import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIBookingRSA {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/smartbook/" + nome_metodo;


  }


  async getPdfPrenotazioni(v_user, id_azienda, v_today, v_todayTo, curStatus, curPiano, search)
  {

    let formData = new FormData();

    formData.append("v_user",  v_user);
    formData.append("id_azienda",  id_azienda);
    formData.append("v_today",  v_today);
    formData.append("v_todayTo",  v_todayTo);
    formData.append("curStatus",  curStatus);
    formData.append("curPiano",  curPiano);
    formData.append("search",  search);

    return axios.post(this.getUrlDomain("getPdfPrenotazioni") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }






}

export default new APIBookingRSA({
  url: "http://emtool.local/public/api/smartbook/"
})
