<template>
<div class="cont-table">

    <div class="spin">
        <ion-spinner name="crescent"></ion-spinner>
    </div>

    <div class="row cont_pagamento cont_tickets">
        <div class=" " style="margin:0 auto;">
            <div class="card">

                <div class="card-body" style="padding:0px">

                    <div class="table-responsive" style="">

                        <table id="myTable" style="margin-top:59px">
                            <thead>
                                <tr>
                                    <th class='all' style='padding-top:5px!important;padding-bottom:5px!important;padding-left:6px'>
                                        <div class="div_n">N.</div>
                                    </th>

                                    <!-- <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>Nome</th> -->

                                    <th class='all' style='padding-top:5px!important;padding-bottom:5px!important'>Descrizione</th>

                                    <th class='desktop' style='padding-top:5px!important;padding-bottom:5px!important'>Azienda</th>

                                    <th class='desktop' style='padding-top:5px!important;;padding-bottom:5px!important;'>Inserito Il</th>

                                    <th class='desktop' style='padding-top:5px!important;padding-bottom:5px!important'>Scade Il</th>

                                    <th class='desktop' style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>Scaduto</th>

                                    <th class='desktop' style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>Tipologia</th>

                                    <th class='all' style='padding-top:5px!important;;padding-bottom:5px!important;text-align:right'>Da Pagare</th>

                                    <th class='desktop' style='padding-top:5px!important;;padding-bottom:5px!important;text-align:right'>Pagato</th>

                                    <th class='desktop' style='padding-top:5px!important;;padding-bottom:5px!important'>
                                        <div class="spazio">Data Pag.</div>
                                    </th>

                                    <th class='all' style='padding-top:5px!important;;padding-bottom:5px!important;text-align:right'>Riman.</th>

                                    <th class='all' style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>Stato</th>

                                    <th class='desktop' style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>Allegato</th>

                                    <!-- <th class='desktop' style='padding-top:5px!important;;padding-bottom:5px!important;text-align:center!important'>Invio Mail</th> -->

                                    <th v-if="isMobile" class='desktop tdPayments' style='padding-top:5px!important;;padding-bottom:5px!important'>Pagamenti</th>

                                    <th class='all' style="padding-top:5px!important;;padding-bottom:5px!important;"></th>





                                </tr>
                            </thead>

                            <tfoot>

                                <tr>

                                    <th class='all col_footer'>
                                        <div class="div_n">&nbsp;</div>
                                    </th>

                                    <!-- <th class='all col_footer'>Totale (€)</th> -->

                                    <th class='all col_footer'>Totale (€)</th>

                                    <th class='desktop col_footer'>&nbsp;</th>

                                    <th class='desktop col_footer'>&nbsp;</th>

                                    <th class='desktop col_footer'>&nbsp;</th>

                                    <th class='desktop col_footer'>&nbsp;</th>

                                    <th class='desktop col_footer'>&nbsp;</th>

                                    <th class='all col_footer_right' id="v_tot_importo">0,00</th>

                                    <th class='desktop col_footer_right' id="v_tot_importo_pagato">0,00</th>

                                    <th class='all col_footer'>&nbsp;</th>

                                    <th class='desktop col_footer_right' id="v_tot_importo_rimanente">0,00</th>

                                    <th class='all col_footer'>&nbsp;</th>

                                    <th class='desktop col_footer'>&nbsp;</th>

                                    <!-- <th class='desktop col_footer'>&nbsp;</th> -->

                                    <th v-if="isMobile" class='desktop tdPayments' style='padding-top:15px!important;;padding-bottom:5px!important;text-align:center!important;border-top:1px solid black'>&nbsp;</th>

                                    <th class='all col_border'></th>



                                </tr>
                            </tfoot>

                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contaziende">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"></v-text-field>

                            </v-toolbar>

                        </div>

                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredAziende" :key="item.companyid" @click="manageClickAziende(item)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ item.fld_name }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>
    </div>

</div>
</template>

<script>
import apipagamento from "../utils/pagamento/apipagamento";
import axios from 'axios';

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {

    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    mounted: function () {

        /*eslint-disable no-undef*/

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        var isPhoneApp = params.isPhoneApp;

        console.log(isPhoneApp);

        this.aziende = JSON.parse(localStorage.getItem('td_info_global')).aziende;

        this.search = "";

        window.curAzienda = "-1";




        $(document).ready(
            function () {

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":

                    router.push({
                        path: "/editPagamento/0"
                    });

                    break;

                case "btn_edit":

                    //alert(window.id_par_sel);

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da modifcare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        router.push({
                            path: "/editPagamento/" + window.id_par_sel
                        });

                    }

                    break;

                case "btn_delete":

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da eliminare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        this.deleteRow();

                    }

                    break;

                case "btn_copy":

                    if (window.id_par_sel == 0) {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da copiare",
                            showConfirmButton: false,
                            timer: 2000
                        });

                    }

                    if (window.id_par_sel > 0) {

                        router.push({
                            path: "/copyPagamento/" + window.id_par_sel
                        });

                    }

                    break;

                default:
                    break;
            }
        });

        //this.setupButtons();

        this.setupButtonsGrid();

        this.setupGrid();

        // di default i pulsanti edit, delete e copy vengono nascosti
        this.hideShowButtons(false);

    },
    data: () => ({
        currentFile: null,
        showPicker: false,

        sheetAziende: false,
        search: '',

        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
            (v && v.length <= 16) ||
            "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
            (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,

        aziende: [],

    }),

    computed: {

        isMobile(){
            return true ;
        },

        filteredAziende() {

            // console.log("SEARCH: ", this.search);

            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.fld_name.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    methods: {

        manageClickAziende: async function (azienda) {

            console.log("AZI SEL: ", azienda);

            this.sheetAziende = false;

            window.curAzienda = azienda.companyid;

            this.hideShowButtons(false);

            window.table.fnPageChange(0);
            window.table._fnDraw();

        },

        downloadWithAxios(url, title) {
            axios({
                    method: 'get',
                    url,
                    responseType: 'arraybuffer',
                })
                .then((response) => {
                    this.forceFileDownload(response, title)
                })
                .catch(() => console.log('error occured'))
        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },

        setupGrid: function () {

            // var that = this;
            // var pathLocal = that.$withBase('/');

            var v_token = window.$cookies.get("token");

            var flt_aziende = "<img src='/alertduedate/img/FiltroAzienda.png' style='border:0!important;' class='selazienda' />";

            console.log(flt_aziende);

            var pointerVue = this;

            const date = new Date().toJSON().slice(0, 10)
            console.log(date) //2015-07-23

            // var $futureTime = date;

            $(document).ready(function () {

                window.id_par_sel = 0;

                var dataCol = [{
                            data: 'newid'/* 'DT_RowIndex' */,
                            orderable: false,
                            searchable: false,
                            name: '',
                            width: '1%',



                        },

                        // {
                        //     data: 'nome',
                        //     orderable: false,
                        //     name: 'nome',
                        //     width: '4%'
                        // },

                        {
                            data: 'descrizione',
                            orderable: false,
                            name: 'descrizione',
                            width: '6%'
                        },

                        {
                            data: 'fld_name',
                            orderable: false,
                            name: 'fld_name',
                            width: '8%'
                        },

                        {
                            data: 'created_at',
                            orderable: false,
                            filtrable: false,
                            name: 'created_at',
                            width: '2%'
                        },

                        {
                            data: 'data_scadenza',
                            orderable: false,
                            name: 'data_scadenza',
                            width: '2%'
                        },

                        {
                            data: 'scaduto',
                            orderable: false,
                            name: 'scaduto',
                            width: '1%'
                        },

                        {
                            data: 'nome_tipologia',
                            orderable: false,
                            name: 'nome_tipologia',
                            width: '1%'
                        },

                        {
                            data: 'importo',
                            orderable: false,
                            name: 'importo',
                            width: '2%'
                        },

                        {
                            data: 'importo_pagato',
                            orderable: false,
                            name: 'importo_pagato',
                            width: '2%'
                        },

                        {
                            data: 'data_pagamento',
                            orderable: false,
                            name: 'data_pagamento',
                            width: '2%'
                        },

                        {
                            data: 'importo_rimanente',
                            orderable: false,
                            name: 'importo_rimanente',
                            width: '2%'
                        },


                        {
                            data: 'nome_stato',
                            orderable: false,
                            name: 'nome_stato',
                            width: '2%'
                        },

                        {
                            data: 'allegato',
                            orderable: false,
                            name: 'allegato',
                            width: '1%'
                        },

                        // {
                        //     data: 'invia_mail',
                        //     orderable: false,
                        //     name: 'invia_mail',
                        //     width: '3%'
                        // },

                        {
                            data: 'pagamento_dettagli',
                            orderable: false,
                            name: 'pagamento_dettagli',
                            width: '3%'
                        },

                        {
                            width: '2%',
                            title: '',
                            target: 0,
                            className: 'treegrid-control',
                            data: function (item) {
                                if (item.children) {
                                    return '<span class="expand">  + </span>';
                                }
                                return '';
                            }
                        },

                    ];

                window.table = $('#myTable').dataTable({

                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 30,

                    processing: false,
                    paging: true,
                    serverSide: true,

                    'treeGrid': {
                        'left': 50,
                        'expandIcon': '<span>+</span>',
                        'collapseIcon': '<span>-</span>'
                    },

                    ajax: {
                        url: pointerVue.getUrlDomain("getPagamenti") + '?v_user=' + v_token,
                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curAzienda = window.curAzienda;

                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label><select name="myTable_length" style="display:none!important" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select></label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log("SETTINGS: ", oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });

                    },

                    columns: dataCol,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();


                        var gruppo_utente = JSON.parse(localStorage.getItem('td_info_global')).gruppo_utente;

                        //console.log("GRP UT: ", gruppo_utente);

                        var elems = gruppo_utente.split(",");


                        console.log("GRP UT: ", elems);

                        if (gruppo_utente == "57")
                        {


                            $(".treegrid-control").addClass("diplay_plus");

                        }







                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                        text: 'Cancel',
                                        role: 'cancel',
                                        cssClass: 'switch-btn-cancel',
                                    },
                                    {
                                        text: 'Done',
                                        cssClass: 'switch-btn-done',
                                        handler: columns => {
                                            console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                            var valore = columns.hour.text + ":" + columns.minute.text;

                                            console.log(valore);
                                        }
                                    },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');

                        $('.p_importo, .p_importo_pagato, .p_importo_rimanente').number(true, 2, ',', '.');

                        var importo = 0;
                        $(".p_importo").each(function () {
                            importo = importo + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var importo_pagato = 0;
                        $(".p_importo_pagato").each(function () {
                            importo_pagato = importo_pagato + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        var importo_rimanente = 0;
                        $(".p_importo_rimanente").each(function () {
                            importo_rimanente = importo_rimanente + parseFloat($(this).text().replace(".", "").replace(",", "."));
                        });

                        //console.log("TOTALE: ", subtotale)

                        $("#v_tot_importo").text(importo.toString().replace(".", ","));
                        $('#v_tot_importo').number(true, 2, ',', '.');

                        $("#v_tot_importo_pagato").text(importo_pagato.toString().replace(".", ","));
                        $('#v_tot_importo_pagato').number(true, 2, ',', '.');

                        $("#v_tot_importo_rimanente").text(importo_rimanente.toString().replace(".", ","));
                        $('#v_tot_importo_rimanente').number(true, 2, ',', '.');

                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Pagamenti</div>
                    ` + flt_aziende + `

                </div>
            `);

                //new $.fn.dataTable.FixedHeader(window.table);

                console.log("EVENTO PAGAMENTI " + window.pagamentiAddedEvents);

                if (window.pagamentiAddedEvents === undefined) {

                    $('body').on('click', '.paginate_button', function () {
                        $(".spin").show();

                    });

                    $('body').on('click', '.refreshButton', function () {

                        $(".spin").show();
                        window.table._fnDraw();

                    });

                    $('body').on('click', '.cont_pagamento tr', function () {

                        setTimeout(() => {

                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            console.log("LAST ID SEL: ", last_id_sel);
                            console.log("ID SEL: ", id_sel);

                            $("tr").removeClass("active-row");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            if (set_cls_active == 1) {

                                $(this).addClass("active-row");

                                window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                                pointerVue.hideShowButtons(true);

                            } else {

                                pointerVue.hideShowButtons(false);

                            }

                        }, 100);

                    });

                }


                $('body').on('click', '.selazienda', function () {

                    console.log(("EVENTO CLICK SELAZIENDA"));

                    pointerVue.sheetAziende = true;

                });

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    // $(".selazienda").on("ionChange", function (opt) {

                    //     console.log("OPT SEL: ", opt);

                    //     console.log("ID AZI SEL: ", this.value);

                    //     $(".spin").show();

                    //     window.curAzienda = this.value;

                    //     window.table.fnPageChange(0);
                    //     window.table._fnDraw();

                    // });

                    /*
                    var elements = jQuery('.datetimechangeday');
                    console.log ( elements , "testa" );
                    var id = $(this).data("id")  ;*/

                    window.currDay = date;
                    window.currDayTo = date;

                    // alert(window.currDay);

                    // document.querySelector('.datetimechangeday').pickerOptions = {
                    //     buttons: [{
                    //             text: 'Cancel',
                    //             role: 'cancel',
                    //             cssClass: 'switch-btn-cancel',
                    //         },
                    //         {
                    //             text: 'Done',
                    //             cssClass: 'switch-btn-done',
                    //             handler: columns => {
                    //                 var id = 0;
                    //                 console.log("ore minuti id", columns, id);
                    //                 var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                    //                 window.currDay = valore;
                    //                 document.querySelector('.datetimechangeday').value = valore;

                    //                 window.currDayTo = valore;
                    //                 document.querySelector('.datetimechangedayTo').value = valore;

                    //                 $(".spin").show();
                    //                 window.table.fnPageChange(0);
                    //                 window.table._fnDraw();
                    //             }
                    //         },
                    //     ],
                    // };

                    // document.querySelector('.datetimechangedayTo').pickerOptions = {
                    //     buttons: [{
                    //             text: 'Cancel',
                    //             role: 'cancel',
                    //             cssClass: 'switch-btn-cancel',
                    //         },
                    //         {
                    //             text: 'Done',
                    //             cssClass: 'switch-btn-done',
                    //             handler: columns => {
                    //                 var id = 0;
                    //                 console.log("ore minuti id", columns, id);
                    //                 var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                    //                 window.currDayTo = valore;
                    //                 document.querySelector('.datetimechangedayTo').value = valore;
                    //                 //update From

                    //                 // window.currDay = valore ;
                    //                 // document.querySelector('.datetimechangeday').value = valore ;

                    //                 window.table.fnPageChange(0);
                    //                 $(".spin").show();
                    //                 window.table._fnDraw();
                    //             }
                    //         },
                    //     ],
                    // };

                }, 600);

                window.pagamentiAddedEvents = true;

            });

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                       // class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Add"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                       // class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Edit",
                 
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/elimina_2dolist.png",
                       // class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30,
                        title: "Delete",
                         
                    },

                    {
                        text: "Copy",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/copy_elements.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_copy",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        deleteRow: async function () {

            var v_token = window.$cookies.get("token");

            // console.log("TOKEN 3 ", v_token);

            var that = this;

            that.$swal
                .fire({
                    title: "Delete",
                    html: "Do you want to delete the selected item ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Ok",
                })
                .then(async function (result) {

                    if (result.value == true) {

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apipagamento.deletePagamento(
                            window.id_par_sel, v_token

                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deletePagamento", res);

                            that.$swal({
                                icon: "success",
                                text: "Data successfully deleted",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                window.table._fnDraw();
                                that.hideShowButtons(false);

                            }, 200);

                        }).catch(err => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err);
                                var msg = err.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("ERrori", "Non è stato possibile eliminare i dati");
                                console.log("response", response);

                            }

                        );

                    }

                });

        },

        downAttach: async function (id) {

            console.log("ID", id);

        },

        hideShowButtons: async function (v_enable) {

            //console.log("hideShowButtons");

            setTimeout(() => {

                var gruppo_utente = JSON.parse(localStorage.getItem('td_info_global')).gruppo_utente;

                var elems = [];

                if (gruppo_utente  != null)
                {
                elems = gruppo_utente.split(",");

                }


                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                           // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Add"

                        },

                    ];

                }

                if (v_enable == true) {

                    if (elems.includes("17")) // commercialista
                    {

                        pulsantis = [

                            {
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                              //  class: "inverted",
                                link: "/settings",
                                id: "btn_add",
                                disabled: false,
                                width: 30,
                                title: "Add"

                            },


                        ];


                    }
                    else
                    {

                        pulsantis = [

                            {
                                text: "Add",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                               // class: "inverted",
                                link: "/settings",
                                id: "btn_add",
                                disabled: false,
                                width: 30,
                                title: "Add"

                            },

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                              //  class: "inverted",
                                link: "/info",
                                id: "btn_edit",
                                disabled: false,
                                width: 30,
                                title: "Edit",
                 
                            },

                            {
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/elimina_2dolist.png",
                               // class: "inverted",
                                link: "/info",
                                id: "btn_delete",
                                disabled: false,
                                width: 30,
                                title: "Delete",
                         
                            },

                            {
                                text: "Copy",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/copy_elements.png",
                                class: "inverted",
                                link: "/info",
                                id: "btn_copy",
                                disabled: false,
                            },

                        ];

                    }



                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        }

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #45b7e9;

}

.pay a {
    color: #45b7e9;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #45b7e9;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.cont_pagamento .text_title {
    z-index: 1;
}

.cont_pagamento .cont_buttons .text_title {
    min-width: 40px;

}

.cont_pagamento .text_title {
    min-width: 40px !important;
}

.cont_pagamento .cont_buttons ion-item {
    transform: translate(-16px, 2px);
}

.cont_pagamento .cont_buttons {
    min-width: 102px;
}

.selazienda {
    max-width: 23px;
    min-height: 23px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /*   background-image: url(/alertduedate/img/FiltroAzienda.png); */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}
</style>

<style>
.cont_pagamento #myTable_wrapper {
    min-width: initial;
    /* min-width: 1350px;
    max-width: 1350px;
    width:1350px; */
    /* background: red;*/

    width: 100%;
    min-width: 90vw;
    max-width: 90vw;

}

.cont_pagamento .nome_parente {
    min-width: 200px;
}

.cont_pagamento .inserito {
    min-width: 70px;
}

.cont_pagamento .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.cont_pagamento .mail {
    min-width: 200px;
}

.cont_pagamento td:first-child {
    padding: 12px;
}

/* .cont_pagamento #myTable_filter {
    padding-top: 1px;
    padding-bottom: 1px;
    position: fixed;
    left: 0px;
    max-width: 100%;
} */

.cont_pagamento #myTable_filter {
    top: 66px;
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

@media screen and (min-width: 1024px) {

    .cont_pagamento table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        visibility: hidden !important;
    }

    .tdPayments {
        visibility: hidden;
        max-width: 2px!important;
        overflow:hidden!important;


    }

    .tblPayments {
        visibility: hidden;

        max-height: 1px;
        zoom: 1;
        /* background: blueviolet; */

        display: inline!important;
        max-width: 1px!important;

    }


}

.spazio {
    padding-left:24px
}

.col_footer {
    padding-top:15px!important;
    padding-bottom:5px!important;
    padding-left:6px;
    border-top:1px solid black
}

.col_footer_right {
    padding-top:15px!important;
    padding-bottom:5px!important;
    padding-left:6px;
    border-top:1px solid black;
    text-align: right;
}

@media screen and (max-width: 768px) {

    .spazio {
        padding-left:0px
    }


    .div_n {
        padding-left: 20px;
    }

    .cont_pagamento .nome_parente {
        min-width: initial;
        word-break: break-all;
        max-width: 120px;
    }

    .cont_pagamento .inserito {
        min-width: initial;
    }

    .cont_pagamento .osp_assoc {
        min-width: initial;
        display: flex;
        justify-content: center;
    }

    .cont_pagamento .mail {
        min-width: initial;
    }

    .cont_pagamento #myTable_wrapper {
        min-width: initial;
        min-width: 100vw;
        max-width: 100vw;
        width: 100vw;
        /* background: red;*/

    }

    .cont_pagamento #myTable_filter {
        top: 58px;
        position: fixed;
        left: 0px;
        min-width: 100%;
        z-index: 3;
    }

    .cont_pagamento #myTable {
        max-width: 100vw;
        width: 100vw !important;
    }

    .cont_pagamento table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        left: 10px !important;
    }

    .treegrid-control {
        display: none;
    }


    .tblPayments div {

        padding:5px;

    }

    .col_footer {
        padding-top:15px!important;
        padding-bottom:5px!important;
        padding-left:6px;
        border-top:none;
        color: white;
    }

    .col_footer_right {
        padding-top:15px!important;
        padding-bottom:5px!important;
        padding-left:6px;
        border-top:none;
        text-align: right;
        color: white;
    }



}

.scaduto {
    min-width: 56px;
    justify-content: center;
}

.tipologia {
    justify-content: center;
    display: flex;
}

.valori {
    justify-content: right;
    display: flex;
}

.invio_mail {
    min-width: 56px;
    justify-content: center;
}

.cont_pagamento .text_title {
    background-color: #45b7e9;
    color: white;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 80px
}

.cont_pagamento .p_importo_rimanente {
    text-align: right !important;
}

@media screen and (max-width: 1024px) {

    .scaduto,
    .tipologia,
    .valori, .valori_importo_pagato {
        display: inline-flex;
        height: 20px;
        align-items: center;
        transform: translateY(1px);
        justify-content: flex-start !important;

    }

    .invio_mail {
        display: inline-flex;
        height: 20px;
        align-items: center;
        transform: translateY(1px);
        justify-content: flex-start !important;
        padding-left: 4px;

    }

    /* .valori_importo_pagato {
        justify-content: right;
        display: flex;
    } */


}

.diplay_plus {
    visibility: hidden;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAzienda {
    margin-left: 15px;
}

img {
    border-image-width: 0px !important;
    border: none !important;
}

.v-application .text-center {

    overflow: auto;
}

.expand,
.treegrid-control span {
    font-size: 28px;
}

td.treegrid-control {
    min-height: 72px;
}

.treegrid-control,
.treegrid-control-open span {
    font-size: 34px;
    cursor: pointer;
}

.col_border {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    text-align: center !important;
    border-top: 1px solid black !important
}

.tr {
    display: table-row;
}

.td {
    display: table-cell;
}

</style>
