import axios from 'axios'

class APIAttivita {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async insertAttivita(attivita, user)
  {


    let formData = new FormData();

    formData.append("username",  user);

    for ( var key in attivita ) {
       formData.append(key, attivita[key]);
    }

    return axios.post(this.getUrlDomain("insAttivitaApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getAttivita(id)
  {

    return axios.get(this.getUrlDomain("getAttivitaApi") + "/" + id + "?api=1",{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async updateAttivita(attivita, id)
  {


    let formData = new FormData();


    for ( var key in attivita ) {
       formData.append(key, attivita[key]);
    }

    return axios.post(this.getUrlDomain("updAttivitaApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteAttivita(id)
  {

    return axios.get(this.getUrlDomain("delAttivitaApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }




}

export default new APIAttivita({
  url: "http://emtool.local/"
})
