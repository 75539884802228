<template>
<div class="cont-table" style="max-width:90vw">

    <div class="spin">
        <ion-spinner name="crescent"></ion-spinner>
    </div>

    <div class="row cont_tickets">
        <div class=" " style="margin:0 auto;">
            <div class="card">

                <div class="card-body" style="padding:0px">

                    <div class="table-responsive" style="">

                        <table id="myTable">
                            <thead>
                                <tr>

                                    <th class='all' style='text-align:right!important;'> </th>

                                    <th class='all'>Ospite</th>

                                    <th class='desktop'>Data</th>
                                    <th class='all'>Ora</th>
                                    <th class='all'>Nucleo</th>
                                    <th class='desktop'> Nominativo </th>

                                    <th class='desktop'>Stato</th>

                                    <th class='desktop'> E-Mail</th>
                                    <th class='desktop'> Telefono</th>
                                    <th class='desktop'> C. Fiscale</th>
                                    <th class='none'> Note</th>
                                    <th class='desktop'> Descrizione</th>
                                    <th class='desktop'>Dispositivo</th>

                                </tr>
                            </thead>

                            <tfoot>

                                <tr>
                                    <th colspan="13">
                                    </th>
                                </tr>
                            </tfoot>

                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import apibookingrsa from "../utils/bookingrsa/apibookingrsa";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/
        $(document).ready(
            function () {

                window.uname = window.$cookies.get("username");
                var uname = window.uname;
                
                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {
         
                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_info":
                    router.push({
                        path: "/info"
                    });
                    break;

                case "btn_pdf":

                    this.createPdf();

                    break;

                default:
                    break;
            }
        });

        this.setupButtons();

        this.setupGrid();

    },
    data: () => ({
        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
            (v && v.length <= 16) ||
            "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
            (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/smartbook/" + nome_metodo;

        },

        setupGrid: function () {

            var that = this;
            var pathLocal = that.$withBase('/');

            var pointerVue = this;


                        var dateTest = new Date();
                        dateTest = dateTest.setDate(dateTest.getDate() + 365);
                        const dateTest2 = new Date(dateTest).toJSON().slice(0, 10);

                         //console.log("DATE: " + dateTest2) //2015-07-23



                        const date = new Date().toJSON().slice(0, 10)



                        //console.log("DATE: " + date) //2015-07-23

                        var $futureTime = dateTest2 ;


            $(document).ready(function () {

                //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

                // console.log("HOSTNAME: " + $(location).attr('hostname'));
                // console.log("PROTOCOL: " + $(location).attr('protocol'));

                window.table = $('#myTable').dataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 30,

                    processing: false,
                    paging: true,
                    serverSide: true,

                    ajax: {
                        url: pointerVue.getUrlDomain("getListingRSA") + '?v_user=ZmFnb3N0b25p&id_azienda=3165',
                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: [{
                            data: 'DT_RowIndex',
                            orderable: false,
                            searchable: false,
                            name: '',
                            width: '1%',
                            // targets: 0,
                        },

                        {
                            data: 'namepaziente',
                            orderable: false,
                            name: 'namepaziente',
                            width: '3%'
                        },
                        {
                            data: 'data',
                            orderable: false,
                            name: 'data',
                            width: '6%'
                        },
                        {
                            data: 'booking_date_time',
                            orderable: false,
                            name: 'booking_date_time',
                            width: '6%',
                        },
                        {
                            data: 'settorepiano',
                            orderable: false,
                            name: 'settorepiano',
                            width: '6%'
                        },
                        {
                            data: 'composite',
                            orderable: false,
                            name: 'composite',
                            width: '10%'
                        },
                        {
                            data: 'status',
                            orderable: false,
                            name: 'status',
                            width: '3%'
                        },

                        {
                            data: 'email',
                            orderable: false,
                            name: 'email',
                            width: '5%'
                        },
                        {
                            data: 'phone',
                            orderable: false,
                            name: 'phone',
                            width: '5%'
                        },
                        {
                            data: 'cod_fis_ospite',
                            orderable: false,
                            name: 'cod_fis_ospite',
                            width: '6%'
                        },
                        {
                            data: 'note',
                            orderable: false,
                            name: 'note',
                            width: '6%'
                        },
                        {
                            data: 'description',
                            orderable: false,
                            name: 'description',
                            width: '6%'
                        },
                        {
                            data: 'tipology',
                            orderable: false,
                            name: 'tipology',
                            width: '1%'
                        },

                    ],

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                        text: 'Cancel',
                                        role: 'cancel',
                                        cssClass: 'switch-btn-cancel',
                                    },
                                    {
                                        text: 'Done',
                                        cssClass: 'switch-btn-done',
                                        handler: columns => {
                                            console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                            var valore = columns.hour.text + ":" + columns.minute.text;

                                            console.log(valore);


                                            $.ajax({
                                                url: pointerVue.getUrlDomain("updateHour"),
                                                data: {
                                                    "id": id,
                                                    "hour": valore,
                                                    "v_user": "ZmFnb3N0b25p"
                                                },
                                                success: function () {
                                                }
                                            });



                                        }
                                    },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                var sel = `  <ion-item>
                <ion-select   data-id="0"  value="0" class="selectstatusall" interface="action-sheet" placeholder=" "><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='0'>In attesa</ion-select-option><ion-select-option  value='1'>Confermato</ion-select-option><ion-select-option  value='2'>Disdetto cliente</ion-select-option><ion-select-option  value='3'>Disdetto ammin.</ion-select-option></ion-select></ion-item>
                  `;

                  var sel2 = `  <ion-select   data-id="0"  value="-1" class="selectpianiall" interface="action-sheet" placeholder=" "><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='NUCLEO 1 A'>NUCLEO 1 A</ion-select-option><ion-select-option  value='NUCLEO 1 B'>NUCLEO 1 B</ion-select-option><ion-select-option  value='NUCLEO 2 A'>NUCLEO 2 A</ion-select-option><ion-select-option  value='NUCLEO 3 A'>NUCLEO 3 A</ion-select-option><ion-select-option  value='NUCLEO ALZHEIMER'>NUCLEO ALZHEIMER</ion-select-option><ion-select-option  value='NUCLEO TERRA B'>NUCLEO TERRA B</ion-select-option></ion-select></ion-item>
`;

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">
                    <ion-icon class='dayleft' ios="ios-arrow-dropleft" style="z-index:-2;display:none;cursor:pointer;font-size:32px;" md="md-arrow-dropleft"></ion-icon>

                    <ion-datetime max="`+$futureTime +`" style="margin-right: 10px;z-index:0;" cancel-Text="" done-Text="" class='datetimechangeday' value='`  + date +  `' display-format="YYYY-MM-DD" ></ion-datetime>
                    <ion-datetime max="`+$futureTime +`" style="z-index:0;" cancel-Text="" done-Text="" class='datetimechangedayTo' value='`  + date +  `' display-format="YYYY-MM-DD" ></ion-datetime>

                    <ion-icon  class='dayright' style="display:none;font-size:32px;cursor:pointer;" ios="ios-arrow-dropright" md="md-arrow-dropright"></ion-icon>
                    <img src='` + pathLocal + `update-left-rotation.png' class="refreshButton" />
                    ` + sel + sel2 + `


                </div>
            `);

                //new $.fn.dataTable.FixedHeader(window.table);

                if (window.listBookingRsaAddedEvents === undefined) {

                    $('body').on('click', '.paginate_button', function () {
                        $(".spin").show();

                    });

                    $('body').on('click', '.refreshButton', function () {

                        $(".spin").show();
                        window.table._fnDraw();

                    });

                }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    /*
                    var elements = jQuery('.datetimechangeday');
                    console.log ( elements , "testa" );
                    var id = $(this).data("id")  ;*/




                    window.currDay = date;
                    window.currDayTo = date;

                    // alert(window.currDay);

                    document.querySelector('.datetimechangeday').pickerOptions = {
                        buttons: [{
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'switch-btn-cancel',
                            },
                            {
                                text: 'Done',
                                cssClass: 'switch-btn-done',
                                handler: columns => {
                                   var  id = 0;
                                    console.log("ore minuti id", columns, id);
                                    var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                                    window.currDay = valore;
                                    document.querySelector('.datetimechangeday').value = valore;

                                    window.currDayTo = valore;
                                    document.querySelector('.datetimechangedayTo').value = valore;

                                    $(".spin").show();
                                    window.table.fnPageChange(0);
                                    window.table._fnDraw();
                                }
                            },
                        ],
                    };

                    document.querySelector('.datetimechangedayTo').pickerOptions = {
                        buttons: [{
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'switch-btn-cancel',
                            },
                            {
                                text: 'Done',
                                cssClass: 'switch-btn-done',
                                handler: columns => {
                                   var  id = 0;
                                    console.log("ore minuti id", columns, id);
                                    var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                                    window.currDayTo = valore;
                                    document.querySelector('.datetimechangedayTo').value = valore;
                                    //update From

                                    // window.currDay = valore ;
                                    // document.querySelector('.datetimechangeday').value = valore ;

                                    window.table.fnPageChange(0);
                                    $(".spin").show();
                                    window.table._fnDraw();
                                }
                            },
                        ],
                    };

                }, 600);

                window.listBookingRsaAddedEvents = true;

            });

        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },



                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image:"https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image:"https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image:"https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];



                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

    

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },


        createPdf: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

                            // valori.currDayTo = window.currDayTo;
                            // valori.curStatus = window.curStatus;
                            // valori.curPiano = window.curPiano;


            var response = await apibookingrsa.getPdfPrenotazioni(
                "ZmFnb3N0b25p", 
                3165, 
                window.currDay, 
                window.currDayTo, 
                window.curStatus, 
                window.curPiano, 
                ""
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPdfPrenotazioni", res);

                try {

                    console.log(res.data.Result);

                    window.open(res.data.Result, '_blank');
              
                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile esportare il pdf");
                    console.log("response", response);

                }

            );




        }




    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #45b7e9;

}

.pay a {
    color: #45b7e9;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #45b7e9;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
     0px;
}

    .selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black!important;
    padding: 0px 0px;
    min-width: 14px!important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);

    }


</style>