import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from './components/profile.vue'
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import listBookingRsa from './components/listBookingRsa.vue'
import parenti from './components/parenti.vue'
import editParente from './components/editParente.vue'
import tipologiapagamento from './components/tipologiapagamento.vue'
import editTipologiaPagamento from './components/editTipologiaPagamento.vue'
import pagamenti from './components/pagamenti.vue'
import editPagamento from './components/editPagamento.vue'
import mailGruppo from './components/mailGruppo.vue'
import editMailGruppo from './components/editMailGruppo.vue'
import copyPagamento from './components/copyPagamento.vue'
import confInvioMail from './components/confInvioMail.vue'
import reservate from './components/reservate.vue'
import argomenti from './components/argomenti.vue'
import editArgomento from './components/editArgomento.vue'
import attivita from './components/attivita.vue'
import editAttivita from './components/editAttivita.vue'
import copyAttivita from './components/copyAttivita.vue'
import pianificazioneChoiceUser from './components/pianificazioneChoiceUser.vue'
import pianificazione from './components/pianificazione.vue'
import editPianificazione from './components/editPianificazione.vue'
import logoFirma from './components/logoFirma.vue'
import chooseLogoFirma from './components/chooseLogoFirma.vue'
import reservateFromMail from './components/reservateFromMail.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import reminder from './components/reminder.vue'
import editReminder from './components/editReminder.vue'
import chooseReminder from './components/chooseReminder.vue'
import reservateReminder from './components/reservateReminder.vue'
import notes from './components/notes.vue'
import editNotes from './components/editNotes.vue'
import gruppoNotifiche from './components/gruppoNotifiche.vue'
import editGruppoNotifiche from './components/editGruppoNotifiche.vue'
import confPianificazione from './components/confPianificazione.vue'
import editConfPianificazione from './components/editConfPianificazione.vue'
import reloadPage from './components/reloadPage.vue'
import editPianificazionePersonale from './components/editPianificazionePersonale.vue'
import editUpdatePianificazionePersonale from './components/editUpdatePianificazionePersonale.vue'
import editUpdatePianificazione from './components/editUpdatePianificazione.vue'


Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/listBookingRsa',
            name: 'listBookingRsa',
            component: listBookingRsa
        }

        ,
        {
            path: '/parenti',
            name: 'parenti',
            component: parenti
        }

        ,
        {
            path: '/editParente/:id',
            name: 'editParente',
            component: editParente,
            props: true
        }



        ,
        {
            path: '/tipologiapagamento',
            name: 'tipologiapagamento',
            component: tipologiapagamento
        }


        ,
        {
            path: '/editTipologiaPagamento/:id',
            name: 'editTipologiaPagamento',
            component: editTipologiaPagamento,
            props: true
        }

        ,
        {
            path: '/pagamenti',
            name: 'pagamenti',
            component: pagamenti,
            props: true
        }

        ,
        {
            path: '/editPagamento/:id',
            name: 'editPagamento',
            component: editPagamento,
            props: true
        }

        ,
        {
            path: '/mailGruppo',
            name: 'mailGruppo',
            component: mailGruppo,
            props: true
        }

        ,
        {
            path: '/editMailGruppo/:id',
            name: 'editMailGruppo',
            component: editMailGruppo,
            props: true
        }

        ,
        {
            path: '/copyPagamento/:id',
            name: 'copyPagamento',
            component: copyPagamento,
            props: true
        }

        ,
        {
            path: '/confInvioMail',
            name: 'confInvioMail',
            component: confInvioMail,
            props: true
        }

        // ,
        // {
        //     path: '/reservate/:user/:psw/:page_call',
        //     name: 'reservate',
        //     component: reservate,
        //     props: true
        // }

        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }
        ,
        {
            path: '/argomenti',
            name: 'argomenti',
            component: argomenti,
            props: true
        }

        ,
        {
            path: '/editArgomento/:id',
            name: 'editArgomento',
            component: editArgomento,
            props: true
        }

        ,
        {
            path: '/attivita',
            name: 'attivita',
            component: attivita,
            props: true
        }

        ,
        {
            path: '/editAttivita/:id',
            name: 'editAttivita',
            component: editAttivita,
            props: true
        }

        ,
        {
            path: '/copyAttivita/:id',
            name: 'copyAttivita',
            component: copyAttivita,
            props: true
        }

        ,
        {
            path: '/pianificazioneChoiceUser',
            name: 'pianificazioneChoiceUser',
            component: pianificazioneChoiceUser,
            props: true
        }


        ,
        {
            path: '/pianificazione',
            name: 'pianificazione',
            component: pianificazione,
            props: true
        }



        ,
        {
            path: '/editPianificazione/:id',
            name: 'editPianificazione',
            component: editPianificazione,
            props: true
        }



        ,
        {
            path: '/logoFirma',
            name: 'logoFirma',
            component: logoFirma,
            props: true
        }

        ,
        {
            path: '/chooseLogoFirma',
            name: 'chooseLogoFirma',
            component: chooseLogoFirma,
            props: true
        }


        ,
        {
            path: '/reservateFromMail/:id',
            name: 'reservateFromMail',
            component: reservateFromMail,
            props: true
        },


        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial,
            props: true
        },

        {
            path: '/reminder',
            name: 'reminder',
            component: reminder,
            props: true
        },

        
        {
            path: '/editReminder/:id',
            name: 'editReminder',
            component: editReminder,
            props: true
        },

        {
            path: '/chooseReminder',
            name: 'chooseReminder',
            component: chooseReminder,
            props: true
        },

        {
            path: '/reservateReminder/:id',
            name: 'reservateReminder',
            component: reservateReminder,
            props: true
        },
        
        {
            path: '/notes',
            name: 'notes',
            component: notes,
            props: true
        },

        {
            path: '/editNotes/:id',
            name: 'editNotes',
            component: editNotes,
            props: true
        },

        {
            path: '/gruppoNotifiche',
            name: 'gruppoNotifiche',
            component: gruppoNotifiche,
            props: true
        },

        {
            path: '/editGruppoNotifiche/:id',
            name: 'editGruppoNotifiche',
            component: editGruppoNotifiche,
            props: true
        },

        {
            path: '/confPianificazione',
            name: 'confPianificazione',
            component: confPianificazione,
            props: true
        },

        {
            path: '/editConfPianificazione/:id',
            name: 'editConfPianificazione',
            component: editConfPianificazione,
            props: true
        },

        {
            path: '/reloadPage',
            name: 'reloadPage',
            component: reloadPage,
            props:true
        },


        {
            path: '/editPianificazionePersonale/:id',
            name: 'editPianificazionePersonale',
            component: editPianificazionePersonale,
            props: true
        },

        {
            path: '/editUpdatePianificazionePersonale/:id',
            name: 'editUpdatePianificazionePersonale',
            component: editUpdatePianificazionePersonale,
            props: true
        },

        {
            path: '/editUpdatePianificazione/:id',
            name: 'editUpdatePianificazione',
            component: editUpdatePianificazione,
            props: true
        },

        

    ]
})


    router.afterEach((to, from) => {
        console.log ("from" , from  );
        console.log("going to " + to.fullPath)


        setTimeout(() => {

            console.log(window.location.hash);
            var curr = window.location.hash;
    
    
            if (curr == '#/' && to.fullPath.length > 2 /* && curr != "re" */)
    
                window.location.hash = to.fullPath;
        }, 900);

        

       // console.log(router.app.$refs);


/*         console.log(to);

        console.log("Current Refs");
        console.log(router.app.$refs);

        Vue.nextTick(function () {
            console.log("after NextTick");
            console.log(router.app.$refs);
        }) */
    })


export default router
