import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIInfo {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/smartbook/" + nome_metodo;


  }


  async getInfo(user)
  {


    let formData = new FormData();

    formData.append("v_user",  user);

    return axios.post(this.getUrlDomain("getInfo") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }






}

export default new APIInfo({
  url: "http://emtool.local/public/api/smartbook/"
})
