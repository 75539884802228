<template>
    <div class="cont-table">

        <div class="row cont_conf_pianificazione_2dolist cont_tickets">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:59px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important;padding-left: 5px!important;'>
                                            Surname</th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important;padding-left: 5px!important;'>
                                            Name</th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important;text-align: center'>
                                            Personal</th>

                                        <th class='all' style='padding-top:5px!important;padding-bottom:5px!important;text-align: center;'>
                                            Managed</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="6">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contattivita">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAttivita" class="vattivitasheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAttivita" fixed style="position:fixed;">
                                Attività
                                <v-btn class='closeSheet' icon @click="sheetAttivita = !sheetAttivita">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchAttivita"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAttivita" :key="item.id"
                                        @click="manageClickAttivita(item)">

                                        <v-img alt="Attivita Logo" class="  " contain src="@/assets/filter_activity2.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemAttivita'>{{ item.nome }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contproprietari">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetProprietari" class="vproprietarisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetProprietari" fixed style="position:fixed;">
                                Proprietari
                                <v-btn class='closeSheet' icon @click="sheetProprietari = !sheetProprietari">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchProprietari"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredProprietari" :key="item.login"
                                        @click="manageClickProprietario(item)">

                                        <v-img alt="Proprietario Logo" class="  " contain src="@/assets/owners.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemProprietario'>{{ item.name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/2dolist_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>




    </div>
</template>

<script>
import apipianificazione from "../utils/pianificazione/apipianificazione";
import apiconfpianusers from "../utils/confpianusers/apiconfpianusers";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';
import _ from 'lodash';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    computed: {

        filteredAttivita() {
            return _.orderBy(this.attivita.filter(item => {
                if (!this.searchAttivita) return this.attivita;
                return (item.nome.toLowerCase().includes(this.searchAttivita.toLowerCase()));
            }), 'headline')
        },

        filteredProprietari() {

            return _.orderBy(this.proprietari.filter(item => {
                if (!this.searchProprietari) return this.proprietari;
                return (item.name.toLowerCase().includes(this.searchProprietari.toLowerCase()));
            }), 'headline')

        }

    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        this.proprietari = JSON.parse(localStorage.getItem('td_info_global')).todo_utenti;

        this.searchAttivita = "";
        this.searchProprietari = "";
        this.userSelected = "-1";

        window.statoid = "";
        window.propid = "-1";
        window.curSrcText = "";


        var a = window.$cookies.get("a");

        if (a == "N") {

            this.userSelected = window.$cookies.get("username");

        }

        /*eslint-disable no-undef*/
        $(document).ready(
            function () {

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        setTimeout(() => {

            this.syncFilterAttivita();

        }, 100);

        $(document).ready(function () {

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editConfPianificazione/0"
                    });
                    break;


                case "btn_edit":


                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editConfPianificazione/" + ids
                    });



                    break;

                case "btn_delete":

                    this.deleteRow();

                    break;

                default:
                    break;
            }
        });

        this.setupButtonsGrid();

        this.setupGrid();

        // di default i pulsanti edit, delete vengono nascosti
        this.hideShowButtons(false);

    },
    data: () => ({

        textSearch: "",

        sheetAttivita: false,
        searchAttivita: '',

        sheetProprietari: false,
        searchProprietari: "",

        userSelected: "-1",

        currentFile: null,
        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,

        proprietari: [],
        attivita: [],

    }),

    methods: {

        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;


                }

            });

            console.log("TOT SEL: ", tot);

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push(

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            //class: "inverted",
                            title: "Add",
                            width: 30

                        }

                    );


                }



                if (tot == 1) {


                    pulsantis.push(

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            //class: "inverted",
                            title: "Edit",
                            width: 30

                        }

                    );


                }

                if (tot > 0) {


                    pulsantis.push(

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/elimina_2dolist.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            // class: "inverted",
                            title: "Delete",
                            width: 30

                        }

                    );




                }

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);



        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },

        manageClickAttivita: async function (attivita) {

            console.log("ATTIVITA SEL: ", attivita);

            this.sheetAttivita = false;

            window.attivitaid = attivita.id;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            this.hideShowButtons(false);

            setTimeout(function () {

                window.table.ajax.reload();

            }, 100);

        },

        manageClickProprietario: async function (proprietario) {

            console.log("PROPRIETARIO SEL: ", proprietario);

            this.sheetProprietari = false;

            window.propid = proprietario.login;

            this.userSelected = proprietario.login;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            this.syncFilterAttivitaSrcByUser();


            this.hideShowButtons(false);


            setTimeout(function () {

                window.table.ajax.reload();

            }, 100);


        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + nome_metodo;

        },

        setupGrid: function () {

            var v_token = window.$cookies.get("token");
            // var a = window.$cookies.get("a");

            var filtri = "";
            // if ((a == "S") || (a == "Y")) {

            //     filtri = `<img src='/todolist/img/refresh_2dolist.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><img src='/todolist/img/utenti_2dolist.png' style='border:0!important;' class='selproprietario' title='Utenti' /><div style="transform: translateY(-1px);margin-left:7px" title='Stati'>
            //     <ion-select   data-id="0"  class="selstato" interface="action-sheet" placeholder=" " title='Stati'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='OPEN'>Aperto</ion-select-option><ion-select-option  value='WIP'>In Corso</ion-select-option><ion-select-option  value='CLOSE'>Chiuso</ion-select-option></div><img src='/todolist/img/attivita_2dolist.png' style='border:0!important;' class='selattivita' title='Attività' />`;

            // } else {

            //     filtri = `<img src='/todolist/img/refresh_2dolist.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' />
            //     <div title='Stati' style='margin-left:7px;margin-top:-3px'>
            //     <ion-select   data-id="0"  class="selstato" interface="action-sheet" placeholder=" " title='Stati'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='OPEN'>Aperto</ion-select-option><ion-select-option  value='WIP'>In Corso</ion-select-option><ion-select-option  value='CLOSE'>Chiuso</ion-select-option></div><img src='/todolist/img/attivita_2dolist.png' style='border:0!important;margin-left:10px' class='selattivita' title='Attività' />`;

            // }

            console.log(filtri);

            var pointerVue = this;

            const date = new Date().toJSON().slice(0, 10)
            console.log(date) //2015-07-23

            $(document).ready(function () {

                window.id_par_sel = 0;

                window.table = $('#myTable').DataTable({
                    // dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                    //     "<'rows colcontTable'<'col-sm-12 'tr>>" +
                    //     "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 30,

                    processing: false,
                    paging: true,
                    serverSide: true,

                    ajax: {
                        url: pointerVue.getUrlDomain("TodolistPianGetConfUsers") + '?username=' + v_token,
                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.statoid = window.statoid;
                            valori.attivitaid = window.attivitaid;

                            valori.propid = window.propid;

                            valori.curSrcText = window.curSrcText;


                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label><select name="myTable_length" style="display:none!important" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select></label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: [{
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'cognome_utente',
                        orderable: false,
                        name: 'cognome_utente',
                        width: '5%'
                    },

                    {
                        data: 'nome_utente',
                        orderable: false,
                        name: 'nome_utente',
                        width: '5%'
                    },
                  

                    {
                        data: 'pianificazione_personale',
                        orderable: false,
                        name: 'pianificazione_personale',
                        width: '5%'
                    },


                    {
                        data: 'pianificazione_altri',
                        orderable: false,
                        name: 'pianificazione_altri',
                        width: '5%'
                    },


                    ],

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        $(".downloadspinner").hide();

                        setTimeout(() => {

                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);


                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">Users Conf.</div>
                    ` + filtri + `

                </div>
                <div><img src='/todolist/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                console.log("EVENTO " + window.pianificazioneAddedEvents);

                if (window.pianificazioneAddedEvents === undefined) {

                    $(".selricerca").on("click", function (opt) {
                        console.log(opt);

                        pointerVue.textSearch = "";

                        $(".contpopupsearch").show();

                        pointerVue.$modal.show('popupSearch');

                    });


                    $('body').on('click', '.paginate_button', function () {
                        $(".spin").show();

                    });

                    $('body').on('click', '.cont_conf_pianificazione_2dolist tr', function () {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            pointerVue.hideShowButtonsSelRow();


                        }


                    });

                }

                $('body').on('click', '.selaggiorna', function () {


                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    pointerVue.hideShowButtons(false);

                    setTimeout(function () {

                        window.table.ajax.reload();

                    }, 200);

                });

                $('body').on('click', '.selattivita', function () {

                    console.log(("EVENTO CLICK SELATTIVITA"));

                    pointerVue.sheetAttivita = true;

                });

                $('body').on('click', '.selproprietario', function () {

                    console.log(("EVENTO CLICK SELPROPRIETARIO"));

                    pointerVue.sheetProprietari = true;

                });

                //setup ionic calendar top
                setTimeout(function () {

                    $(".selstato").on("ionChange", function (opt) {
                        console.log(opt);

                        window.statoid = this.value;

                        pointerVue.showSpinner = true;
                        pointerVue.$root.$children[0].showProgress = true;

                        pointerVue.hideShowButtons(false);

                        setTimeout(function () {

                            window.table.ajax.reload();

                        }, 200);


                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.pianificazioneAddedEvents = true;

            });

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                        //class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Add"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Edit",
                 
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/elimina_2dolist.png",
                        //  class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30,
                        title: "Delete",
                         
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },
        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        syncFilterAttivita: async function () {


            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianificazione.getAttivitaByUser(
                that.userSelected
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAttivitaByUser", res);

                this.attivita = res.data.attivita;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncFilterAttivitaSrcByUser: async function () {


            var that = this;


            var response = await apipianificazione.getAttivitaByUser(
                that.userSelected
            ).then((res) => {

                console.log("res from getAttivitaByUser", res);

                that.attivita = res.data.attivita;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        deleteRow: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Delete",
                    html: "Do you want to delete the selected item ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_2dolist.png' />",
                    cancelButtonText: "<img title='Cancel' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_2dolist.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apiconfpianusers.deleteConf(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteConf", res);

                            that.$swal({
                                icon: "success",
                                text: "Data successfully deleted",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                window.table.ajax.reload();
                                that.hideShowButtons(false);

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Add"

                        },

                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Add"

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30,
                            title: "Edit",
                 
                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/elimina_2dolist.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Delete",
                         
                        },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        }

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont_conf_pianificazione_2dolist table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_conf_pianificazione_2dolist table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-bottom: 12px !important;
}


.cl_descrizione {
    width: 200px !important
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #70cf4e;

}

.pay a {
    color: #70cf4e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #70cf4e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}
</style>

<style>

.cont_conf_pianificazione_2dolist #myTable_wrapper {
    min-width: 800px;
    max-width: 800px;
    width: 800px;
}


.cont_conf_pianificazione_2dolist #myTable {
    margin-right: 3px;
    width: 700px !important;
}



.cont_conf_pianificazione_2dolist .nome_parente {
    min-width: 200px;
}

.cont_conf_pianificazione_2dolist .inserito {
    min-width: 70px;
}

.cont_conf_pianificazione_2dolist .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.cont_conf_pianificazione_2dolist .mail {
    min-width: 200px;
}

.cont_conf_pianificazione_2dolist td:first-child {
    padding: 10px;
}


.cont_conf_pianificazione_2dolist #myTable_filter {
    top: 66px;
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #70cf4e;

}

.cont_conf_pianificazione_2dolist .text_title {
    z-index: 1;
}

.cont_conf_pianificazione_2dolist .cont_buttons .text_title {
    min-width: 78px;

}

.cont_conf_pianificazione_2dolist .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_conf_pianificazione_2dolist .text_title {
    min-width: 78px !important;
}

.cont_conf_pianificazione_2dolist .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 80px
}

.cont_conf_pianificazione_2dolist .checkall {
    width: 13px;
    height: 13px;
}

.cont_conf_pianificazione_2dolist .cl_num {
    margin-left: 2px;
}

.cont_conf_pianificazione_2dolist .val_status {
    width: 10px !important;
}

.cont_conf_pianificazione_2dolist .cl_checkall {
    padding-left: 5px;
    padding-top: 2px;
}


.selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.selproprietario {
    max-width: 25px;
    min-height: 25px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.selstato {
    max-width: 25px;
    min-height: 25px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 29px;
    background-repeat: no-repeat;
    background-image: url(/todolist/img/stati_2dolist.png);
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.selattivita {
    max-width: 29px;
    min-height: 29px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.headerSheetAttivita {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetProprietari {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAttivita {
    margin-left: 15px;
}

.itemProprietario {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.cl_center {
    text-align: center !important;
}

.cl_right {
    text-align: right !important;
}

.cl_image {
    text-align: center !important;
}

.stato,
.invio_mail,
.ritardo {
    min-width: 56px;
    justify-content: center;
    text-align: center !important;
}

@media screen and (max-width: 768px) {

    .cont_conf_pianificazione_2dolist .cl_checkall {
        margin-left: 12px !important;
    }


    .cl_descrizione {}

    .stato,
    .invio_mail,
    .ritardo {
        display: inline-flex;
        height: 20px;
        align-items: center;
        transform: translateY(1px);
        justify-content: flex-start !important;
    }

    .cl_center {
        display: inline-flex;
    }

    .cl_right {
        display: inline-flex;
    }

    .cont_conf_pianificazione_2dolist .nome_parente {
        min-width: initial;
        word-break: break-all;
        max-width: 120px;
    }

    .cont_conf_pianificazione_2dolist .inserito {
        min-width: initial;
    }

    .cont_conf_pianificazione_2dolist .osp_assoc {
        min-width: initial;
        display: flex;
        justify-content: center;
    }

    .cont_conf_pianificazione_2dolist .mail {
        min-width: initial;
    }

    .cont_conf_pianificazione_2dolist #myTable_wrapper {
        min-width: initial;
        min-width: 100vw;
        max-width: 100vw;
        width: 100vw;

    }

    #myTable {
        width: inherit !important;
        max-width: 100vw;
    }

    .cont_conf_pianificazione_2dolist #myTable_filter {
        position: fixed;
        left: 0px;
        min-width: 100%;
        z-index: 3;
        top: 58px;
    }

    .selstato {
        max-width: 25px;
        min-height: 25px;
        margin-left: 2px;
        color: black !important;
        padding: 0px 0px;
        min-width: 14px !important;
        text-indent: -119px;
        background-size: 29px;
        background-repeat: no-repeat;
        background-image: url(/todolist/img/Filtro.png);
        z-index: 2;
        border: none !important;
        cursor: pointer;
        margin-top: 3px;
    }

    .cont_conf_pianificazione_2dolist .div_n {
        padding-left: 21px !important;
    }

}

.cont_conf_pianificazione_2dolist .item-native {
    padding-inline-start: 0px !important;
}
</style>
