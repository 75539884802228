<template>
<v-container class="w-container" style="padding-top:59px">

    <v-row>

        <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

            <div class="mainTitle" style="
            background-color: #70cf4e;
            color: white;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw">{{ titolo }}</div>

        </v-col>

    </v-row>

    <v-form style="" v-model="valid" ref="form" lazy-validation>

        <v-row>

            <v-col cols="12" md="12">

                <div id="cont_id_argomento" style="cursor:pointer">

                    <ion-item style="min-width:200px;transform: translateX(-6px);">
                        <ion-label style="font-size:12px;color:gray!important;opacity:1" position="floating"> Argomento </ion-label>
                        <ion-select id="argomento" name="argomento" class='argomento' cancel-Text="Chiudi" done-Text="" :value="attivita.id_argomento" interface="action-sheet" disabled readonly>
                            <ion-select-option v-for="item in argomenti" :key="item.id" :value='item.id'> {{ item.nome }}</ion-select-option>
                        </ion-select>
                    </ion-item>

                </div>

            </v-col>

        </v-row>



        <v-row>

            <v-col cols="12" md="12">

                <v-text-field v-model="attivita.nome" label="Nome" :rules="nameRules" required></v-text-field>

            </v-col>

        </v-row>

        <v-row>

            <v-col cols="12" md="12">

                <v-text-field v-model="attivita.descrizione" label="Descrizione"></v-text-field>

            </v-col>

        </v-row>

    </v-form>


    <div class="conttipologie">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetTipologie" class="vtipologiesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetTipologie" fixed style="position:fixed;">
                            Argomenti {{ sheetTipologie }}
                            <v-btn class='closeSheet' icon @click="sheetTipologie = !sheetTipologie">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>

                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchTipologie"></v-text-field>

                            </v-toolbar>

                        </div>


                        <v-divider></v-divider>

                        <div class="spacer" style="min-height:72px;"></div>

                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="item in filteredTipologie" :key="item.id" @click="manageClickTipologie(item)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemTipologia'>{{ item.nome }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>


</v-container>
</template>

<script>

import apiattivita from "../utils/attivita/apiattivita";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        var pointerVue = this;

        console.log("PARAMS: ", this.$route.params.id);

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        if (this.$route.params.id > 0) {

            this.titolo = "Copia Attività";

            setTimeout(() => {
                this.syncAttivita();
            }, 100);

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/attivita"
                    });
                    break;

                default:
                    break;
            }
        });


        $('body').on('click', '#cont_id_argomento', function (event) {
            console.log(event);

            if (!pointerVue.disField)
            {

                pointerVue.sheetTipologie = true;

            }


        });



    },

    computed: {

        filteredTipologie() {

            return _.orderBy(this.argomenti.filter(item => {
                if (!this.searchTipologie) return this.argomenti;
                return (item.nome.toLowerCase().includes(this.searchTipologie.toLowerCase()));
            }), 'headline')
        },



    },

    data: () => ({

        disField: true,

        sheetTipologie: false,
        searchTipologie: "",

        currentFile: null,
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",
        titolo: "Aggiungi Attività",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        attivita: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        select: null,
        checkbox: false,

        argomenti: JSON.parse(localStorage.getItem('td_info_global')).todo_argomenti,


    }),

    methods: {

        manageClickTipologie: async function (tipologia) {

            console.log("TIPO SEL: ", tipologia);

            this.sheetTipologie = false;

            this.attivita.id_argomento = tipologia.id;


        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },


        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/salva_todolist.png",
                        width: 30,
                        title: "Save"


                    },
                    {
                        text: "Parenti",
                        icon: "mdi-arrow-left",
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        title: "Back"

                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var that = this;

            console.log("ARGOMENTO: ", $("#argomento").val());


            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                //console.log("CUR FILE: ", that.currentFile);

                var errore = "";

                if ($("#argomento").val() == "") {

                    errore = "Specificare l'argomento";

                }

                if (errore == "")
                {

                    if (that.attivita.nome == null)
                    {

                        errore = "Specificare il nome";

                    }

                }



                if (errore == "") {

                    that.saveData();

                } else {

                    that.$swal({
                        icon: "error",
                        text: errore,
                        showConfirmButton: false,
                        timer: 2000
                    });

                }

            }
        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var response = null;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            response = await apiattivita.insertAttivita(
                that.attivita,
                v_token,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from insertAttivita", res);

                that.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });



                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/attivita"
                    });

                }, 200);







            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

            );








        },

        syncAttivita: async function () {

           // var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiattivita.getAttivita(
                that.$route.params.id

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAttivita", res);

                try {

                    this.attivita = res.data.attivita;

                    this.nameRules = [];
                    this.attivita.nome = null;
                    this.attivita.descrizione = null;
                    this.attivita.id = 0;



                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        }

    }

})
</script>

<style>
.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.headerSheetTipologie {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}


.itemTipologia {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}
</style>
