<template>
    <v-container fluid class="text-center d-flex align-center justify-center"
        style="max-width: 600px; min-width: 300px;padding-bottom:100px;">

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Aziende
                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="search"></v-text-field>
                            </v-toolbar>
                        </div>
                        <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                        close
                    </v-btn> -->
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <v-list-item class="text-left" v-for="(tile, key) in filteredAziende" :key="key"
                                    @click="manageClickAziende(tile)">
                                    <v-icon aria-hidden="false">mdi-domain</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetDipendenti">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Dipendenti
                            <!-- <v-switch @change="changeGruppi" style="position: absolute;z-index: 1;left: 8px;top: -13px;" v-model="switch1" :label="` `"></v-switch> -->

                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchDipendenti"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                                    @click="manageClickDipendenti(objDip, 0)">
                                    <v-icon aria-hidden="false">mdi-account</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.fld_name }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetEmpty">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">

                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>

                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetGruppi">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">
                            Gruppi
                            <!--  <v-switch @change="changeGruppi" style="position: absolute;z-index: 1;left: 8px;top: -13px;" v-model="switch1" :label="` `"></v-switch> -->
                            <v-btn class='closeSheet' icon @click="manageClose()">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                            <v-toolbar>
                                <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                    v-model="searchGruppi"></v-text-field>
                            </v-toolbar>
                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>
                                <v-list-item class="text-left" v-for="(objDip, key) in filteredGruppi" :key="key"
                                    @click="manageClickDipendenti(objDip, 1)">
                                    <v-icon aria-hidden="false">mdi-account-multiple</v-icon>
                                    <v-list-item-title class='itemAzienda'>{{ objDip.nome }}</v-list-item-title>
                                    <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                </v-list-item>
                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

        <v-dialog id='dialogMsgSend' content-class="dialogMsg" v-model="dialogMsgSend" width="100vw" style="min-width:100vw"
            max-width="500px">
            <v-card height="100vh" width="100vw">
                <v-card-title>

                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>

                            <v-col cols="12" sm="12" md="12">
                                <span class="v-input input">{{ "Messaggio a" }}<span class="corrente">{{ nomeCorrente
                                }}</span> </span>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field id='titolo' models="titolo" label="Titolo"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea id='body' outlined name="input-7-4" label="Testo" models="body"></v-textarea>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <template>
                                    <v-file-input show-size counter multiple label="File input"
                                        v-model="currentFile"></v-file-input>

                                </template>
                            </v-col>

                            <!--    <v-col cols="12" sm="12" md="12">
                            <v-btn @click="sendMsg" class="mx-2" fab color="yellow">
                                <v-icon>
                                    mdi-email-send-outline
                                </v-icon>
                            </v-btn>
                        </v-col> -->

                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <!--  <v-btn color="blue darken-1" text @click="closeSendMsg">
                    Close
                </v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <template>
            <div>

                <v-dialog id='dialogMsg' content-class="dialogMsg" v-model="dialogMsg" width="100vw" style="min-width:100vw"
                    max-width="500px">

                    <v-card v-if="defaultItem.read == 1 || defaultItem.accepted == true" height="100vh" width="100vw">
                        <v-card-title>

                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>

                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-h5">{{ "Messaggio" }}</span>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field readonly v-model="defaultItem.title" label="Titolo"></v-text-field>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-textarea readonly outlined name="input-7-4" label="Testo"
                                            v-model="defaultItem.body"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <!--    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="blue darken-1" text @click="close">
                            Close
                        </v-btn>
                    </v-card-actions> -->
                    </v-card>

                    <v-card v-else height="100vh" width="100vw">
                        <v-card-title>

                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <span class="text-h5">{{ "Accettazione" }}</span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">

                                        <v-checkbox v-model="defaultItem.accepted" @click="acceptRead()">
                                            <template v-slot:label>
                                                <div>
                                                    Dichiaro di accettare la lettura del messaggio

                                                </div>
                                            </template>
                                        </v-checkbox>

                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>

                </v-dialog>

                <v-data-table mobile-breakpoint="0" :headers="headers" :items="desserts" :options.sync="options"
                    :server-items-length="totalDesserts" :loading="loading" class="elevation-1"
                    :single-select="singleSelect" @click:row="handleClick">

                    <template v-slot:item.read="{ item }">

                        <!--  <v-img :alt="item.name" width="22" height="22" class=" " contain src="@/assets/exit.png" transition="scale-transition" @click="alerta(1)" /> -->

                        <!--   <v-checkbox @click="alerta(1)"></v-checkbox> -->
                        <div :class="'imgread' + item.read">
                            <v-img :alt="item.name" width="22" height="22" class=" " contain src="@/assets/message2.png"
                                transition="scale-transition" @click="openMsgDialog(item)" />
                        </div>
                    </template>

                    <template v-slot:item.created_at="{ item }">
                        <div :class="'msgDesc ' + 'msgread' + item.read" style=" ">
                            <span class='hour'>{{ item.created_at.substring(0, 10) }}</span> <span class='hour'>{{
                                item.created_at.substring(10, 19) }}</span>
                        </div>
                    </template>

                    <template v-slot:item.title="{ item }">
                        <div :class="'msgDesc ' + 'msgread' + item.read" style=" ">
                            {{ item.title }}
                        </div>
                    </template>

                    <template v-slot:item.dest="{ item }">
                        <div :class="'msgDest '" style=" ">
                            {{ item.dest }}
                        </div>
                    </template>

                    <!--     <template v-slot:top>
                    <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                </template> -->

                </v-data-table>
            </div>
        </template>

    </v-container>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";

import {
    bus
} from "../main";
import router from ".././router";
import $ from 'jquery';
import _ from 'lodash';
export default {

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },

    watch: {
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
    },

    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        this.dipendenti = this.$root.$children[0].dipendenti;

        this.gruppi = this.$root.$children[0].gruppi;

        this.aziende = this.$root.$children[0].aziende;
        this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {


                case "btn_download":
                    var url = "https://app.emtool.eu/public/documents/" + this.currItem.attachment;
                    window.location.href = url;

                    break;
                case "btn_delete":
                    this.delMsg();
                    break;

                case "btn_invia_msg":
                    this.sendMsg();
                    break;
                case "btn_companies":
                    //  this.setupButtons();
                    this.sheetAziende = true;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.sheetGruppi = false;
                    this.dialogMsgSend = false;
                    break;

                case "btn_dipendenti":
                    //  this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = true;
                    this.sheetGruppi = false;
                    this.dialogMsgSend = false;
                    break;

                case "btn_gruppi":

                    this.gruppi = this.$root.$children[0].gruppi;

                    //  this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.sheetGruppi = true;
                    this.dialogMsgSend = false;
                    break;

                case "btn_back":
                    this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetGruppi = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.dialogMsgSend = false;
                    this.dialogMsg = false;

                    this.closeSendMsg();

                    break;
                case "btn_aziende":

                    this.buttonsNewMessage();

                    this.dipendenti = this.$root.$children[0].dipendenti;
                    this.aziende = this.$root.$children[0].aziende;
                    this.globalArrAziende = this.$root.$children[0].globalArrAziende;

                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {
                        this.sheetAziende = true;
                        this.$root.$children[0].showProgress = false;

                    }, 300);

                    break;
                case "btn_add_tms":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;

                default:
                    break;
            }
        });

        this.setupButtons();
    },
    data: () => ({

        currentFile: null,
        selecting: 0,
        currItem: {},
        currGruppo: {},
        switch1: true,
        nomeCorrente: "",
        titolo: "",
        body: "",
        aziende: [],
        gruppi: [],
        globalArrAziende: [],
        dipendenti: [],
        search: '',
        sheetGruppi: false,
        sheetAziende: false,
        sheetEmpty: false,
        sheetDipendenti: false,
        searchDipendenti: '',
        searchGruppi: '',

        currAzienda: "",

        defaultItem: {
            title: 'ti',
            body: '',
            read: 0,
            accepted: 0,
            id: 0

        },
        defaultItemSend: {
            title: '',
            body: '',

        },

        dialogMsgSend: false,
        dialogMsg: false,

        singleSelect: true,

        totalDesserts: 0,
        desserts: [],
        loading: true,
        options: {},
        headers: [{
            text: ' ',
            value: 'read',
            align: 'start'
        },
        {
            text: 'Date',
            value: 'created_at',
            align: 'start'
        },
        {
            text: 'Title',

            sortable: true,
            value: 'title',
            align: 'start'
        },
        {
            text: 'Receiver',

            sortable: true,
            value: 'dest',
            align: 'start'
        },

        ],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        selectFile(file) {
            // this.progress = 0;
            this.currentFile = file;
        },

        alerta: function (val) {
            this.selecting = 1;
            console.log(val);
            setTimeout(() => {

                this.selecting = 0;
            }, 300);
        },
        acceptRead: function () {
            // console.log("item",item);
            // item.read=1;
            console.log("dessetrs", this.desserts);

            for (let [key, value] of Object.entries(this.desserts)) {

                console.log(value);
                if (this.currItem.id == this.desserts[key].id) {
                    this.desserts[key].read = 1;

                }
                // console.log("Messaggio", key, value);
                // arrMsg.push(value);
            }

            // console.log(   "currITems", this.currItem );
            this.updateMessage(this.currItem.message_id);
        },

        addBackDeleteBtn: function () {

            var pulsantis = [];
            pulsantis.push({
                text: "",
                icon: "mdi-arrow-left",
                link: "/back",
                id: "btn_back",
                disabled: false,
                image: "https://app.emtool.eu/public/_lib/img/back-2dolist.png",
                width: 30,
                title: "Back"


            },);

            if (this.currItem.attachment) {
                pulsantis.push({
                    text: "",
                    icon: "mdi-download-outline",
                    link: "/download",
                    id: "btn_download",
                    disabled: false,
                },);
            }


            setTimeout(() => {
                $("#btn_download").attr("href", "https://app.emtool.eu/public/documents/" + this.currItem.attachment);

            }, 300);




            var a = window.$cookies.get("a");
            if (a == 'Y' || a == 'S') {

                pulsantis.push({
                    text: "",
                    icon: "mdi-delete",
                    link: "/delete",
                    id: "btn_delete",
                    disabled: false,
                    title: "Delete",

                },);
            }

            this.$root.$children[0].bnavbuttons = pulsantis;

        },
        addBackBtn: function () {

            var pulsantis = [];
            pulsantis.push({
                text: "",
                icon: "mdi-arrow-left",
                link: "/back",
                id: "btn_back",
                disabled: false,
                image: "https://app.emtool.eu/public/_lib/img/back-2dolist.png",
                width: 30,
                title: "Back"


            },);

            /*  pulsantis.push({
                 text: "",
                 icon: "mdi-email-send-outline",
                 link: "/invia",
                 id: "btn_invia_msg",
                 disabled: false,
             }, ); */

            this.$root.$children[0].bnavbuttons = pulsantis;

        },
        handleClick(row) {

            if (this.selecting != 1) {
                // set active row and deselect others
                console.log("row", row);
                this.openMsgDialog(row);
                //this.addBackBtn();
                this.addBackDeleteBtn();

                this.desserts.map((item, index) => {
                    item.selected = item === row

                    this.$set(this.desserts, index, item)
                })

                // or just do something with your current clicked row item data
                console.log(row.sugar)
            }

        },

        manageClose: function () {

            this.setupButtons();
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.sheetDipendenti = false;
            this.sheetGruppi = false;

        },
        changeGruppi: function () {
            this.sheetDipendenti = !this.sheetDipendenti;
            this.sheetGruppi = !this.sheetGruppi;

            /* if(!this.switch1){
                this.sheetDipendenti = false ;
                this.sheetGruppi = true ;
            }
            else
                {
                     this.sheetDipendenti = true ;
                    this.sheetGruppi = false ;
                } */
        },

        delMsg: function () {

            //  var username = window.$cookies.get("username");
            //  var token = window.$cookies.get("token");
            var response;
            console.log("this.currItem", this.currItem);
            try {
                response = this.$api.deleteMessage(
                    this.currItem.message_id

                );

                this.$swal({
                    icon: "success",
                    text: "Your message has been deleted correctly",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    this.dialogMsg = false;
                    this.setupButtons();
                    this.getDataFromApi();
                }, 2000);

            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            console.log(response);

        },

        sendMsg: function () {

            var title = $("#titolo").val();
            var body = $("#body").val();

            //insertMessage(user_group, user, sender, title, msg, isgroup)

            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");
            var response;

            console.log(" this.currGruppo", this.currGruppo.nome);
            var isgroup = 0;
            if (this.currGruppo.nome !== undefined) {
                isgroup = 1;

            }
            if (title.length > 0) {
                try {
                    response = this.$api.insertMessage(
                        this.nomeCorrente,
                        token,
                        username,
                        title,
                        body,
                        isgroup,
                        this.currentFile

                    );

                    this.$swal({
                        icon: "success",
                        text: "Your message has been sent correctly",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        this.closeSendMsg();
                        this.getDataFromApi();
                    }, 2000);

                } catch (error) {
                    this.showSpinner = false;
                    console.log(error);
                }
            } else {
                this.$swal({
                    icon: "error",
                    text: "Please insert the title and body message"
                });

            }

            console.log(response);

        },
        changeDip: function (selectObj) {
            console.log(selectObj);
            var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    selNameDip = dip.fld_name;
                    //set display
                    this.nomeCorrente = atob(dip.v_user);
                }
            }
            console.log(selNameDip);
            //  this.$root.$children[0].curDipTop = selNameDip;

        },

        manageClickDipendenti: function (dipendente, isGroup) {

            console.log(isGroup);
            this.sheetDipendenti = false;
            this.sheetGruppi = false;
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.currDipendente = dipendente.resourceid;
            this.dialogMsgSend = true;

            if (this.currGruppo) {
                this.currGruppo = dipendente;
                this.nomeCorrente = dipendente.nome;

                console.log("this.currGruppo", this.currGruppo);

            } else {
                this.currGruppo = {};

            }

            // this.$root.$children[0].bnavbuttons.push ( {}  );

            //            this.resetDay();

            setTimeout(() => {
                var pulsantis = [{
                    text: "Dashboard",
                    icon: "mdi-home-circle",
                    link: "/dash",
                    id: "btn_exit",
                    disabled: false,
                },];

                pulsantis.push({
                    text: "",
                    icon: "mdi-arrow-left",
                    link: "/back",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back-2dolist.png",
                    width: 30,
                    title: "Back"


                },);

                pulsantis.push({
                    text: "",
                    icon: "mdi-email-send-outline",
                    link: "/invia",
                    id: "btn_invia_msg",
                    disabled: false,
                },);

                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);

            this.changeDip();
            setTimeout(() => {
                //  this.syncMonth();
            }, 100);

        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            this.currDipendente = "";
            /*             this.$root.$children[0].curDipTop = "";

                        this.$root.$children[0].curDipOreTop = "";
                        this.$root.$children[0].curDipOreTopLabel = ""; */

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];
            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            console.log("Elenco aziende:", this.aziende);
            for (var az of aziende) {
                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);
                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);
                    }
                }
            }
            this.dipendenti = arrDipendenti;

        },

        manageClickAziende: async function (azienda) {

            this.currAzienda = azienda;
            this.buttonsNewMessage();
            await this.syncArrayDipendenti();

            console.log("CurAzienda", this.currAzienda);
            console.log(this.dipendenti);

            setTimeout(() => {

                this.sheetEmpty = true;

                //    this.sheetDipendenti = true;
            }, 300);

        },

        openMsgDialog: function (item) {

            // item.read = "1";

            this.currItem = item;
            this.dialogMsg = true;
            //this.editedItem = Object.assign({}, this.defaultItem)
            this.defaultItem.title = item.title;
            this.defaultItem.body = item.desc;
            this.defaultItem.read = item.read;
            this.defaultItem.id = item.id;

            this.defaultItem.accepted = item.read;

            //  this.updateMessage(item.id);

        },

        closeSendMsg() {
            this.dialogMsgSend = false;
            this.setupButtons();

        },

        close() {
            this.dialogMsg = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        getDataFromApi() {
            this.loading = true
            this.realApiCall().then(data => {
                this.desserts = data.items
                this.totalDesserts = data.total
                this.loading = false
            })
        },

        realApiCall: async function () {
            let items = await this.getMessages();
            //console.log ( "ciao",1 );

            return new Promise((resolve, reject) => {
                //console.log ( "ciao",2 );
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                console.log(reject);

                //const total = items.length

                const total = this.totalDesserts;
                //   alert(total);

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                }
                console.log(itemsPerPage, page);
                /* if (itemsPerPage > 0) {
                    items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                } */

                setTimeout(() => {
                    resolve({
                        items,
                        total,
                    })
                }, 10)
            })
        },
        getMessages: async function () {

            var response;

            var token = window.$cookies.get("token");

            console.log("Options :", this.options);
            var itemsPerPage = this.options.itemsPerPage;

            var start = (this.options.itemsPerPage * this.options.page) - this.options.itemsPerPage;
            var arrMsg = [];
            try {
                response = await this.$api.getMessages(
                    token,
                    start,
                    itemsPerPage
                );

                this.totalDesserts = response.data.Messages.msg_tot;
                this.$root.$children[0].totalMsg = response.data.Messages.msg_tot_nr;

                var msg = response.data.Messages.pagine[0].messaggi;
                console.log("msg", msg);
                /*  for ( let [key] in msg)
                 {
                     //console.log ( "messaggio", msg[key] );
                     arrMsg.push ( msg[key] ) ;

                 } */

                for (let [key, value] of Object.entries(msg)) {
                    console.log("Messaggio", key, value);
                    arrMsg.push(value);
                }

            } catch (error) {
                // this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
            return arrMsg;

            /*  return [{
                     name: 'Nuovi cedolini',
                     body: 'Sono disponibili i nuovi cedolini',
                     date: '2022-12-12',
                     read: '1',
                     image: 'timesheet2.png',
                 },
                 {
                     name: 'Ritirare documenti ufficio',
                     body: 'Sono disponibili i nuovi cedolini',
                     date: '2022-11-12',
                     read: '1',
                     image: 'timesheet2.png',
                 },
                 {
                     name: 'Disponibile il nuovo cedolino',
                     body: 'Sono disponibili i nuovi cedolini',
                     date: '2022-10-12 ',
                     read: '1',
                     image: 'timesheet2.png',
                 },
                 {
                     name: 'Ritiro pc ufficio',
                     body: 'Sono disponibili i nuovi cedolini',
                     date: '2022-12-12  ',
                     read: '1',
                     image: 'timesheet2.png',
                 },

             ] */
        },

        setupButtons: async function () {
            setTimeout(() => {
                var pulsantis = [{
                    text: "Dashboard",
                    icon: "mdi-home-circle",
                    link: "/dash",
                    id: "btn_exit",
                    disabled: false,
                },];
                var a = window.$cookies.get("a");
                if (a == 'Y' || a == 'S') {
                    pulsantis.push({
                        text: "",
                        icon: "mdi-message-plus",
                        link: "/dash",
                        id: "btn_aziende",
                        disabled: false,
                    },);
                }
                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },

        buttonsNewMessage: async function () {
            setTimeout(() => {
                var pulsantis = [
                    /* {
                                        text: "Dashboard",
                                        icon: "mdi-home-circle",
                                        link: "/dash",
                                        id: "btn_exit",
                                        disabled: false,
                                    }, */
                ];
                //var a = window.$cookies.get("a");

                //if (a == 'Y' || a == 'S') {
                pulsantis.push({
                    text: "",
                    icon: "mdi-arrow-left",
                    link: "/back",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back-2dolist.png",
                    width: 30,
                    title: "Back"


                },);

                /*    pulsantis.push({
                       text: "",
                       icon: "mdi-domain",
                       link: "/aziende",
                       id: "btn_companies",
                       disabled: false,
                   }, ); */

                if (this.currAzienda) {
                    pulsantis.push({
                        text: "",
                        icon: "mdi-account",
                        link: "/dipendenti",
                        id: "btn_dipendenti",
                        disabled: false,
                    },);

                    pulsantis.push({
                        text: "",
                        icon: "mdi-account-multiple",
                        link: "/gruppi",
                        id: "btn_gruppi",
                        disabled: false,
                    },);
                }

                //}
                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },

        updateMessage: async function (id_msg) {

            //  var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");

            this.$root.$children[0].totalMsg = this.$root.$children[0].totalMsg - 1;

            var response;

            // console.log(username, token_system);
            try {
                response = this.$api.updateStatusMsg(
                    token,
                    id_msg
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
        },

        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #45b7e9;

}

.pay a {
    color: #45b7e9;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #45b7e9;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.dialogMsg {
    min-width: 100vw !important;
}

span.corrente {
    margin-left: 4px;
}

.imgread1 {
    visibility: hidden;
}

.imgread0 {
    visibility: visible;
}

.msgread1 {
    font-weight: normal;
}

.msgread0 {
    font-weight: bold;
}

.v-application .elevation-1 {
    box-shadow: none !important;
    ;
}

span.hour {
    font-size: 10px;
}

.msgDest {
    font-size: 10px;
}

.msgDesc {
    min-width: 66px;
    font-size: 10px;
}</style>
