<template>
    <div class="cont-table">

        <div class="row cont_reminder_2dolist cont_tickets">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive" style="">

                            <table id="myTable" style="margin-top:59px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox style="width:10px!important;height:10px!important"
                                                    name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>

                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'>
                                            <div style="margin-left:5px">Owner</div>
                                        </th>

                                        <th class='all' style='text-align:center!important'>
                                            Assign To</th>

                                        <th class='desktop'>
                                            Activity</th>

                                        <th class='desktop'>
                                            <div style="margin-left:5px">Description</div>
                                        </th>

                                        <th class='all' style='text-align:center!important'>
                                            Status</th>

                                        <th class='desktop' style='text-align:center!important'>
                                            Freq. (MM)</th>

                                        <th class='desktop' style='text-align:center!important'>
                                            Date Ref.</th>

                                        <th class='desktop' style='text-align:center!important'>
                                            Hour Ref.</th>


                                        <th class='desktop' style='text-align:center!important'>
                                            Attachment</th>





                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="11">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="contattivita">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAttivita" class="vattivitasheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAttivita" fixed style="position:fixed;">
                                Attività
                                <v-btn class='closeSheet' icon @click="sheetAttivita = !sheetAttivita">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchAttivita"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAttivita" :key="item.id"
                                        @click="manageClickAttivita(item)">

                                        <v-img alt="Attivita Logo" class="  " contain
                                            src="@/assets/filter_activity2.png" max-width="24" max-height="24"
                                            transition="scale-transition" />

                                        <v-list-item-title class='itemAttivita'>{{ item.nome }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contproprietari">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetProprietari" class="vproprietarisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetProprietari" fixed style="position:fixed;">
                                Proprietari
                                <v-btn class='closeSheet' icon @click="sheetProprietari = !sheetProprietari">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchProprietari"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredProprietari" :key="item.login"
                                        @click="manageClickProprietario(item)">

                                        <v-img alt="Proprietario Logo" class="  " contain src="@/assets/owners.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemProprietario'>{{ item.name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>


        <div class="contpopupsearch" style="display:none">

            <template>
                <modal name="popupSearch" :clickToClose="false" :width="262" :height="215">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            <v-img alt="" src="@/assets/2dolist_ricerca3.png" max-width="25" max-height="25" />

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <v-text-field v-model="textSearch" label="">
                            </v-text-field>

                        </v-col>

                    </v-row>



                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-search" contain src="@/assets/btn_cancel3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelSearch" />

                                <v-img alt="" class="btn-confirm-search" contain src="@/assets/btn_confirm3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmSearch" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupactivate" style="display:none">

            <template>
                <modal name="popupActivate" :clickToClose="false" :width="262" :height="260">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">
                            Attivazione
                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dataRiferimento" label="Data Riferimento" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dataRiferimento" no-title @input="showPicker = false"
                                    locale="it"></v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-left:12px">

                            <label class="v-label v-label--active theme--light" style="margin-top:12px!important">Ora
                                Riferimento</label>
                            <ion-datetime
                                minute-Values="0,01,02,03,04,05,06,07,08,09,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59"
                                style="border-bottom: 1px solid #ced4da;padding: 4px;margin-top:10px;width:236px"
                                id="ora_riferimento" name="ora_riferimento" class='hourbooking' cancel-Text=""
                                done-Text="" display-format="HH:mm">
                            </ion-datetime>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-activate" contain src="@/assets/btn_cancel3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" title="Cancel" @click="btnCancelActivate" />

                                <v-img alt="" class="btn-confirm-activate" contain
                                    src="@/assets/btn_confirm3_2dolist.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmActivate" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>


        <div class="contpopupother" style="display:none">

            <template>
                <modal name="popupOther" :clickToClose="false" :width="262" :height="266">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">
                            Invio mail e gestione altro utente
                        </v-col>

                    </v-row>

                    <v-row style="margin-bottom:0px!important;padding-bottom:0px!important">

                        <v-col cols="12" md="12" style="margin-bottom:0px!important;padding-bottom:0px!important">

                            <v-text-field id="mailOther" v-model="mailOther" label="E-Mail"></v-text-field>

                        </v-col>

                    </v-row>

                    <v-row style="margin-top:0px!important;padding-top:0px!important">

                        <v-col cols="12" md="12" style="margin-top:0px!important;padding-top:0px!important">

                            <v-checkbox id="chkGestioneOther" label="Gestione" v-model="gestioneOther" true-value="S"
                                false-value="N" class="cl-checkbox"></v-checkbox>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-mail-other" contain
                                    src="@/assets/btn_cancel3_2dolist.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelMailOther" />

                                <v-img alt="" class="btn-confirm-mail-other" contain
                                    src="@/assets/btn_confirm3_2dolist.png" max-width="30" max-height="30"
                                    style="cursor:pointer" transition="scale-transition" title="Confirm"
                                    @click="btnConfirmMailOther" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>






    </div>
</template>

<script>
import apipianificazione from "../utils/pianificazione/apipianificazione";
import apireminder from "../utils/reminder/apireminder";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';
import _ from 'lodash';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    computed: {

        filteredAttivita() {
            return _.orderBy(this.attivita.filter(item => {
                if (!this.searchAttivita) return this.attivita;
                return (item.nome.toLowerCase().includes(this.searchAttivita.toLowerCase()));
            }), 'headline')
        },

        filteredProprietari() {

            return _.orderBy(this.proprietari.filter(item => {
                if (!this.searchProprietari) return this.proprietari;
                return (item.name.toLowerCase().includes(this.searchProprietari.toLowerCase()));
            }), 'headline')

        }

    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        this.$root.$children[0].checkLogout();

        this.proprietari = JSON.parse(localStorage.getItem('td_info_global')).todo_utenti;

        this.searchAttivita = "";
        this.searchProprietari = "";
        this.userSelected = "-1";

        window.statoid = "-1";
        window.propid = "-1";
        window.curSrcText = "";


        var a = window.$cookies.get("a");

        if (a == "N") {

            this.userSelected = window.$cookies.get("username");

        }

        /*eslint-disable no-undef*/
        $(document).ready(
            function () {

                window.uname = window.$cookies.get("username");
                var uname = window.uname;

                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        setTimeout(() => {

            this.syncFilterAttivita();

        }, 100);

        $(document).ready(function () {

            $("#ora_riferimento").on("ionChange", function (opt) {

                console.log(opt);


            });

            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editReminder/0"
                    });
                    break;


                case "btn_edit":


                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }
                            ids = ids + $(this).attr("data-id");
                        }

                    });

                    console.log("IDS SEL:", ids);

                    router.push({
                        path: "/editReminder/" + ids
                    });



                    break;

                case "btn_delete":

                    this.deleteRow();

                    break;

                case "btn_down_attach":

                    this.downAttach();

                    break;

                case "btn_enable":

                    this.popupActivate();

                    break;

                case "btn_disable":

                    this.disableReminder();

                    break;

                case "btn_other":

                    this.openPopupMailOther();

                    break;

                default:
                    break;
            }
        });

        this.setupButtonsGrid();

        this.setupGrid();

        // di default i pulsanti edit, delete vengono nascosti
        this.hideShowButtons(false);

    },
    data: () => ({

        mailOther: "",
        gestioneOther: "N",

        dataRiferimento: "",
        oraRiferimento: "",

        textSearch: "",

        sheetAttivita: false,
        searchAttivita: '',

        sheetProprietari: false,
        searchProprietari: "",

        userSelected: "-1",

        currentFile: null,
        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,

        proprietari: [],
        attivita: [],

    }),

    methods: {


        getCurrentDate: function () {

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();


            today = yyyy + "-" + mm + "-" + dd;

            return today;

        },

        getCurrentTime: function () {

            var today = new Date();
            var time = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0');

            return time;

        },

        openPopupMailOther: async function () {


            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apireminder.getInfoOther(
                ids
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getInfoOther", res);

                that.mailOther = res.data.Email;
                that.gestioneOther = res.data.Gestito;

                setTimeout(() => {

                    $(".contpopupother").show();

                    this.$modal.show('popupOther');

                }, 150);




            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );

        },


        btnCancelMailOther: function () {

            this.$modal.hide('popupOther');

        },


        btnConfirmMailOther: function () {

            var that = this;

            var errore = "";

            if (that.mailOther == "") {
                errore = "E-Mail richiesta";
            }

            if (errore == "") {

                if (!that.checkMail(that.mailOther)) {

                    errore = "E-Mail non valida";

                }

            }


            if (errore == "") {

                console.log("ok");

                that.saveDataOther();

            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }




        },

        saveDataOther: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            that.$modal.hide('popupOther');

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apireminder.updateInfoOther(
                ids,
                that.mailOther,
                that.gestioneOther,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from updateInfoOther", res);

                that.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    window.table.ajax.reload();
                    that.hideShowButtons(false);

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );



        },

        checkMail: function (v_mail) {

            /* eslint-disable */
            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(v_mail);

        },


        disableReminder: async function () {

            var that = this;


            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apireminder.deactivateReminder(
                ids,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from activateReminder", res);

                that.$swal({
                    icon: "success",
                    text: "Reminder disattivato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    window.table.ajax.reload();
                    that.hideShowButtons(false);

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        btnCancelActivate: function () {

            this.$modal.hide('popupActivate');

        },


        btnConfirmActivate: function () {



            var that = this;

            var errore = "";

            if (that.dataRiferimento == "") {
                errore = "Data riferimento richiesta";
            }

            if (errore == "") {

                if ($("#ora_riferimento").val() == "") {

                    errore = "Ora riferimento richiesta";

                }

            }


            if (errore == "") {

                console.log("ok");

                that.activateReminder();


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });



            }







        },


        activateReminder: async function () {

            var that = this;

            that.$modal.hide('popupActivate');

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            console.log("IDS SEL:", ids);

            var vTime = "";
            var strTime = new String($("#ora_riferimento").val());
            if (strTime.indexOf("T") != -1) {

                var elements = strTime.split("T");
                var elements2 = elements[1].split(":");
                vTime = elements2[0] + ":" + elements2[1];

            }
            else {
                vTime = strTime;
            }


            console.log("ORA: ", vTime);


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apireminder.activateReminder(
                ids,
                that.dataRiferimento,
                vTime
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from activateReminder", res);

                that.$swal({
                    icon: "success",
                    text: "Reminder attivato correttamente",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    window.table.ajax.reload();
                    that.hideShowButtons(false);

                }, 200);

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        popupActivate: async function () {

            var that = this;

            that.dataRiferimento = that.getCurrentDate();


            setTimeout(() => {

                $(".contpopupactivate").show();

                that.$modal.show('popupActivate');

                setTimeout(() => {

                    $("#ora_riferimento").val(that.getCurrentTime());

                }, 150);

            }, 100);



        },

        downAttach: async function () {

            var v_attach = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {


                    v_attach = $(this).attr("file-attach");


                }

            });

            console.log("FILE NAME: ", v_attach);

            var file_path = this.getUrlAttach(v_attach);

            console.log("FULL PATH: ", file_path);

            var a = document.createElement('A');
            a.href = file_path;
            a.target = "_blank";
            a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

        },

        hideShowButtonsSelRow: function () {

            var tot = 0;
            var v_attach = "";
            var v_stato = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                    v_stato = $(this).attr("is-enable");
                    v_attach = $(this).attr("attach-exist");


                }

            });

            console.log("TOT SEL: ", tot);

            setTimeout(() => {

                var pulsantis = [];

                if (tot == 0) {

                    pulsantis.push(

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            //class: "inverted",
                            title: "Add",
                            width: 30,
                            title: "Add"


                        }

                    );


                }



                if (tot == 1) {


                    pulsantis.push(

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            //class: "inverted",
                            title: "Edit",
                            width: 30

                        }

                    );


                    if (v_stato == "S") // reminder abilitato
                    {

                        pulsantis.push(

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/disabilita_2dolist.png",
                                link: "/info",
                                id: "btn_disable",
                                disabled: false,
                                title: "Disabilita",
                                width: 30

                            }

                        );



                    }

                    if (v_stato == "N") // reminder disabilitato
                    {

                        pulsantis.push(

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/abilita_2dolist.png",
                                link: "/info",
                                id: "btn_enable",
                                disabled: false,
                                title: "Abilita",
                                width: 30

                            }

                        );


                    }

                    if (v_attach == "S") // allegato presente
                    {

                        pulsantis.push(

                            {
                                text: "Edit",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/download_2dolist.png",
                                link: "/info",
                                id: "btn_down_attach",
                                disabled: false,
                                title: "Scarica Allegato",
                                width: 30

                            }

                        );



                    }



                    pulsantis.push(

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/mail_gestione_other_2dolist.png",
                            link: "/info",
                            id: "btn_other",
                            disabled: false,
                            title: "Invio mail e gestione altro utente",
                            width: 30

                        }

                    );




                }

                if (tot > 0) {


                    pulsantis.push(

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/elimina_2dolist.png",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            // class: "inverted",
                            title: "Delete",
                            width: 30

                        }

                    );




                }

                this.$root.$children[0].bnavbuttons = pulsantis;


            }, 100);



        },

        btnCancelSearch: function () {

            this.$modal.hide('popupSearch');

        },

        btnConfirmSearch: function () {

            console.log("ok");

            window.curSrcText = this.textSearch;

            this.$modal.hide('popupSearch');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            setTimeout(function () {

                window.table.ajax.reload();

            }, 200);

        },

        manageClickAttivita: async function (attivita) {

            console.log("ATTIVITA SEL: ", attivita);

            this.sheetAttivita = false;

            window.attivitaid = attivita.id;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            this.hideShowButtons(false);

            setTimeout(function () {

                window.table.ajax.reload();

            }, 100);

        },

        manageClickProprietario: async function (proprietario) {

            console.log("PROPRIETARIO SEL: ", proprietario);

            this.sheetProprietari = false;

            window.propid = proprietario.login;

            this.userSelected = proprietario.login;

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            this.syncFilterAttivitaSrcByUser();


            this.hideShowButtons(false);


            setTimeout(function () {

                window.table.ajax.reload();

            }, 100);


        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + nome_metodo;

        },


        getUrlAttach: function (nome_file) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/documents/" + nome_file;

        },



        setupGrid: function () {

            var v_token = window.$cookies.get("token");
            var a = window.$cookies.get("a");


            //var v_tipo_reminder = window.$cookies.get("sel_tipo_reminder");
            var v_tipo_reminder = "P";

            var v_desc_reminder = "Reminder"

            if (v_tipo_reminder == "P") {

                v_desc_reminder = "Personal Reminder";

            }


            var filtri = "";
            if ((a == "S") || (a == "Y")) {

                filtri = `<img src='/todolist/img/refresh_2dolist.png' style='border:0!important;' class='selaggiorna' title='Refresh' /><img src='/todolist/img/utenti_2dolist.png' style='border:0!important;' class='selproprietario' title='Users' /><div style="transform: translateY(-1px);margin-left:7px" title='Status'>
                <ion-select   data-id="0"  class="selstato" interface="action-sheet" placeholder=" " title='Stati'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='S'>Abilitati</ion-select-option><ion-select-option  value='N'>Non Abilitati</ion-select-option></div><img src='/todolist/img/attivita_2dolist.png' style='border:0!important;' class='selattivita' title='Activities' />`;

            } else {

                filtri = `<img src='/todolist/img/refresh_2dolist.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' />
                <div title='Stati' style='margin-left:7px;margin-top:-3px'>
                    <ion-select   data-id="0"  class="selstato" interface="action-sheet" placeholder=" " title='Stati'><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='S'>Abilitati</ion-select-option><ion-select-option  value='N'>Non Abilitati</ion-select-option></div><img src='/todolist/img/attivita_2dolist.png' style='border:0!important;margin-left:10px' class='selattivita' title='Attività' />`;

            }

            console.log(filtri);

            var pointerVue = this;

            const date = new Date().toJSON().slice(0, 10)
            console.log(date) //2015-07-23

            $(document).ready(function () {

                window.id_par_sel = 0;

                window.table = $('#myTable').DataTable({
                    // dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                    //     "<'rows colcontTable'<'col-sm-12 'tr>>" +
                    //     "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 30,

                    processing: false,
                    paging: true,
                    serverSide: true,


                    ajax: {
                        url: pointerVue.getUrlDomain("TodolistRemiderApi") + '?username=' + v_token,
                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.statoid = window.statoid;
                            valori.attivitaid = window.attivitaid;

                            valori.propid = window.propid;

                            valori.curSrcText = window.curSrcText;


                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label><select name="myTable_length" style="display:none!important" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select></label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: [{
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'proprietario',
                        orderable: false,
                        name: 'proprietario',
                        width: '5%'
                    },

                    {
                        data: 'email_other',
                        orderable: false,
                        name: 'email_other',
                        width: '3%'
                    },


                    {
                        data: 'nome',
                        orderable: false,
                        name: 'nome',
                        width: '5%'
                    },

                    {
                        data: 'descrizione',
                        orderable: false,
                        name: 'descrizione',
                        width: '7%'
                    },

                    {
                        data: 'abilitato',
                        orderable: false,
                        name: 'abilitato',
                        width: '1%'
                    },

                    {
                        data: 'durata_min',
                        orderable: false,
                        name: 'durata_min',
                        width: '3%'
                    },

                    {
                        data: 'data_start',
                        orderable: false,
                        name: 'data_start',
                        width: '3%'
                    },

                    {
                        data: 'ora_start',
                        orderable: false,
                        name: 'ora_start',
                        width: '3%'
                    },

                    {
                        data: 'allegato',
                        orderable: false,
                        name: 'allegato',
                        width: '1%'
                    },


                    ],

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        $(".downloadspinner").hide();

                        setTimeout(() => {

                            pointerVue.$root.$children[0].showProgress = false;

                        }, 700);


                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class="text_title">` + v_desc_reminder + `</div>
                    ` + filtri + `

                </div>
                <div><img src='/todolist/img/ricerca.png' style='border:0!important;' class='selricerca' title='Ricerca' /></div>

            `);

                console.log("EVENTO " + window.pianificazioneAddedEvents);

                if (window.pianificazioneAddedEvents === undefined) {

                    $(".selricerca").on("click", function (opt) {
                        console.log(opt);

                        pointerVue.textSearch = "";

                        $(".contpopupsearch").show();

                        pointerVue.$modal.show('popupSearch');

                    });


                    $('body').on('click', '.paginate_button', function () {
                        $(".spin").show();

                    });

                    $('body').on('click', '.cont_reminder_2dolist tr', function () {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            pointerVue.hideShowButtonsSelRow();


                        }


                    });

                }

                $('body').on('click', '.selaggiorna', function () {


                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    pointerVue.hideShowButtons(false);

                    setTimeout(function () {

                        window.table.ajax.reload();

                    }, 200);

                });

                $('body').on('click', '.selattivita', function () {

                    console.log(("EVENTO CLICK SELATTIVITA"));

                    pointerVue.sheetAttivita = true;

                });

                $('body').on('click', '.selproprietario', function () {

                    console.log(("EVENTO CLICK SELPROPRIETARIO"));

                    pointerVue.sheetProprietari = true;

                });

                //setup ionic calendar top
                setTimeout(function () {

                    $(".selstato").on("ionChange", function (opt) {
                        console.log(opt);

                        window.statoid = this.value;

                        pointerVue.showSpinner = true;
                        pointerVue.$root.$children[0].showProgress = true;

                        pointerVue.hideShowButtons(false);

                        setTimeout(function () {

                            window.table.ajax.reload();

                        }, 200);


                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.pianificazioneAddedEvents = true;

            });

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                        //class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Add"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                        //class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Edit",

                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/elimina_2dolist.png",
                        //  class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        width: 30,
                        title: "Delete",

                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },
        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

        syncFilterAttivita: async function () {


            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianificazione.getAttivitaByUser(
                that.userSelected
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAttivitaByUser", res);

                this.attivita = res.data.attivita;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncFilterAttivitaSrcByUser: async function () {


            var that = this;


            var response = await apipianificazione.getAttivitaByUser(
                that.userSelected
            ).then((res) => {

                console.log("res from getAttivitaByUser", res);

                that.attivita = res.data.attivita;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        deleteRow: async function () {

            var that = this;

            that.$swal
                .fire({
                    title: "Delete",
                    html: "Do you want to delete the selected item ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_2dolist.png' />",
                    cancelButtonText: "<img title='Cancel' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_2dolist.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);

                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;

                        var response = await apireminder.deleteReminder(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteReminder", res);

                            that.$swal({
                                icon: "success",
                                text: "Data successfully deleted",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.dialogMsg = false;
                                that.setupButtons();

                                window.table.ajax.reload();
                                that.hideShowButtons(false);

                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("ERrori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },

        hideShowButtons: async function (v_enable) {

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Add"

                        },

                    ];

                }

                if (v_enable == true) {

                    pulsantis = [

                        {
                            text: "Add",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                            // class: "inverted",
                            link: "/settings",
                            id: "btn_add",
                            disabled: false,
                            width: 30,
                            title: "Add"

                        },

                        {
                            text: "Edit",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_edit",
                            disabled: false,
                            width: 30,
                            title: "Edit",

                        },

                        {
                            text: "Delete",
                            icon: "mdi-home-circle",
                            image: "https://app.emtool.eu/public/_lib/img/elimina_2dolist.png",
                            // class: "inverted",
                            link: "/info",
                            id: "btn_delete",
                            disabled: false,
                            width: 30,
                            title: "Delete",

                        },

                    ];

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        }

    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
/* .cont_reminder_2dolist table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_reminder_2dolist table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
    padding-bottom: 12px !important;
} */


.cl_descrizione {
    width: 200px !important
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #70cf4e;

}

.pay a {
    color: #70cf4e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #70cf4e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}
</style>

<style>
.cl-checkbox i {
    font-size: 13px !important;
}



.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/_lib/img/btn_cancel3_2dolist.png") !important;
    background-size: 30px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/public/_lib/img/btn_confirm3_2dolist.png") !important;
    background-size: 30px !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    text-indent: 1px !important;
    min-width: 40px !important;
    max-width: 34px !important;
}


.cont_reminder_2dolist #myTable_wrapper {
    min-width: initial;
    min-width: 1100px;
    max-width: 1100px;
    width: 1100px;
}



.cont_reminder_2dolist #myTable {
    margin-right: 3px;
    width: 1000px !important;
}

.cont_reminder_2dolist .nome_parente {
    min-width: 200px;
}

.cont_reminder_2dolist .inserito {
    min-width: 70px;
}

.cont_reminder_2dolist .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.cont_reminder_2dolist .mail {
    min-width: 200px;
}

.cont_reminder_2dolist td:first-child {
    padding: 10px;
}

.cont_reminder_2dolist #myTable_filter {
    top: 66px;
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
    border-color: #70cf4e;

}

.cont_reminder_2dolist .text_title {
    z-index: 1;
}

.cont_reminder_2dolist .cont_buttons .text_title {
    min-width: 78px;

}

.cont_reminder_2dolist .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


.cont_reminder_2dolist .text_title {
    min-width: 78px !important;
}

.cont_reminder_2dolist .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 80px
}

.cont_reminder_2dolist .checkall {
    width: 13px;
    height: 13px;
}

.cont_reminder_2dolist .cl_num {
    margin-left: 2px;
}

.cont_reminder_2dolist .val_status {
    width: 10px !important;
}

.cont_reminder_2dolist .cl_checkall {
    padding-left: 6px;
    padding-top: 2px;
}


.selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 13px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.selproprietario {
    max-width: 25px;
    min-height: 25px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.selstato {
    max-width: 25px;
    min-height: 25px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 29px;
    background-repeat: no-repeat;
    background-image: url(/todolist/img/stati_2dolist.png);
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.selattivita {
    max-width: 29px;
    min-height: 29px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.headerSheetAttivita {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetProprietari {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemAttivita {
    margin-left: 15px;
}

.itemProprietario {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

.cl_center {
    text-align: center !important;
}

.cl_right {
    text-align: right !important;
}

.cl_image {
    text-align: center !important;
}

.stato,
.invio_mail,
.ritardo {
    min-width: 56px;
    justify-content: center;
    text-align: center !important;
}

@media screen and (max-width: 768px) {

    .cont_reminder_2dolist .cl_checkall {
        margin-left: 18px !important;
    }


    .cl_descrizione {
        display: inline;
    }

    .stato,
    .invio_mail,
    .ritardo {
        display: inline-flex;
        height: 20px;
        align-items: center;
        transform: translateY(1px);
        justify-content: flex-start !important;
    }

    .cl_center {
        display: inline-flex;
    }

    .cl_right {
        display: inline-flex;
    }

    .cont_reminder_2dolist .nome_parente {
        min-width: initial;
        word-break: break-all;
        max-width: 120px;
    }

    .cont_reminder_2dolist .inserito {
        min-width: initial;
    }

    .cont_reminder_2dolist .osp_assoc {
        min-width: initial;
        display: flex;
        justify-content: center;
    }

    .cont_reminder_2dolist .mail {
        min-width: initial;
    }


    .cont_reminder_2dolist #myTable_wrapper {
        min-width: initial;
        min-width: 100vw;
        max-width: 100vw;
        width: 100vw;
    }


    .cont_reminder_2dolist #myTable {
        max-width: 99vw;
        width: 99vw !important;
    }

    .cont_reminder_2dolist #myTable_filter {
        position: fixed;
        left: 0px;
        min-width: 100%;
        z-index: 3;
        top: 58px;
    }

    .selstato {
        max-width: 25px;
        min-height: 25px;
        margin-left: 2px;
        color: black !important;
        padding: 0px 0px;
        min-width: 14px !important;
        text-indent: -119px;
        background-size: 29px;
        background-repeat: no-repeat;
        background-image: url(/todolist/img/Filtro.png);
        z-index: 2;
        border: none !important;
        cursor: pointer;
        margin-top: 3px;
    }

    .cont_reminder_2dolist .div_n {
        padding-left: 21px !important;
    }

    .cont_reminder_2dolist table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
    table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
        margin-top: -8px !important
    }

    .cont_reminder_2dolist table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
    .cont_reminder_2dolist table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
        padding-left: 25px !important;

    }

}

.cont_reminder_2dolist .item-native {
    padding-inline-start: 0px !important;
}
</style>
