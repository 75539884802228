import axios from 'axios'

class APIReminder {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async getUserInfo(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("getPianificazioniUserInfoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getAttivitaByArgomento(id_argomento) {

    let formData = new FormData();

    formData.append("id_argomento", id_argomento);

    return axios.post(this.getUrlDomain("getAttivitaByArgomentoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttivitaByUser(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getAttivitaByUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttivitaPredecessoriApi(id_argomento, user) {

    let formData = new FormData();

    formData.append("id_argomento", id_argomento);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("getAttivitaPredecessoriApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertReminder(reminder, user, file) {


    let formData = new FormData();

    formData.append("username", user);
    formData.append("file", file);

    for (var key in reminder) {
      formData.append(key, reminder[key]);
    }


    return axios.post(this.getUrlDomain("TodolistInsertReminder"), formData, { 'Content-Type': 'multipart/form-data' });
  }

  async getReminder(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("TodolistEditReminder"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateReminder(reminder, id, user, remove_attach, file) {


    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);
    formData.append("remove_attach", remove_attach);
    formData.append("file", file);

    for (var key in reminder) {
      formData.append(key, reminder[key]);
    }

    return axios.post(this.getUrlDomain("TodolistUpdateReminder"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async deleteReminder(id) {


    let formData = new FormData();

    formData.append("v_id", id);
   
    return axios.post(this.getUrlDomain("TodolistDeleteReminder"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async calculateMinute(data_da, ora_da, data_a, ora_a) {


    let formData = new FormData();

    formData.append("v_data_da", data_da);
    formData.append("v_ora_da", ora_da);
    formData.append("v_data_a", data_a);
    formData.append("v_ora_a", ora_a);

    return axios.post(this.getUrlDomain("calcOreApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async calculatePeriodEnd(data_da, ora_da, dur_minuti) {


    let formData = new FormData();

    if (dur_minuti == undefined) {
      dur_minuti = "";
    }

    formData.append("v_data_da", data_da);
    formData.append("v_ora_da", ora_da);
    formData.append("v_dur_min", dur_minuti * 60);

    return axios.post(this.getUrlDomain("calcDateTimeEndApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }
  async deletePianificazione(id) {

    return axios.get(this.getUrlDomain("delPianificazioneApi") + "?id=" + id, { 'Content-Type': 'multipart/form-data' });

  }



  async getDefaultValueOld() {

    return axios.get(this.getUrlDomain("getPianificazioneDefaultValueApi"), { 'Content-Type': 'multipart/form-data' });

  }

  async getDefaultValue(username) {

    let formData = new FormData();

    formData.append("username", username);

    return axios.post(this.getUrlDomain("getPianificazioneDefaultValueApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async activateReminder(id, v_data, v_ora) {

    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("v_data", v_data);
    formData.append("v_ora", v_ora);

    return axios.post(this.getUrlDomain("TodolistActivateReminder"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deactivateReminder(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("TodolistDeactivateReminder"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateInfoOther(id, v_mail, v_gestione, user) {


    let formData = new FormData();

    formData.append("v_id", id);
    formData.append("v_mail", v_mail);
    formData.append("v_gestione", v_gestione);
    formData.append("v_user", user);

    return axios.post(this.getUrlDomain("TodolistUpdateOtherReminder"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getInfoOther(id) {


    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("TodolistGetOtherReminder"), formData, { 'Content-Type': 'multipart/form-data' });

  }




}

export default new APIReminder({
  url: "http://emtool.local/public/api/alertduedate/"
})
