<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center"
        style="max-width: 300px; min-width: 300px">

        <div class="Absolute-Center">

        </div>

    </v-container>
</template>

<script>
import {
    bus
} from "../main";
import router from ".././router";
import $ from 'jquery';
//import Login from './login.vue'

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        bus.$off("bottomIconClickEvent");
        this.$root.$children[0].showProgress = false;
    },


    mounted: async function () {

        window.$cookies.remove("a");
        window.$cookies.remove("b");
        window.$cookies.remove("token");
        window.$cookies.remove("system");
        window.$cookies.remove("token_system");
        window.$cookies.remove("username");
        window.$cookies.remove("user_id");
        window.$cookies.remove("is_ebadge");
        window.$cookies.remove("namesurname");
        window.$cookies.remove("is_pwd_changed_once");
        window.$cookies.remove("last_menu");

        var that = this;

        console.log(this.$root.$children);
        console.log("PARAMS: ", this.$route.params.id);


        setTimeout(() => {

            if (that.$route.params && that.$route.params.id.length > 0) {


                console.log("STR INPUT: ", that.$route.params.id);

                var substr = that.$route.params.id.toString().substr(1, that.$route.params.id.toString().length - 2);

                //console.log("STR OK: ", substr);

                var str_decod = atob(substr);

                //console.log("STR_DECOD: ", str_decod);


                var elements = str_decod.split("|");

                //console.log("ELEMENTS: ", elements);

                that.loginAction(elements[0], elements[1]);



            }



            // if (that.$route.params && that.$route.params.id.length > 0)
            //     that.loginAction("guest" + that.$route.params.id);

        }, 100);
        $(document).ready(function () {



        });

        bus.$on("bottomIconClickEvent", (data) => {
            console.log(data);
            switch (data) {
                case "btn_add_tms":
                    this.validate();

                    break;

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                default:
                    break;
            }
        });

        this.setupButtons();
    },
    data: () => ({
        showPicker: false,

    }),

    methods: {

        getUrlLoginApi: function () {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/utility/checkLogin.php";

        },



        getUrlDomainSimple: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "" + nome_metodo;

        },

        fixBkc() {

            var settSmartbook = JSON.parse(window.$cookies.get("settSmartbook"));
            var settings = settSmartbook[0];
            var backgroundImage = settings.backgroundImage;
            var txt_image = this.getUrlDomainSimple("public/bck_companies/" + backgroundImage);

            var rule = ` .bkc_over_image {background-image :url(` + txt_image + `)!important}`;
            $('#addedCSS').text(rule);

        },

        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "api/" + nome_metodo;

        },


        loginAction: async function (username, password) {

            //const auth = { user: this.username, password: this.password };
            // Correct username is 'foo' and password is 'bar'
            const url = this.getUrlLoginApi();

            this.success = false;
            this.error = null;

            let formData = new FormData();

            //  var pointerVue = this;
            //var pass = atob(window.$cookies.get("token_system"));
            // var pass = "password";

            // var login = username; //window.$cookies.get("username");ù

            console.log("USERNAME: ", username);
            console.log("PASSWORD: ", password);

            formData.append("user", username);
            formData.append("password", password);
            formData.append("productid", "2dolist");

            var pointerVue = this;

            var login = username;


            //formData.append("password", this.input.password);
            try {
                await this.axios
                    .post(url, formData, {
                        "content-type": "application/json"
                    })
                    .then(async (result) => {
                        var respo = result.data;
                        this.success = true;
                        // console.log("RESPO: ", respo   );

                        if (respo.Result == "OK") {

                            if (respo.product_enable == 1) // prodotto abilitato
                            {


                                setTimeout(() => {

                                    this.showSpinner = false;
                                    //this.$swal("You are logged in!!!");

                                    window.$cookies.set("a", respo.a, "9y");
                                    window.$cookies.set("b", respo.b, "9y");

                                    window.$cookies.set("token", respo.token, "9y");
                                    window.$cookies.set("system", respo.system, "9y");
                                    window.$cookies.set("token_system", respo.token_system, "9y");
                                    window.$cookies.set("username", respo.username, "9y");
                                    window.$cookies.set("user_id", respo.user_id, "9y");
                                    window.$cookies.set("namesurname", respo.namesurname, "9y");
                                    window.$cookies.set("is_ebadge", respo.is_ebadge, "9y");
                                    window.$cookies.set("is_cust_supp", respo.isCustOrSupplier.length, "9y");
                                    window.$cookies.set("is_agila", respo.is_agila, "9y");
                                    window.$cookies.set("models", respo.models, "9y");
                                    window.$cookies.set("product_permissions", respo.product_permissions, "9y");

                                    var uname = login;
                                    const args = [uname, true, ['bar', 5], {
                                        foo: 'baz'
                                    }];
                                    try {
                                        console.log("entering user");
                                        window.flutter_inappwebview.callHandler('setLoggedUser', ...args);

                                    } catch (error) {
                                        // alert(error);
                                    }

                                    this.$root.$children[0].curDipTop = respo.namesurname;

                                    this.$root.$children[0].refreshMenu();

                                    //update menu after login
                                    this.$root.$children[0].changeMenu();
                                    //Load companies array
                                    this.$root.$children[0].syncMonth();
                                    this.$root.$children[0].syncGroups();

                                    // load generic info
                                    $.ajax({
                                        type: "POST",
                                        url: pointerVue.getUrlDomain("getSettings") + '?v_user=' + respo.token,
                                        success: function (resultData) {

                                            localStorage.setItem('td_info_global', JSON.stringify(resultData));

                                            console.log("RESULT_DATA", JSON.stringify(resultData));

                                            setTimeout(() => {
                                                router.push({
                                                    path: "/pianificazione"
                                                });

                                            }, 300);

                                        }
                                    });

                                }, 1);







                            }
                            else {


                                this.$swal({
                                    icon: "error",
                                    text: "Prodotto non abilitato",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                this.showSpinner = false;


                            }


                        }
                        else {

                            this.success = false;
                            this.$swal({
                                icon: "error",
                                text: respo.ErrDetails,
                                confirmButtonText: "<img title='Confirm' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_2dolist.png' />",
                                cancelButtonText: "<img title='Cancel' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_2dolist.png' />",
                                showCancelButton: false,
                                customClass: {
                                    cancelButton: 'order-1',
                                    confirmButton: 'order-2',
                                }
                            });

                            this.showSpinner = false;


                        }












                    });
            } catch (err) {
                this.success = false;
                console.log(err.message);
                /* this.$swal({
                    icon: "error",
                    text: "Wrong credentials"
                }); */

                this.showSpinner = false;
                //  console.log(   response);

                //   this.error = err.message;
            }

        },

        loginActionOld: async function (username) {
            //const auth = { user: this.username, password: this.password };
            // Correct username is 'foo' and password is 'bar'
            //const url = "https://app.emtool.eu/api/emt/checkLogin";
            const url = "https://ws.emtool.eu/napi/api/users/checkLogin";

            this.success = false;
            this.error = null;

            let formData = new FormData();

            //  var pointerVue = this;
            //var pass = atob(window.$cookies.get("token_system"));
            var pass = "password";

            // var login = username; //window.$cookies.get("username");
            formData.append("user", username);
            formData.append("password", pass);
            var pointvue = this;


            //formData.append("password", this.input.password);
            try {
                await this.axios
                    .post(url, formData, {
                        "content-type": "application/json"
                    })
                    .then((result) => {
                        var respo = result.data;
                        this.success = true;
                        // console.log("RESPO: ", respo   );

                        setTimeout(() => {

                            pointvue.showSpinner = false;
                            //this.$swal("You are logged in!!!");

                            var logo = respo.logo;

                            var set = respo.settSmartbook;

                            pointvue.$root.$children[0].settingsUser = set[0];
                            console.log("this.$root.$children[0].settingsUse", this.$root.$children[0].settingsUser);

                            if (logo.length > 0) {
                                pointvue.$root.$children[0].logo = logo;
                                localStorage.setItem('logo', logo);

                            } else {
                                localStorage.setItem('logo', "");
                            }

                            window.$cookies.set("settSmartbook", JSON.stringify(respo.settSmartbook), "9y");

                            window.$cookies.set("a", respo.a, "9y");
                            window.$cookies.set("b", respo.b, "9y");

                            window.$cookies.set("token", respo.token, "9y");
                            window.$cookies.set("system", respo.system, "9y");
                            window.$cookies.set("token_system", respo.token_system, "9y");
                            window.$cookies.set("username", respo.username, "9y");
                            window.$cookies.set("user_id", respo.user_id, "9y");
                            window.$cookies.set("namesurname", respo.namesurname, "9y");
                            window.$cookies.set("is_ebadge", respo.is_ebadge, "9y");

                            window.$cookies.set("sector", respo.sector, "9y");

                            window.$cookies.set("settSmartbook", JSON.stringify(respo.settSmartbook), "9y");
                            pointvue.$root.$children[0].renderMenu = false;
                            pointvue.$root.$children[0].renderMenu = true;
                            this.fixBkc();

                            if (respo.a == 'Y' || respo.a == 'S' || respo.b == 'Y' || respo.b == 'S') {
                                $(".bottomBtnMenu").show();

                            } else
                                $(".bottomBtnMenu").hide();

                            pointvue.$router.push('/bookinggen');

                        }, 1);
                    });
            } catch (err) {
                this.success = false;
                console.log(err.message);
                /* this.$swal({
                    icon: "error",
                    text: "Wrong credentials"
                }); */

                this.showSpinner = false;
                //  console.log(   response);

                //   this.error = err.message;
            }
        },

        getUrlDomainBase: function (nome_metodo) {
            let result = "";
            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }
            if ($(location).attr('hostname') != "localhost") {
                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";
            }
            return result + "" + nome_metodo;
        },
        setupButtons: async function () {
            setTimeout(() => {

                // this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {

        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },

    },
};
</script>
