import axios from 'axios'

class APINotificaGruppo {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/todolist/" + nome_metodo;

  }


  async setNotificaGruppo(mail_gruppo, id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);
    formData.append("login",  mail_gruppo);


    return axios.post(this.getUrlDomain("setNotificaGruppo") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  
  async getNotificaGruppo(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("getNotificaGruppo") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async deleteNotificaGruppo(id, user)
  {


    let formData = new FormData();

    formData.append("id",  id);
    formData.append("v_user",  user);


    return axios.post(this.getUrlDomain("deleteNotificaGruppo") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }








}

export default new APINotificaGruppo({
  url: "http://emtool.local/public/api/alertduedate/"
})
