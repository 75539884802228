import axios from 'axios'

class APIPianificazione {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async getUserInfo(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("getPianificazioniUserInfoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getAttivitaByArgomento(id_argomento) {

    let formData = new FormData();

    formData.append("id_argomento", id_argomento);

    return axios.post(this.getUrlDomain("getAttivitaByArgomentoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttivitaByUser(user, owner) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_owner", owner);

    return axios.post(this.getUrlDomain("getAttivitaByUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttivitaPredecessoriApi(id_argomento, user) {

    let formData = new FormData();

    formData.append("id_argomento", id_argomento);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("getAttivitaPredecessoriApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertPianificazione(pianificazione, user) {


    let formData = new FormData();

    formData.append("username", user);

    for (var key in pianificazione) {
      formData.append(key, pianificazione[key]);
    }


    return axios.post(this.getUrlDomain("TodolistInsertPianificazione"), formData, { 'Content-Type': 'multipart/form-data' });
  }


  async insertPianificazioneNew(pianificazione, user, ids_activity) {


    let formData = new FormData();

    formData.append("username", user);
    formData.append("ids_activity", ids_activity);

    for (var key in pianificazione) {
      formData.append(key, pianificazione[key]);
    }


    return axios.post(this.getUrlDomain("TodolistInsertPianificazioneNew"), formData, { 'Content-Type': 'multipart/form-data' });
  }




  async insertPianificazionePersonale(pianificazione, user) {


    let formData = new FormData();

    formData.append("username", user);

    for (var key in pianificazione) {
      formData.append(key, pianificazione[key]);
    }


    return axios.post(this.getUrlDomain("TodolistInsertPianificazionePers"), formData, { 'Content-Type': 'multipart/form-data' });
  }



  async insertPianificazionePersonaleNew(pianificazione, user, ids_activity) {


    let formData = new FormData();

    formData.append("username", user);
    formData.append("ids_activity", ids_activity);

    for (var key in pianificazione) {
      formData.append(key, pianificazione[key]);
    }


    return axios.post(this.getUrlDomain("TodolistInsertPianificazionePersNew"), formData, { 'Content-Type': 'multipart/form-data' });
  }




  async getPianificazione(id) {

    return axios.get(this.getUrlDomain("getPianificazioneApi") + "/" + id + "?api=1", { 'Content-Type': 'multipart/form-data' });

  }



  async updatePianificazione(pianificazione, id, user) {


    let formData = new FormData();

    formData.append("username", user);

    for (var key in pianificazione) {
      formData.append(key, pianificazione[key]);
    }

    return axios.post(this.getUrlDomain("updPianificazioneApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updatePianificazioneNew(pianificazione, id, user) {


    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    for (var key in pianificazione) {
      formData.append(key, pianificazione[key]);
    }

    return axios.post(this.getUrlDomain("updatePianificazioneNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async updatePianificazionePersonale(pianificazione, id, user) {


    let formData = new FormData();

    formData.append("username", user);

    for (var key in pianificazione) {
      formData.append(key, pianificazione[key]);
    }

    return axios.post(this.getUrlDomain("updPianificazionePersApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }



  async calculateMinute(data_da, ora_da, data_a, ora_a) {


    let formData = new FormData();

    formData.append("v_data_da", data_da);
    formData.append("v_ora_da", ora_da);
    formData.append("v_data_a", data_a);
    formData.append("v_ora_a", ora_a);

    //return axios.post(this.getUrlDomain("calcMinuteApi"), formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;
    return axios.post(this.getUrlDomain("calcOreApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async calculatePeriodEnd(data_da, ora_da, dur_minuti) {


    let formData = new FormData();

    if (dur_minuti == undefined) {
      dur_minuti = "";
    }

    formData.append("v_data_da", data_da);
    formData.append("v_ora_da", ora_da);
    formData.append("v_dur_min", dur_minuti * 60);

    return axios.post(this.getUrlDomain("calcDateTimeEndApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }
  async deletePianificazione(id) {

    return axios.get(this.getUrlDomain("delPianificazioneApi") + "?id=" + id, { 'Content-Type': 'multipart/form-data' });

  }



  async getDefaultValueOld() {

    return axios.get(this.getUrlDomain("getPianificazioneDefaultValueApi"), { 'Content-Type': 'multipart/form-data' });

  }

  async getDefaultValue(username) {

    let formData = new FormData();

    formData.append("username", username);

    return axios.post(this.getUrlDomain("getPianificazioneDefaultValueApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APIPianificazione({
  url: "http://emtool.local/public/api/alertduedate/"
})
