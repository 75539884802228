<template>
    <div id="mainContainer">

        <v-dialog id='dialogMsg' content-class="dialogMsg" v-model="dialogMsg" width="100vw" style="min-width:100vw"
            max-width="500px">

            <v-card height="100vh" width="100vw">
                <v-card-title>

                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>

                            <v-col cols="12" sm="12" md="12">
                                <span class="text-h5">{{ "Richiesta" }} </span>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field readonly v-model="currItem.data_inizio"
                                    label="Data inizio"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field readonly v-model="currItem.data_fine" label="Data fine"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field readonly v-model="currItem.ora_inizio" label="Ora inizio"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field readonly v-model="currItem.ora_fine" label="Ora fine"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field readonly v-model="currItem.stato" label="Stato"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-textarea readonly outlined name="input-7-4" label="Note"
                                    v-model="currItem.note"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <!--    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="blue darken-1" text @click="close">
                            Close
                        </v-btn>
                    </v-card-actions> -->
            </v-card>

            <!--         <v-card v-else height="100vh" width="100vw">
            <v-card-title>

            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <span class="text-h5">{{ "Accettazione" }}</span>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">

                            <v-checkbox v-model="defaultItem.accepted" @click="acceptRead()">
                                <template v-slot:label>
                                    <div>
                                        Dichiaro di accettare la lettura del messaggio

                                    </div>
                                </template>
                            </v-checkbox>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card> -->

        </v-dialog>

        <div class="tabs">

            <div class="divicontab">
                <v-img width="26" height="26" class=" " contain src="@/assets/ferie.png"
                    transition="scale-transition" />
            </div>

            <template>

                <v-tabs centered v-model="tab">
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab v-for="item in items" :key="item" @click="setupNavBottom">
                        {{ item }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">

                    <v-tab-item key="Request">

                        <div id="holiday">

                            <div class="totals" style="display:none;">
                                <div class="grouptotal">
                                    <span class="titletotals">
                                        <v-icon>mdi-account-hard-hat</v-icon>
                                        <span> {{ totPresenzaMese }} H</span>
                                    </span>
                                    <span class="detailtotals">{{ totPresenzaMese }} </span>
                                </div>
                                <div class="grouptotal">
                                    <span class="titletotals">
                                        <v-icon>mdi-account-minus</v-icon>
                                        <span> {{ totAssenzaMese }} H</span>
                                    </span>
                                    <span class="detailtotals">{{ totAssenzaMese }} </span>
                                </div>

                                <div class="grouptotal">
                                    <span class="titletotals">
                                        <v-icon>mdi-account-plus</v-icon>
                                        <span> {{ totStraordinariMese }} H</span>
                                    </span>
                                    <span class="detailtotals">{{ totStraordinariMese }}</span>
                                </div>
                            </div>

                            <v-layout d-flex align-center justify-center style="min-height: auto;margin-top:4px;">
                                <v-card-actions>
                                    <div class="contcalendar" id="contCalendarHoliday">

                                        <datepicker is-range :first-day-of-week="2" is-expanded color="yellow" is-light
                                            ref="defCalendar" :attributes="attributesCal" @dayclick="onDayClick"
                                            format="DD-MM-YYYY" v-model="range" />
                                        <!--     <datepicker ref="defCalendar" @dayclick="onDayClick" @update:from-page="pageChange" format="DD-MM-YYYY" v-model="date" is-expanded :first-day-of-week="2" locale="it" color="yellow" is-light :attributes="attributes" /> -->

                                    </div>
                                </v-card-actions>
                            </v-layout>
                            <!--  <v-divider></v-divider> -->

                            <rise-loader v-if="showSpinner" :size="'20px'" :color="'#ffd212'" />
                            <!--Detail -->

                            <div class="mx-auto nocolor contQuanties contVoices" color="transparent"
                                style="transform: translate(0px, -50px);">

                                <v-list-item one-line class="contDayFormatted" style="min-height:20px;">
                                    <v-list-item-content>

                                        <v-list-item-subtitle class="dayFormatted"
                                            style="justify: center; text-align: center;font-size: 9px;"
                                            justify="center">{{ dayFormatted }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list class="transparent" style="transform: translateX(-12px);">

                                    <!--             <v-list-item v-for="item in forecast" :key="item.day">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ item.day }}</v-list-item-title>

                <v-list-item-subtitle class="text-right" style="min-width: 160px">

                    <v-select v-if="item.day == 'Causale'" @change="updateSumDay" class="selectHour" :disabled="false" :items="causali" item-value="idtypeactivity" item-text="description" label="" dense v-model="item.quantity"></v-select>

                    <div class="conSelect" v-if="item.day != 'Causale' && item.day != 'Totale'">

                        <v-text-field readonly type="text" :min="0" :max="10" v-if="item.day != 'Causale' && item.day != 'Totale'" class="selectHour" @change="updateSumDay" :disabled="!day_editable" :items="itemsQuantity" label="" dense :value="item.quantity + ' H'" :ref="'combo'+item.day"></v-text-field>

                        <div class="addbuttons">
                            <div :data-id="item.day" v-on:click="sumHours" class="plus" :disabled="!day_editable">+</div>
                            <div :data-id="item.day" v-on:click="sumHours" class="minus" :disabled="!day_editable">-</div>
                        </div>

                    </div>

                    <v-text-field v-if="item.day == 'Totale'" class="selectHour totaleCombo" :disabled="true" label="" dense :value="item.quantity + ' H'"></v-text-field>

                </v-list-item-subtitle>
            </v-list-item> -->

                                    <v-list-item>
                                        <!--   <v-list-item-icon align="center">
                                        <v-icon>mdi-expand-all</v-icon>
                                    </v-list-item-icon> -->
                                        <v-list-item-title align="start" justify="start"> Causale </v-list-item-title>

                                        <v-list-item-subtitle class="text-right" style="min-width: 160px">
                                            <v-select v-model="causale" class="selectHour" :disabled="false"
                                                :items="causali" item-value="idtype_holidays_permits"
                                                item-text="description" label="" dense></v-select>

                                        </v-list-item-subtitle>
                                    </v-list-item>

                                    <!--  <v-list-item>
                <v-list-item-icon align="center">
                    <v-icon>mdi-expand-all</v-icon>
                </v-list-item-icon>
                <v-list-item-title align="start" justify="start"> Ore </v-list-item-title>

                <v-list-item-subtitle class="text-right" style="min-width: 160px">

                    <div class="conSelect">

                        <v-text-field readonly type="text" :min="0" :max="10" class="selectHour" @change="updateSumDay" :disabled="!day_editable" :items="itemsQuantity" label="" dense></v-text-field>

                        <div class="addbuttons">
                            <div data-id="1" v-on:click="sumHours" class="plus" :disabled="false">+</div>
                            <div data-id="2" v-on:click="sumHours" class="minus" :disabled="false">-</div>
                        </div>

                    </div>

                </v-list-item-subtitle>
            </v-list-item> -->

                                    <v-list-item id='startHour'
                                        v-if="/*(causale == '3'||causale == '2') &&*/ singleMode">
                                        <!-- <v-list-item-icon align="center">
                                        <v-icon>mdi-expand-all</v-icon>
                                    </v-list-item-icon> -->
                                        <v-list-item-title align="start" justify="start"> Orario inizio
                                        </v-list-item-title>

                                        <v-list-item-subtitle class="text-right" style="min-width: 160px">

                                            <div class="conSelect">

                                                <v-text-field v-model="timeStart" readonly type="text" :min="0"
                                                    :max="10" class="selectHour" @change="updateSumDay"
                                                    :disabled="false" label="" dense value=""></v-text-field>

                                                <div class="addbuttons">
                                                    <div data-id="startHour" v-on:click="sumHours" class="plus"
                                                        :disabled="false">+</div>
                                                    <div data-id="startHour" v-on:click="sumHours" class="minus"
                                                        :disabled="false">-</div>
                                                </div>

                                            </div>

                                            <!-- <div class="conSelect">
                                            <v-dialog ref="dialogStart" v-model="modalStartHour" :return-value.sync="timeStart" persistent width="290px">
                                                <template v-slot:activator="{ on, attrsTwo }">
                                                    <v-text-field v-model="timeStart" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrsTwo" v-on="on"></v-text-field>
                                                </template>
                                                <v-time-picker @click:hour="closePicker" v-if="modalStartHour" v-model="timeStart" full-width>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="modalStartHour = false">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn text color="primary" @click="$refs.dialogStart.save(timeStart)">
                                                        OK
                                                    </v-btn>
                                                </v-time-picker>
                                            </v-dialog>

                                        </div> -->

                                        </v-list-item-subtitle>
                                    </v-list-item>

                                    <v-list-item id='endHour' v-if="/*(causale == '3'||causale == '2') &&*/ singleMode">
                                        <!--  <v-list-item-icon align="center">
                                        <v-icon>mdi-expand-all</v-icon>
                                    </v-list-item-icon> -->
                                        <v-list-item-title align="start" justify="start"> Orario fine
                                        </v-list-item-title>

                                        <v-list-item-subtitle class="text-right" style="min-width: 160px">

                                            <div class="conSelect">

                                                <v-text-field v-model="timeEnd" readonly type="text" :min="0" :max="10"
                                                    class="selectHour" @change="updateSumDay" :disabled="false" label=""
                                                    dense value=""></v-text-field>

                                                <div class="addbuttons">
                                                    <div data-id="endHour" v-on:click="sumHours" class="plus"
                                                        :disabled="false">+</div>
                                                    <div data-id="endHour" v-on:click="sumHours" class="minus"
                                                        :disabled="false">-</div>
                                                </div>

                                            </div>

                                            <!--       <div class="conSelect">
                                            <v-dialog ref="dialogEnd" v-model="modalEndHour" :return-value.sync="timeEnd" persistent width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="timeEnd" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
                                                </template>
                                                <v-time-picker @click:hour="closePickerTwo" v-if="modalEndHour" v-model="timeEnd" full-width>
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="modalEndHour = false">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn text color="primary" @click="$refs.dialogEnd.save(timeEnd)">
                                                        OK
                                                    </v-btn>
                                                </v-time-picker>
                                            </v-dialog>
                                        </div> -->

                                        </v-list-item-subtitle>
                                    </v-list-item>

                                    <v-list-item>
                                        <!-- <v-list-item-icon align="center">
                                        <v-icon>mdi-expand-all</v-icon>
                                    </v-list-item-icon> -->
                                        <v-list-item-title align="start" justify="start"> Note </v-list-item-title>

                                        <v-list-item-subtitle class="text-right" style="min-width: 160px">
                                            <v-textarea id="notes" rows="2" class="selectHour totaleCombo"
                                                :disabled="false" label="" dense></v-textarea>
                                        </v-list-item-subtitle>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-title align="start" justify="start"> Selezione </v-list-item-title>
                                        <v-list-item-subtitle class="text-right"
                                            style="min-width: 200px;font-size:9px;">
                                            {{ rangeDetail }}
                                        </v-list-item-subtitle>
                                    </v-list-item>

                                </v-list>

                                <!--  <v-divider></v-divider> -->

                                <!--     <v-card-actions>
      <v-btn text>
        Full Report
      </v-btn>
    </v-card-actions> -->
                            </div>

                            <v-row justify="center" class="rowStatus" style="align-items:center;">
                                <span>STATO</span>
                                <span class="spanStatus">
                                    <v-tooltip v-if="curStatus == 'Open'" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon color="black" size="30" v-on="on">mdi-account-lock-open</v-icon>
                                        </template>
                                        <span>{{ curStatus }}</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="curStatus == 'Close'" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon size="30" color="black" v-on="on">mdi-account-lock</v-icon>
                                        </template>
                                        <span>{{ curStatus }}</span>
                                    </v-tooltip>

                                    <v-tooltip v-if="curStatus == 'For approval'" bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon size="30" color="black" v-on="on">mdi-account-arrow-right</v-icon>
                                        </template>
                                        <span>{{ curStatus }}</span>
                                    </v-tooltip>

                                    {{ "" }}
                                </span>
                            </v-row>

                            <!--Detail End -->

                            <!--Admin dialog START-->
                            <!--  <div ref="ok" id="ok"></div> -->
                            <template>
                                <v-row justify="center">
                                    <v-dialog v-model="admindialog" persistent max-width="600px">
                                        <template>
                                            <!--    <v-btn
            color="primary"

            v-bind="attrs"
            v-on="on"
          >
            Scegli dipendente
          </v-btn> -->
                                        </template>
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5">Seleziona dipendente</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-container>
                                                    <v-row>

                                                        <v-col cols="12" sm="6">
                                                            <v-select v-model="currAzienda"
                                                                v-on:change="syncArrayDipendenti" :items="aziende"
                                                                label="Azienda*" required></v-select>
                                                        </v-col>
                                                        <v-col cols="12" sm="6">
                                                            <v-select :items="dipendenti" label="Risorsa"
                                                                v-model="currDipendente" item-value="v_res_id"
                                                                item-text="fld_name" @change="changeDip"></v-select>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                                <small></small>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text
                                                    @click="admindialog = false; syncMonth();">
                                                    Close
                                                </v-btn>
                                                <v-btn color="blue darken-1" text
                                                    @click="admindialog = false; syncMonth();">
                                                    Save
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-row>
                            </template>

                            <!--Admin dialog END -->

                            <!---OK START-->
                            <!--
<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="purple"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open In
        </v-btn>
      </template>
      <v-list style="max-height: 309px;overflow: auto;">
        <v-subheader>Open in</v-subheader>
        <v-list-item
          v-for="tile in tiles"
          :key="tile.title"
          @click="sheet = false"
        >
          <v-list-item-avatar>
            <v-avatar
              size="22px"
              tile
            >
              <img
                :src="`https://cdn.vuetifyjs.com/images/bottom-sheets/${tile.img}`"
                :alt="tile.title"
              >
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title>{{ tile.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template> -->

                            <!---OK END-->

                            <template>
                                <div class="text-center">
                                    <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                                        <v-sheet class="text-center" height="100vh">
                                            <div class="headerSheetAziende" fixed style="position:fixed;">
                                                Aziende
                                                <v-btn class='closeSheet' icon @click="sheetAziende = !sheetAziende">
                                                    <v-icon>mdi-close-circle-outline</v-icon>
                                                </v-btn>

                                                <v-toolbar>
                                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                                        v-model="search"></v-text-field>

                                                </v-toolbar>

                                            </div>
                                            <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                        close
                    </v-btn> -->

                                            <v-divider></v-divider>

                                            <div class="spacer" style="min-height:72px;"></div>

                                            <v-flex xs12 sm12>
                                                <v-card>

                                                    <v-list-item class="text-left" v-for="(tile, key) in filteredAziende"
                                                        :key="key" @click="manageClickAziende(tile)">
                                                        <v-icon aria-hidden="false">mdi-domain</v-icon>
                                                        <v-list-item-title class='itemAzienda'>{{
                                                            tile
                                                        }}</v-list-item-title>
                                                        <v-icon class='iconPlusList'
                                                            aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                                    </v-list-item>
                                                </v-card>
                                            </v-flex>
                                        </v-sheet>
                                    </v-bottom-sheet>
                                </div>
                            </template>

                            <template>
                                <div class="text-center">
                                    <v-bottom-sheet v-model="sheetDipendenti">
                                        <v-sheet class="text-center" height="100vh">

                                            <div class="headerSheetAziende" fixed style="position:fixed;">

                                                Dipendenti
                                                <v-btn class='closeSheet' icon
                                                    @click="sheetDipendenti = !sheetDipendenti">
                                                    <v-icon>mdi-close-circle-outline</v-icon>
                                                </v-btn>

                                                <v-toolbar>
                                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                                        v-model="searchDipendenti"></v-text-field>

                                                </v-toolbar>
                                            </div>

                                            <v-divider></v-divider>

                                            <div class="spacer" style="min-height:72px;"></div>

                                            <v-flex xs12 sm12>
                                                <v-card>
                                                    <v-list-item class="text-left"
                                                        v-for="(objDip, key) in filteredDipendenti" :key="key"
                                                        @click="manageClickDipendenti(objDip)">
                                                        <v-icon aria-hidden="false">mdi-account</v-icon>
                                                        <v-list-item-title class='itemAzienda'>{{
                                                            objDip.fld_name
                                                        }}</v-list-item-title>
                                                        <v-icon class='iconPlusList'
                                                            aria-hidden="false">mdi-plus-circle-outline</v-icon>
                                                    </v-list-item>
                                                </v-card>
                                            </v-flex>
                                        </v-sheet>
                                    </v-bottom-sheet>
                                </div>
                            </template>

                            <template>
                                <div class="text-left">
                                    <v-bottom-sheet v-model="sheetStatus">
                                        <v-sheet class="text-center" height="385px" style="padding-bottom:120px;">
                                            <v-btn class='closeStatusSheet' icon @click="sheetStatus = !sheetStatus">
                                                <v-icon>mdi-close-circle-outline</v-icon>
                                            </v-btn>
                                            <div class="py-3">
                                                Seleziona stato
                                            </div>
                                            <v-list-item v-for="tile in allStatus" :key="tile.title"
                                                @click="changeStatus(tile.title)">
                                                <v-list-item-avatar>
                                                    <v-avatar size="32px" tile>
                                                        <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-title style="justify-content: center;text-align: left;">{{
                                                    tile.title
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-sheet>
                                    </v-bottom-sheet>
                                </div>
                            </template>

                            <template>
                                <div class="text-left">
                                    <v-bottom-sheet v-model="sheetChangeStatus">
                                        <v-sheet class="text-center" height="340px" style="padding-bottom:120px;">
                                            <v-btn class='closeStatusSheet' icon
                                                @click="sheetChangeStatus = !sheetChangeStatus">
                                                <v-icon>mdi-close-circle-outline</v-icon>
                                            </v-btn>
                                            <div class="py-3">
                                                Seleziona stato
                                            </div>
                                            <v-list-item
                                                v-show="tile.title != 'Open' && (currItem.stato == 'Open' && tile.title != 'Open' || currItem.stato == 'Refused' && tile.title != 'Refused' || currItem.stato == 'to Approve' && tile.title != 'to Approve' || currItem.stato == 'Approved' && tile.title != 'Approved')"
                                                v-for="tile in allStatus" :key="tile.title"
                                                @click="changeStatusAdmin(tile.title)">
                                                <v-list-item-avatar>
                                                    <v-avatar size="32px" tile>
                                                        <v-icon :color="tile.color">{{ tile.icon }}</v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-title style="justify-content: center;text-align: left;">{{
                                                    tile.title
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-sheet>
                                    </v-bottom-sheet>
                                </div>
                            </template>

                        </div>
                    </v-tab-item>

                    <v-tab-item key="History">
                        <v-card flat>

                            <v-container fluid class="text-center  "
                                style="max-width: 600px; min-width: 320px;padding-bottom:100px;">
                                <v-data-table mobile-breakpoint="0" :headers="headers" :items="records"
                                    :options.sync="options" :server-items-length="totalRecords" :loading="loading"
                                    class="elevation-1" :single-select="singleSelect" @click:row="handleClick"
                                    hide-default-footer :page.sync="page" :items-per-page="options.itemsPerPage">

                                    <template v-slot:top>
                                        <v-text-field v-model="searchHoliday" append-icon="mdi-magnify" label="Search"
                                            class="mx-4" @keydown.enter="cercaFerie"></v-text-field>
                                    </template>

                                    <template v-slot:item.read="{ item }">
                                        <div :class="'imgread' + item.read">
                                            <v-img :alt="item.name" width="22" height="22" class=" " contain
                                                src="@/assets/ferie.png" transition="scale-transition"
                                                @click="openMsgDialog(item)" />
                                        </div>
                                    </template>
                                    <template v-slot:item.created_at="{ item }">
                                        <div :class="'msgDesc ' + 'msgread' + item.read" style=" ">
                                            <span class='hour'>{{ item.data_inizio }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item.title="{ item }">
                                        <div :class="'msgDesc ' + 'msgread' + item.read" style=" ">
                                            {{ item.data_fine }}
                                        </div>
                                    </template>
                                    <template v-slot:item.dest="{ item }">
                                        <div :class="'msgDest '" style=" ">
                                            <div v-if="currAzienda != '' || 1 != '0'" class='ris'>{{ item.risorsa }}</div>
                                            {{ item.stato }}
                                        </div>
                                    </template>

                                    <template v-slot:footer.page-text>

                                    </template>

                                </v-data-table>

                                <div class="text-center pt-2">
                                    <v-pagination v-model="page" total-visible="0"
                                        :length="totalRecords"></v-pagination>

                                </div>

                            </v-container>
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>

            </template>

        </div>

    </div>
</template>

<script>
var env = process.env.NODE_ENV;
if (env != "development") console.log = function () { };

import $ from 'jquery';
import _ from 'lodash';

/*   import '@ionic/core/css/ionic.bundle.css';
 */

/* import Datepicker from "vuejs-datepicker";
import {en, de, es , it} from 'vuejs-datepicker/dist/locale' */
/* import VCalendar from "v-calendar"; */
//import Vue from "vue";
// Use v-calendar & v-date-picker components
/* Vue.use(VCalendar, {

}); */
//import Calendar from 'v-calendar/lib/components/calendar.umd'
import datepicker from "v-calendar/lib/components/date-picker.umd";

//import datepicker from 'v-calendar/lib/components/calendar.umd'

import RiseLoader from "vue-spinner/src/RiseLoader.vue";
import router from ".././router";
import {
    bus
} from "../main";

export default {

    watch: {

        searchHoliday(newVal) {
            console.log(newVal);
            this.getDataFromApi();
            if (newVal === '') {
                this.myValue = null;
            }
        }

        ,
        options: {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
        date: {
            handler: function () {
                // console.log(this.date)
            },
            deep: true,
        },
    },

    updated() {

    },

    mounted: async function () {

        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");
        if (a == 'Y' || a == 'S') {

            this.su = true;

        }

        if (b == 'Y' || b == 'S') {

            this.aa = true;
        }

        this.setupNavBottom();
        this.dipendenti = this.$root.$children[0].dipendenti;
        this.aziende = this.$root.$children[0].aziende;

        this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        this.loadCausali();

        this.setupCalendar();

        /*  let dipReq = window.location.href.includes("?dip=1");

         if (dipReq) {
             this.sheetAziende = true;
         } */

        // let urlParams = new URLSearchParams(window.location.search);

        // console.log( "hasDip" , window.location.href ) ; // true

        /* setTimeout(() => {

         $(".is-today").focus().trigger("click");
         $(".is-today").focus().trigger("click");

        }, 4000); */

        bus.$on("menuClickEvent", (data) => {

            switch (data) {

                case "loadAziende":

                    break;

                case "showaziende":

                    this.sheetAziende = true;
                    break;
            }
        });

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_send_approvation":
                    this.sendInApprovation();
                    break;
                case "btn_delete":
                    this.delMsg();
                    break;
                case "btn_changestatusadmin":
                    this.sheetChangeStatus = true;
                    break;
                case "btn_changestatus":
                    this.sheetStatus = true;
                    break;
                case "btn_back":
                    this.setupButtons();
                    this.sheetAziende = false;
                    this.sheetGruppi = false;
                    this.sheetEmpty = false;
                    this.sheetDipendenti = false;
                    this.dialogMsgSend = false;
                    this.dialogMsg = false;

                    //  this.closeSendMsg();

                    break;

                case "btn_aziende":

                    this.dipendenti = this.$root.$children[0].dipendenti;
                    this.aziende = this.$root.$children[0].aziende;

                    this.globalArrAziende = this.$root.$children[0].globalArrAziende;

                    //this.admindialog = true ;
                    this.$root.$children[0].showProgress = true;

                    setTimeout(() => {
                        this.sheetAziende = true;
                        this.$root.$children[0].showProgress = false;

                    }, 300);
                    break;
                case "btn_status":
                    this.sheetStatus = true;

                    break;

                case "btn_refr_tms":
                    this.resetDay();
                    this.syncMonth();
                    break;
                case "btn_send_req":

                    var $mesi = [

                        "gennaio",
                        "febbraio",
                        "marzo",
                        "aprile",
                        "maggio",
                        "giugno",
                        "luglio",
                        "agosto",
                        "settembre",
                        "ottobre",
                        "novembre",
                        "dicembre",
                    ];
                    var text = $mesi[this.curMonth] + " - " + this.curYear;

                    console.log("this.curStatus", this.curStatus)

                    console.log("this.range", this.range);

                    var start = this.range.start;
                    var end = this.range.end;

                    if (start == undefined) {
                        this.$swal({
                            icon: "error",
                            text: "Selezionare una data ",
                        });

                        return;
                    }

                    var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

                    var endLiteral = end.getDate() + " " + $mesi[end.getMonth()] + " " + end.getFullYear();

                    if (startLiteral != endLiteral)
                        text = startLiteral + " - " + endLiteral;
                    else
                        text = startLiteral;

                    var statonum = 1;
                    // (1 - Aperto 2 - In Approvazione 3 - Chiuso) (obbligatorio)

                    console.log("Causale :", this.timeEnd, this.timeStart);

                    if (this.causale == "") {
                        this.$swal({
                            icon: "error",
                            text: "Selezionare una causale",
                        });

                        return false;
                    }

                    if (this.curStatus == 'Open') statonum = 1;
                    if (this.curStatus == 'Close') statonum = 3;
                    if (this.curStatus == 'For approval') statonum = 2;

                    if (this.aa == 'Y' || this.su == 'Y') {

                        console.log(statonum);

                        this.sheetStatus = true;

                    } else {

                        this.$swal
                            .fire({
                                title: "Sei sicuro?",
                                html: "Vuoi inserire la richiesta <h4>" + text + " ?</h4> ",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes!",
                            })
                            .then((result) => {
                                if (result.value == true) {
                                    //console.log (true);

                                    //HolidayRequest.holidaysRequest(username, password, action, startDate, endDate, timeStart, timeEnd, hours, notes);
                                    var start = this.range.start;
                                    var monthStart = (start.getMonth() + 1).toString().padStart(2, "0");
                                    var dayStart = (start.getDate()).toString().padStart(2, "0");

                                    var end = this.range.end;
                                    var monthEnd = (end.getMonth() + 1).toString().padStart(2, "0");
                                    var dayEnd = (end.getDate()).toString().padStart(2, "0");

                                    var startDate = start.getFullYear() + "-" + monthStart + "-" + dayStart;

                                    var endDate = end.getFullYear() + "-" + monthEnd + "-" + dayEnd;

                                    //this.timeStart
                                    //this.timeEnd

                                    var notes = $("#notes").val();

                                    console.log(startDate, endDate, notes);

                                    var token = window.$cookies.get("token");

                                    /*   var username = window.$cookies.get("username");
                                      var token_system = window.$cookies.get("token_system"); */
                                    //var API_URL = "https://services.ebadge.it/public/api/sendReqHoliday";
                                    var API_URL = "https://app.emtool.eu/api/insertHolidaysPermits";

                                    if (this.currDipendenteObj.resourceid !== undefined) {
                                        console.log("currDipendenteObj", this.currDipendenteObj);
                                        /*   username = atob(this.currDipendenteObj.v_user);
                                          token_system = this.currDipendenteObj.way; */
                                        token = this.currDipendenteObj.v_user;

                                    }

                                    var that = this;
                                    that.showSpinner = true;
                                    console.log("this.causali", this.causali);
                                    // alert (  this.causale );
                                    var parType = "";
                                    if (this.causale == 1) parType = "ferie";
                                    if (this.causale == 2) parType = "permesso retribuito";
                                    if (this.causale == 3) parType = "permesso non retribuito";

                                    /* eslint-disable no-unreachable */
                                    /*    return; */
                                    $.ajax({
                                        type: "POST",
                                        url: API_URL,
                                        data: {
                                            "v_user": token,

                                            'data_inizio': startDate,
                                            'data_fine': endDate,
                                            'tipo_richiesta': parType,

                                            'ora_inizio': this.timeStart,
                                            'ora_fine': this.timeEnd,

                                            'request_type': this.causale,
                                            'note': notes

                                        },
                                        success: function (resultData) {
                                            that.showSpinner = false;
                                            console.log("resultData", resultData, that);

                                            that.$swal({
                                                icon: "success",
                                                text: "La richiesta è stata inviata correttamente",
                                                showConfirmButton: false,
                                                timer: 2000
                                            });

                                            that.getDataFromApi();

                                            // var causali = resultData.Result;
                                        },
                                        error: function (errorData) {
                                            console.log(errorData);
                                            var msg = errorData.responseJSON.Error;

                                            that.$swal({
                                                icon: "error",
                                                text: msg,
                                                showConfirmButton: false,
                                                timer: 3000
                                            });
                                            that.showSpinner = false;

                                        }
                                    });

                                    /*  var response = this.updateStatusMonth(2).then((res) => {
                                         if (res.status == 200) {
                                             // this.$swal("Il timesheet è stato inviato correttamente");
                                             this.$swal({
                                                 icon: "success",
                                                 text: "Il timesheet è stato inviato correttamente",
                                             });
                                             this.syncMonth();
                                         } else {
                                             this.$swal({
                                                 icon: "error",
                                                 text: "Il timesheet non è stato inviato correttamente",
                                             });
                                         }
                                     }); */

                                    // console.log("Response", response);
                                }
                            });
                    }

                    break;

                case "btn_exit":
                    //alert("btn_add_x");
                    router.push({
                        path: "/dash"
                    });
                    break;

                default:
                    break;
            }
        });
    },

    mountedOLD() {
        //console.log ( "CAlendar" ,this.$refs.defCalendar );
        //var pointerTHis = this ;

        $("body").on("click", ".v-menu__content", function () {

            //$(this).hide();
            //  $(".selectHour").trigger("blur");
            //      window.pointerThis = pointerTHis ;

            //this.$refs.comboOrdinari.click();
            //      console.log ( "all refs" , window.refs );
            //$(".v-select--is-menu-active").find(".v-icon").trigger("click");

            /* $(".v-select--is-menu-active").find(".v-select__slot").trigger("blur");

            setTimeout(() => {
                $("body").trigger("click");

            }, 100); */

            //$(".v-select__slot").trigger("click");
            //window.refs.comboOrdinari.hide();

            //   this.$refs.combo.blur();

        });
        //$(".menu__content").hide();
        /*   document.getElementsByClassName("v-menu__content")[0].addEventListener("click",() => {

              alert(1);
            }); */

        /*
              var template = `<ion-list>
                    <ion-item>
                      <ion-label>Gender</ion-label>
                      <ion-select :v-model="sheet" placeholder="Select One" interface="action-sheet">
                        <ion-select-option  value="f">Female</ion-select-option>
                        <ion-select-option value="m">Male</ion-select-option>
                        <ion-select-option value="m">Male</ion-select-option>
                        <ion-select-option value="m">Male</ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-list>`;
                  //document.getElementsByClassName("ionicdiv").appendChild(template);

                  setTimeout(() => {
                      //const  div = document.getElementById('ok');

                      const div = this.$refs.ok ;
                      console.log ("o",div);

                    //div.insertAdjacentHTML('afterbegin' , template);

                  }, 2000); */

        //console.log ( this.$root.showProva ) ;
        //   console.log ("here we go" , window.App ) ;
        //   this.syncMonth();
        /*         var datesOk =   [
                        new Date( '2021-12-13'),
                        new Date( '2021-12-14'),
                ];

            this.todos = [
                {
                    description: 'Festivity',
                    isComplete: false,
                    dates: datesOk,
                    color: 'red',
                },
                ]; */
        //    this.dayFormatted = this.date ;
        /*   setTimeout(() => {
          this.$refs.defCalendar.$emit("dayclick");

          //document.getElementsByClassName("vc-highlights")[0].click();
          //this.$refs.defCalendar.$emit("dayclick")  ;
        }, 1200); */
        //   console.log ( this.date );
    },
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];
        this.$root.$children[0].buttonsTop = [];

        //to manage if admin
        var namesurname = window.$cookies.get("namesurname");

        this.$root.$children[0].curDipTop = namesurname;
        this.$root.$children[0].curDipOreTop = "";
        this.$root.$children[0].curDipOreTopLabel = "";

        bus.$off("bottomIconClickEvent");
        bus.$off("menuClickEvent");
    },

    methods: {

        sendInApprovation: function () {

            var that = this;
            var token = window.$cookies.get("token");

            var API_URL = "https://app.emtool.eu/sendRequestHolidaysPermits";

            var id_richiesta = this.currItem.id_richiesta;

            that.$root.$children[0].showProgress = true;

            $.ajax({
                type: "GET",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': id_richiesta,

                },
                success: function (resultData) {
                    that.$root.$children[0].showProgress = false;
                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();
                        that.getDataFromApi();
                    }, 200);

                    // var causali = resultData.Result;
                },
                error: function (errorData) {
                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    // that.showSpinner = false;
                    //   that.$root.$children[0].showProgress = true;
                    that.$root.$children[0].showProgress = false;

                }
            });

        },
        setTime: function () {
            this.timeStart = "";
            this.timeEnd = "";

        },
        cercaFerie: function () {
            this.getDataFromApi();
        },
        delMsg: function async() {
            var token = window.$cookies.get("token");
            var that = this;

            this.$swal
                .fire({
                    title: "Sei sicuro?",
                    html: "Vuoi eliminare questa richiesta ferie ? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes!",
                })
                .then(async function (result) {

                    if (result.value == true) {

                        //start request
                        var response;
                        console.log("this.currItem", that.currItem);
                        try {

                            //  that.showSpinner = true;
                            that.$root.$children[0].showProgress = true;

                            response = await that.$api.deleteFerie(
                                /* this.currItem.id_richiesta */
                                that.currItem.id_richiesta, token

                            ).then((res) => {

                                that.$root.$children[0].showProgress = false;
                                console.log("res from delete", res);

                                that.$swal({
                                    icon: "success",
                                    text: "Your request has been removed correctly",
                                    showConfirmButton: false,
                                    timer: 3000
                                });

                                setTimeout(() => {

                                    that.dialogMsg = false;
                                    that.setupButtons();
                                    that.getDataFromApi();
                                }, 200);

                            }).catch(err => {
                                that.$root.$children[0].showProgress = false;
                                console.log(err);
                                var msg = err.response.data.Error;

                                that.$swal({
                                    icon: "error",
                                    text: msg,
                                    showConfirmButton: false,
                                    timer: 8000
                                });
                                console.log("ERrori", "Non è stato possibile eliminare questa richiesta");
                                console.log("response", response);

                            }

                            );

                            /* setTimeout(() => {

                                this.dialogMsg = false;
                                this.setupButtons();
                                this.getDataFromApi();
                            }, 2000); */

                        } catch (error) {
                            that.$root.$children[0].showProgress = false;
                            console.log(error);

                        }
                        console.log(response);

                        //end request

                    }
                });

            /*             var response;
                        console.log("this.currItem", this.currItem);
                        try {
                            response = this.$api.deleteFerie(
                                this.currItem.message_id

                            );

                            this.$swal({
                                icon: "success",
                                text: "Your  has been removed correctly",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                this.dialogMsg = false;
                                this.setupButtons();
                                this.getDataFromApi();
                            }, 2000);

                        } catch (error) {
                            this.showSpinner = false;
                            console.log(error);
                        }

                        console.log(response); */

        },

        setupButtons: async function () {
            this.setupNavBottom();
            /*  setTimeout(() => {
                 var pulsantis = [{
                     text: "Dashboard",
                     icon: "mdi-home-circle",
                     link: "/dash",
                     id: "btn_exit",
                     disabled: false,
                 }, ];
                 var a = window.$cookies.get("a");
                 if (a == 'Y' || a == 'S') {
                     pulsantis.push({
                         text: "",
                         icon: "mdi-message-plus",
                         link: "/dash",
                         id: "btn_aziende",
                         disabled: false,
                     }, );
                 }
                 this.$root.$children[0].bnavbuttons = pulsantis;
             }, 100); */
        },

        addBackDeleteBtn: function () {

            var pulsantis = [];

            pulsantis.push({
                text: "",
                icon: "mdi-home-circle",
                link: "/dash",
                id: "btn_exit",
                disabled: false,
            });

            pulsantis.push({
                text: "",
                icon: "mdi-arrow-left",
                link: "/back",
                id: "btn_back",
                disabled: false,
                image: "https://app.emtool.eu/public/_lib/img/back-2dolist.png",
                width: 30,
                title: "Back"


            },);

            if (this.currItem.attachment) {
                pulsantis.push({
                    text: "",
                    icon: "mdi-download-outline",
                    link: "/download",
                    id: "btn_download",
                    disabled: false,
                },);
            }

            setTimeout(() => {
                $("#btn_download").attr("href", "https://app.emtool.eu/public/documents/" + this.currItem.attachment);

            }, 300);

            var a = window.$cookies.get("a");
            if (a == 'Y' || a == 'S') {

                pulsantis.push({
                    text: "",
                    icon: "mdi-send-clock-outline",
                    link: "/btn_changestatusadmin",
                    id: "btn_changestatusadmin",
                    disabled: false,
                },);

            }

            pulsantis.push({
                text: "",
                icon: "mdi-delete",
                link: "/delete",
                id: "btn_delete",
                disabled: false,
                title: "Delete",
                         
            },);

            if (a == 'Y' || a == 'S') {
                console.log("O");
            } else {

                console.log("this.currItem", this.currItem);
                //add button send in approvation
                if ("to Approve" != this.currItem.stato)
                    pulsantis.push({
                        text: "sendinapprovation",
                        icon: "mdi-send-clock-outline",
                        link: "/delete",
                        id: "btn_send_approvation",
                        disabled: false,
                    },);
            }

            this.$root.$children[0].bnavbuttons = pulsantis;

        },

        handleClick(row) {

            if (this.selecting != 1) {
                // set active row and deselect others
                console.log("row", row);
                this.openMsgDialog(row);
                //this.addBackBtn();
                this.addBackDeleteBtn();

                this.records.map((item, index) => {
                    item.selected = item === row

                    this.$set(this.records, index, item)
                })

                // or just do something with your current clicked row item data
                console.log(row.sugar)
            }

        },

        openMsgDialog: function (item) {

            // item.read = "1";

            this.currItem = item;
            this.dialogMsg = true;
            //this.editedItem = Object.assign({}, this.defaultItem)
            this.defaultItem.title = item.title;
            this.defaultItem.body = item.desc;
            this.defaultItem.read = item.read;
            this.defaultItem.id = item.id;

            this.defaultItem.accepted = item.read;

            //  this.updateMessage(item.id);

        },

        closePicker: function (v) {

            console.log("OKI", v);
            v = v < 10 ? '0' + v : v;
            this.timeStart = v + ":00";
            this.$refs.dialogStart.save(this.timeStart);

        },

        closePickerTwo: function (v) {

            console.log("OKI", v);
            v = v < 10 ? '0' + v : v;
            this.timeend = v + ":00";
            this.$refs.dialogEnd.save(this.timeend);

        },

        setSingleMode(mode) {
            this.singleMode = mode;
        },
        getDataFromApi() {
            this.loading = true
            this.realApiCall().then(data => {
                this.records = data.items
                this.totalRecords = data.total
                this.loading = false
            })
        },
        realApiCall: async function () {
            let items = await this.getMessages();
            //console.log ( "ciao",1 );

            return new Promise((resolve, reject) => {
                //console.log ( "ciao",2 );
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                console.log(reject);

                //const total = items.length

                const total = this.totalRecords;
                //   alert(total);

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                }
                console.log(itemsPerPage, page);
                /* if (itemsPerPage > 0) {
                    items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                } */

                setTimeout(() => {
                    resolve({
                        items,
                        total,
                    })
                }, 10)
            })
        },
        loadCausali: function () {

            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            var API_URL = "https://services.ebadge.it/public/api/getVoicesReqHoliday";
            var that = this;
            $.ajax({
                type: "GET",
                url: API_URL,
                data: {
                    "user": username,
                    "password": token_system
                },
                success: function (resultData) {
                    console.log(resultData);
                    var causali = resultData.Result;
                    /*    var arrCausali = [];

                       for (var causale of causali) {
                           //arrCausali[causale.idtypeactivity] = causale.description  ;
                           arrCausali.push( { causale }  );
                       } */
                    // console.log ( arrCausali );
                    that.causali = causali;

                }
            });

        },

        resetDay: function () {
            this.date = "";

        },

        changeStatusAdmin: async function (status) {

            //  this.currFilterStatus = status;

            console.log(status);

            //START
            var token = window.$cookies.get("token");

            /*   var username = window.$cookies.get("username");
              var token_system = window.$cookies.get("token_system"); */
            //var API_URL = "https://services.ebadge.it/public/api/sendReqHoliday";
            var API_URL = "https://app.emtool.eu/api/acceptRejectHolidaysPermits";
            var id_richiesta = this.currItem.id_richiesta;

            var operation = "";
            if (status == "Refused") operation = 'reject';
            if (status == "to Approve") operation = 'to Approve';
            if (status == "Approved") operation = 'accept';
            if (status == "Open") operation = 'open';

            var that = this;
            that.showSpinner = true;

            /* eslint-disable no-unreachable */
            /*    return; */
            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": token,
                    'id_richiesta': id_richiesta,
                    'operation': operation,
                },
                success: function (resultData) {
                    that.showSpinner = false;
                    console.log("resultData", resultData, that);

                    that.$swal({
                        icon: "success",
                        text: "La richiesta è stata inviata correttamente",
                        showConfirmButton: false,
                        timer: 2000
                    });
                    that.sheetChangeStatus = false;
                    that.dialogMsg = false;

                    that.getDataFromApi();

                    // var causali = resultData.Result;
                },
                error: function (errorData) {

                    that.sheetChangeStatus = false;

                    console.log(errorData);
                    var msg = errorData.responseJSON.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    that.showSpinner = false;

                }
            });
            //END

            //  this.sheetChangeStatus = false;
            this.getDataFromApi();
        },

        changeStatus: async function (status) {

            this.page = 1;

            this.currFilterStatus = status;

            this.sheetStatus = false;
            this.getDataFromApi();
            //alert ( this.currFilterStatus);

            /*   console.log(status);
              this.sheetStatus = false;
              var statusInt = 0;

              if (status == "Aperto") statusInt = 1;
              if (status == "For approval") statusInt = 2;
              if (status == "Chiuso") statusInt = 3;
              console.log("Stato int", statusInt);

              //this.$root.$children[0].showProgress = true ;
              this.showSpinner = true;

              var response = await this.updateStatusMonthAdmin(statusInt).then((res) => {
                  if (res.status == 200) {
                      // this.$swal("Il timesheet è stato inviato correttamente");
                      this.$swal({
                          icon: "success",
                          text: "Il timesheet è stato aggiornato correttamente",
                          showConfirmButton: false,
                          timer: 500
                      });
                      this.syncMonth();
                  } else {
                      this.$swal({
                          icon: "error",
                          text: "Il timesheet non è stato aggiornato correttamente",
                      });
                  }
              });
              //this.$root.$children[0].showProgress = false ;
              this.showSpinner = false;

              console.log("Response", response); */

        },

        clearSearch() {
            this.search = "";
        },
        clearSearchDipendenti() {
            this.searchDipendenti = "";
        },

        sumHours: function (event) {

            var operation = "+";

            if (event.target.classList.contains('minus')) {
                operation = '-';
            }

            if (event) {
                var dataid = event.target.getAttribute("data-id");

                var disabled = event.target.attributes.getNamedItem('disabled');

                /*       console.log("PO", this.piano_orario);
                      console.log("curDay", this.curDay); */

                //  var strDay = new Date(this.curYear, this.curMonth - 1, this.curDay);
                var strDay = "";

                var lista_orari = this.$root.$children[0].lista_orari;
                console.log("lista_orari", lista_orari, dataid, strDay, operation);

                if (disabled == null) {

                    var newIndex = 0;

                    for (var ind in lista_orari) {

                        if (dataid == 'startHour' && operation == '+') {
                            if (lista_orari[ind] == this.timeStart) {

                                newIndex = parseInt(ind) + 1;
                            }

                        }

                        if (dataid == 'startHour' && operation == '-') {
                            if (lista_orari[ind] == this.timeStart) {

                                newIndex = parseInt(ind) - 1;
                            }

                        }

                        if (dataid == 'endHour' && operation == '+') {
                            if (lista_orari[ind] == this.timeEnd) {

                                newIndex = parseInt(ind) + 1;
                            }

                        }

                        if (dataid == 'endHour' && operation == '-') {
                            if (lista_orari[ind] == this.timeEnd) {

                                newIndex = parseInt(ind) - 1;
                            }

                        }

                    }
                    if (dataid == 'endHour') this.timeEnd = lista_orari[newIndex.toString()];
                    if (dataid == 'startHour') this.timeStart = lista_orari[newIndex.toString()];

                    console.log("NewIndex", newIndex);

                }
                //console.log ("dw", strDay.getDay() );
                /*   var mapDay = {
                      "0": "domenica",
                      "1": "lunedi",
                      "2": "martedi",
                      "3": "mercoledi",
                      "4": "giovedi",
                      "5": "venerdi",
                      "6": "sabato"
                  }; */

                /*                 var po = this.piano_orario[0];
                                var dayLiteral = mapDay[strDay.getDay()];

                                var oretoday = parseFloat(po[dayLiteral]);

                                var max = 10;
                                max = oretoday;
                                var index = 0;
                                var newQuant;
                                if (dataid == 'Ordinari') index = 0;
                                if (dataid == 'Straordinari') index = 1;
                                if (dataid == 'Assenza') index = 2; */

                //   console.log("quantita", this.forecast[index].quantity);

                if (disabled == null) {

                    /*           if (operation == '+')
                                  newQuant = parseFloat(this.forecast[index].quantity) + 0.5;
                              else
                                  newQuant = parseFloat(this.forecast[index].quantity) - 0.5;

                              if (newQuant <= max && operation == '+') {
                                  this.forecast[index].quantity = newQuant.toString();

                              }

                              if (newQuant >= 0 && operation == '-') {
                                  this.forecast[index].quantity = newQuant.toString();

                              } */

                    /*    var diff = 0;
                       //adapta absence with ordinary , user cliecked ordinari so check if more than usual
                       if (dataid == 'Ordinari') {
                           if (newQuant <= oretoday) {
                               diff = oretoday - newQuant;
                               console.log("Diff:", diff);
                               //needed to set absense
                               if (diff > oretoday) diff = oretoday;
                               this.forecast[2].quantity = diff.toString();
                           }
                       } */

                    /*    if (dataid == 'Assenza') {
                           if (newQuant <= oretoday) {
                               diff = oretoday - newQuant;
                               console.log("Diff:", diff);
                               //needed to set absense
                               if (diff > oretoday) diff = oretoday;
                               this.forecast[0].quantity = diff.toString();
                           }
                       } */

                    // this.updateSumDay();
                }

            }

        },
        getMessages: async function () {

            // var a = window.$cookies.get("a");
            // var b = window.$cookies.get("b");
            //   var azienda = "" ;

            /*   if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S'   )

            {
                    if ()
            }
 */
            var response;

            var token = window.$cookies.get("token");

            console.log("Options :", this.options);
            var itemsPerPage = this.options.itemsPerPage;

            var start = (this.options.itemsPerPage * this.options.page) - this.options.itemsPerPage;
            var arrMsg = [];

            if (start <= 0 || isNaN(start)) start = 0;
            if (itemsPerPage <= 0 || isNaN(itemsPerPage) || itemsPerPage == undefined) itemsPerPage = 10;

            //no filter per grid
            /*  if (this.currDipendenteObj.resourceid !== undefined) {
                 token = this.currDipendenteObj.v_user;

             } */

            try {
                response = await this.$api.getHoliday(
                    token,
                    start,
                    itemsPerPage,
                    this.currAzienda,
                    this.currFilterStatus,
                    this.searchHoliday,

                );

                this.totalRecords = response.data.HolidaysPermits.record_totali;
                //  alert (this.totalRecords);
                //  this.$root.$children[0].totalMsg = response.data.Messages.msg_tot_nr;

                var msg = response.data.HolidaysPermits.holidays_permits;
                console.log("msg", msg);

                for (let [key, value] of Object.entries(msg)) {
                    console.log("Messaggio", key, value);
                    arrMsg.push(value);
                }

            } catch (error) {
                // this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
            return arrMsg;

        },
        manageClickDipendenti: function (dipendente) {
            this.sheetDipendenti = false;
            this.currDipendente = dipendente.resourceid;
            //  alert ( dipendente );
            this.resetDay();
            this.changeDip();
            setTimeout(() => {
                this.syncWorker();

            }, 100);
            /*   this.syncArrayDipendenti();
              console.log ("CurAzienda",this.currAzienda );
              console.log(this.dipendenti);

              this.sheetDipendenti = true ; */

        },

        manageClickAziende: async function (azienda) {

            this.currAzienda = azienda;
            await this.syncArrayDipendenti();

            console.log("CurAzienda", this.currAzienda);
            console.log(this.dipendenti);

            setTimeout(() => {
                this.sheetAziende = false;
                if (this.tab == 0) this.sheetDipendenti = true;

                if (this.tab == 1) {
                    this.getDataFromApi();
                }

                console.log("Curr Azienda :", this.currAzienda);
            }, 300);

        },
        changeDip: function (selectObj) {
            console.log(selectObj);
            /*  console.log("Oggetto :", selectObj);
             console.log(selectObj.src);

             console.log(this.currDipendente);
             console.log(this.dipendenti); */

            var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    selNameDip = dip.fld_name;

                }
            }

            this.$root.$children[0].curDipTop = selNameDip;

        },

        updateStatusMonthAdmin: async function (status) {

            console.log("currDipObj", this.currDipendenteObj);

            //    return false ;// eslint-disable no-unreachable

            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");

            if (this.currDipendenteObj.resourceid !== undefined) {
                //alert (  this.currDipendenteObj.resourceid  );
                system = this.currDipendenteObj.v_res_id;
                token = this.currDipendenteObj.v_user;
                user_id = this.currDipendenteObj.v_user_id;

            }

            var success = 1;
            var response = null;
            try {
                response = await this.$api.tsUpdateStatus(
                    token,
                    system,
                    user_id,
                    status,
                    this.curYear,
                    this.curMonth
                );
            } catch (error) {
                success = 0;
                console.log(error);
            }
            console.log(response, status, success, token, system, user_id);
            return response;
        },

        updateStatusMonth: async function (status) {
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");
            var user_id = window.$cookies.get("user_id");
            //  var username = window.$cookies.get("username");
            //system => resourceid
            //token_system=>pass encodata
            //username

            var success = 1;
            var response = null;
            try {
                response = await this.$api.tsUpdateStatusApproval(
                    token,
                    system,
                    user_id,
                    status,
                    this.curYear,
                    this.curMonth
                );
                /* .then((res) => {
                                console.log (res.data);
                                console.log (success);
                            }); */
            } catch (error) {
                success = 0;
                console.log(error);
            }

            console.log(response, status, success);

            return response;
        },

        setupNavTop: async function () {

            // this.setupNavBottomDone = true ;
            /*   var disabled = false;
              if (this.curStatus == "Close" || this.curStatus == "For Approval") {
                disabled = true;
              } */

            /*             var icon = "mdi-account-lock-open";
                        if (this.curStatus == "Close") icon = "mdi-account-lock";
                        if (this.curStatus == "For approval") icon = "mdi-account-arrow-right";

                        var color = this.colorsStati[this.curStatus];

                        console.log("Color status :", color);

                        var buttonsTop = [{
                            text: "",
                            icon: icon,
                            link: "",
                            id: "btn_status",
                            disabled: false,
                            color: color
                        }];

                        this.$root.$children[0].buttonsTop = buttonsTop; */
            //console.log ( icon );
        },

        setupNavBottom: async function () {

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");

            this.setupNavBottomDone = true;
            var disabled = false;
            if (this.curStatus == "Close" || this.curStatus == "For approval") {
                disabled = true;
            }

            if (this.aa == 'Y' || this.su == 'Y') {
                disabled = false;
            }

            var icon = "mdi-account-lock-open";
            if (this.curStatus == "Close") icon = "mdi-account-lock";
            if (this.curStatus == "For approval") icon = "mdi-account-arrow-right";

            console.log(icon);

            setTimeout(() => {

                var disableSend = false;
                if (this.tab == 1) disableSend = true;

                var disableChangeStatus = false;
                if (this.tab == 0) disableChangeStatus = true;

                //  alert (disableChangeStatus);

                var pulsantis = [

                    {
                        text: "",
                        icon: "mdi-refresh",
                        link: "",
                        id: "btn_refr_tms",
                        disabled: disabled,
                    },

                    {
                        text: "",
                        icon: "mdi-send",
                        link: "/timesheet",
                        id: "btn_send_req",
                        disabled: disableSend,
                    },

                    /*                       {
                                text: "",
                                icon:  icon ,
                                link: "",
                                id: "btn_status",
                                disabled: false,
                              },
                     */

                    {
                        text: "",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];

                if (a == 'Y' || b == 'Y') {

                    pulsantis.push({
                        text: "",
                        icon: "mdi-domain",
                        link: "/dash",
                        id: "btn_aziende",
                        disabled: false,
                    },);

                    pulsantis.push({
                        text: "",
                        icon: "mdi-list-status",
                        link: "/status",
                        id: "btn_changestatus",
                        disabled: disableChangeStatus,
                    },);

                }
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 10);
        },

        resetSumDay: async function () {
            /*  this.totPresenzaMese = monthPresenza;
                  this.totStraordinariMese = monthStraordinary; */

            this.forecast[0].quantity = "";
            this.forecast[1].quantity = "";
            this.forecast[2].quantity = "";
            this.forecast[3].quantity = "";
            this.forecast[4].quantity = "";
        },

        updateSumTotals: function () {
            //update all
            /*             var result = Object.entries(this.monthArray);
                        //   console.log ("qui" ,  result );
                        var monthPresenza = 0;
                        var monthStraordinary = 0;
                        var monthAssenza = 0;
                        //var monthAssenza = 0 ;

                        var ordinary = "";
                        var straordinary = "";
                        var assenza = "";
                        //var assenza = "";

                        for (var dat of result) {
                            ordinary = dat[1].hour_ordinario;
                            straordinary = dat[1].hour_straordinario;
                            assenza = dat[1].hour_activity;

                            if (ordinary > 0) monthPresenza += parseFloat(ordinary);
                            if (straordinary > 0) monthStraordinary += parseFloat(straordinary);
                            if (assenza > 0) monthAssenza += parseFloat(assenza);
                        }
                        this.totPresenzaMese = monthPresenza;
                        this.totStraordinariMese = monthStraordinary;
                        this.totAssenzaMese = monthAssenza;

                        var totali = parseFloat(monthPresenza) + parseFloat(monthStraordinary);

                        this.$root.$children[0].curDipOreTop = "";
                        this.$root.$children[0].curDipOreTopLabel = totali.toString() + " H"; */

        },
        updateSumDay: async function () {
            /*  var token = window.$cookies.get("token");
             var system = window.$cookies.get("system");

             var username = window.$cookies.get("username");
             console.log(username);

             var ord = this.forecast[0].quantity;
             var straord = this.forecast[1].quantity;
             var ass = this.forecast[2].quantity;
             var id_ass = this.forecast[3].quantity;

             if (ass == 0 || ass == "0") {
                 id_ass = "";
                 this.forecast[3].quantity = "";

             }

             this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);

             console.log("meseArray", this.monthArray);

             this.monthArray[this.curDay].hour_ordinario = ord;
             this.monthArray[this.curDay].hour_activity = ass;
             this.monthArray[this.curDay].hour_straordinario = straord;
             this.monthArray[this.curDay].idtypeactivity = id_ass;

             //trial
             this.updateSumTotals();

             //last modify update calendar icons
             this.syncMOnthIcons();

             //Call Ws tsUpdateDay async without blocking ui
             //v_ore_presenza,v_ore_assenza,v_ore_straordinari,v_id_assenza

             //if admin os su
             if (this.currDipendenteObj.resourceid !== undefined) {

                 system = this.currDipendenteObj.v_res_id;
                 token = this.currDipendenteObj.v_user;
                 //user_id = this.currDipendenteObj.v_user_id;

             }

             try {
                 var response = this.$api
                     .tsDayUpdate(token, system, this.curDay, this.curYear, this.curMonth, ord, ass, straord, id_ass)
                     .then((res) => {
                         console.log(res);
                     });
                 console.log(response);
             } catch (error) {
                 console.log(error);
             } */
        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);
            // alert(this.currDipendente);

            this.currDipendente = "";
            this.$root.$children[0].curDipTop = "";

            this.$root.$children[0].curDipOreTop = "";
            this.$root.$children[0].curDipOreTopLabel = "";

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];

            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            //   var aziende =   this.aziende  ;

            console.log("Elenco aziende:", this.aziende, aziende, arrAziende, arrDipendenti);

            for (var az of aziende) {

                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);

                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {

                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);

                    }
                }

            }
            //this.aziende = arrAziende;
            this.dipendenti = arrDipendenti;

        },

        getParamsApi() {
            var curDip = parseInt(this.currDipendente);
            var token = window.$cookies.get("token");
            var system = window.$cookies.get("system");

            if (curDip > 0) {
                console.log("curDIp", curDip, "this.dipendenti", this.dipendenti);
                //for( var dip in this.dipendenti  )
                for (const [key, dip] of Object.entries(this.dipendenti)) {
                    console.log("key", key);
                    if (parseInt(dip.resourceid) == curDip) {
                        console.log("dip", dip, "token", token, "system", system);
                        token = dip.v_user;
                        system = dip.resourceid;

                    }
                }

            }
            console.log({
                "token": token,
                "system": system
            });
            return {
                "token": token,
                "system": system
            };
        },

        syncMOnthIcons: function () {
            var arrTimesheet = this.monthArray;

            var todos = [];

            //var sabdom = [] ;
            for (var day of Object.entries(arrTimesheet)) {
                // console.log("giorno", day[1]);
                var dayCur = day[1];
                var strDay = new Date();

                //check plan hour
                var today = 8;
                if (dayCur.hour_ordinario < today && (dayCur.day_working != "NOT WORKING" &&
                    (dayCur.desc_day != "Sab" && dayCur.desc_day != "Dom"))) {
                    //  console.log (1);

                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: dayCur.hour_ordinario /* "Ordinari  " + dayCur.hour_ordinario  */ /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "green",
                        isComplete: false,
                        bar: {
                            color: "red"
                        },
                        dot: false
                    });

                }

                //Green for workingday
                if (dayCur.hour_ordinario > 0) {

                    console.log("Ordinari");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Ordinari  " + dayCur.hour_ordinario /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "green",
                        isComplete: true,
                        dot: true
                    });

                }

                //illness
                //console.log ("ok" , this.causali [ dayCur.idtypeactivity]);
                if (dayCur.hour_activity > 0) {

                    console.log("fesilnesst");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Assenze " /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                        dates: [strDay],
                        color: "red",
                        isComplete: false,
                        dot: true
                    });
                    //   datesOk.push ( strDay );
                    //  descOk.push ( dayCur.desc_festività );
                }

                if (dayCur.is_festivo) {
                    console.log("fest");
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: dayCur.desc_festività,
                        dates: [strDay],
                        color: "red",
                        isComplete: false,
                        dot: true
                    });
                    //   datesOk.push ( strDay );
                    //  descOk.push ( dayCur.desc_festività );
                }
                if (
                    dayCur.day_working == "NOT WORKING" &&
                    (dayCur.desc_day == "Sab" || dayCur.desc_day == "Dom")
                ) {
                    strDay = new Date(dayCur.year, +dayCur.month - 1, dayCur.day);
                    todos.push({
                        description: "Non lavorativo",
                        dates: [strDay],
                        color: "blue",
                        isComplete: false,
                        dot: true
                    });
                }
            }
            this.todos = todos;

        },

        syncWorker: async function () {
            this.getDataFromApi();
            this.setupCalendar();

        },
        setupCalendar: async function () {

            //ajax

            var username = btoa(window.$cookies.get("username"));
            // var token_system = window.$cookies.get("token_system");
            var API_URL = "https://app.emtool.eu/api/getHolidaysPermitsCalendar";

            if (this.currDipendenteObj.resourceid !== undefined) {
                //   alert (this.currDipendenteObj.v_user);
                username = this.currDipendenteObj.v_user;
                //    token_system = this.currDipendenteObj.way;

            }

            var that = this;
            that.showSpinner = true;
            var todos = [];

            $.ajax({
                type: "POST",
                url: API_URL,
                data: {
                    "v_user": username,

                },
                success: function (resultData) {
                    that.showSpinner = false;
                    console.log("resultData", resultData, that);
                    var arry = Object.entries(resultData.HolidaysPermits.giorni);

                    /*  $.each(arry) {
                         console.log ( "provola" , data);
                     } ); */

                    $(arry).each(function (oki, ok) {
                        console.log("CIAO", ok[0]);
                        todos.push({
                            description: "Ferie" /*  this.causali [ dayCur.idtypeactivity].description + "]" */,
                            dates: ok[0],
                            color: ok[1],
                            isComplete: true,
                            dot: true
                        });

                    });

                    that.todos = todos;

                    /*   that.$swal({
                          icon: "success",
                          text: "La richiesta è stato inviata correttamente",
                      }); */

                    // var causali = resultData.Result;
                }
            });

        },
        syncMonth: async function () {

            var params = this.getParamsApi();

            var token = params.token;
            var system = params.system;

            //   console.log ( system );
            this.showSpinner = true;
            var response;
            var arrTimesheet = [];

            try {
                response = await this.$api
                    .GetTimesheet(token, system, this.curYear, this.curMonth)
                    .then((res) => {
                        arrTimesheet = res.data.Timesheet;
                        console.log("Timesheet", arrTimesheet);

                        this.monthArray = arrTimesheet;

                        var result = Object.entries(arrTimesheet);
                        console.log(result);

                        var info_month = arrTimesheet.info_month;

                        this.$root.$children[0].curDipTop = info_month.full_name;

                        if (!this.setupNavBottomDone) {
                            this.su = arrTimesheet.u_complete;
                            this.aa = arrTimesheet.az_complete;

                        }

                        this.curStatus = info_month.desc_stato;
                        this.curReadOnly = info_month.editable;
                        this.piano_orario = arrTimesheet.piano_orario;

                        console.log("info_month", info_month);

                        var causali = arrTimesheet.causali;
                        console.log("causali", causali);
                        // var arrCausali = [];

                        /*  for (var causale of causali) {
                             //arrCausali[causale.idtypeactivity] = causale.description  ;
                             arrCausali.push(causale.description);
                         }
                         // console.log ( arrCausali );
                         this.causali = causali; */

                        //aziende
                        var arrAziende = [];
                        var arrDipendenti = [];
                        console.log(arrDipendenti);

                        var aziende = Object.entries(arrTimesheet.aziende);
                        //console.log ( "aziendeOK" , aziende );
                        for (var az of aziende) {
                            console.log("az", az);
                            arrAziende.push(az[1].fld_name);

                        }
                        if (arrAziende.length > 1 && !this.setupNavBottomDone) {

                            //attention
                            this.aziende = arrAziende;
                            this.dipendenti = arrDipendenti;
                            this.globalArrAziende = aziende;

                        }

                        console.log("arraz", arrAziende);
                        console.log("globalArrAziende", this.globalArrAziende);

                        //this.syncMOnthIcons();

                    });
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            console.log(response);

            /*       try {
              console.log(response);
             // arrTimesheet = response.data.Timesheet ;
              this.monthArray = arrTimesheet;
            } catch (error) {
              console.log(error  );
            } */

            //  this.data.days = response.data ;

            this.resetSumDay();
            this.updateSumTotals();

            //Setup status at top
            this.setupNavTop();

            //if ( !this.setupNavBottomDone)
            this.setupNavBottom();

        },
        pageChange(obj) {

            /*  if (   this.$root.$children[0].menuAziende  )
               {
                       this.dipendenti =  this.$root.$children[0].dipendenti;
                       this.aziende =  this.$root.$children[0].aziende;

               } */

            this.resetDay();

            this.monthArray = [];
            this.dayFormatted = "";
            // console.log(obj);
            this.curYear = obj.year;
            this.curMonth = obj.month;
            this.forecast[0].quantity = 0;
            this.forecast[1].quantity = 0;
            this.forecast[2].quantity = 0;
            this.forecast[3].quantity = 0;
            this.forecast[4].quantity = 0;

            this.day_editable = false;

            this.syncMonth();

            //this.$refs.defCalendar.$emit('refetchEvents');
            //  this.$root.defCalendar.$emit("dayclick");

            setTimeout(() => {
                // this.onDayClick(   {"day":16 , "ariaLabel": "" , "id":"2022-01-17" } );

            }, 4000);

            //enable

        },

        onDayClick(day) {

            //multiselect
            console.log(day);
            /*             const idx = this.days.findIndex(d => d.id === day.id);
                        if (idx >= 0) {
                            this.days.splice(idx, 1);
                        } else {
                            this.days.push({
                                id: day.id,
                                date: day.date,
                            });
                        }

                        this.curDay = day.day; */

            /*             this.forecast[0].quantity = 0;
                        this.forecast[1].quantity = 0;
                        this.forecast[2].quantity = 0;
                        this.forecast[3].quantity = 0;
                        this.forecast[4].quantity = 0;

                        //const idx = this.days.findIndex(d => d.id === day.id);
                        console.log(day);
                        // this.forecast[0].day = day.ariaLabel;
                        if (day) this.dayFormatted = day.ariaLabel;

                        try {
                            var ord = this.monthArray[day.day].hour_ordinario;
                            var straord = this.monthArray[day.day].hour_straordinario;
                            var assenza = this.monthArray[day.day].hour_activity;
                            var idtypeactivity = this.monthArray[day.day].idtypeactivity;

                            this.day_editable = Boolean(this.monthArray[day.day].day_editable);
                            this.forecast[0].quantity = ord;
                            this.forecast[1].quantity = straord;
                            this.forecast[2].quantity = assenza;
                            this.forecast[3].quantity = idtypeactivity;

                            this.forecast[4].quantity = parseFloat(ord) + parseFloat(straord);
                        } catch (error) {
                            console.log(error);
                        } */
        },
    },

    data() {
        var today = new Date();
        var curMonth = today.getMonth() + 1;
        var curYear = today.getFullYear();
        var curDay = today.getDate();

        // console.log ( curYear);

        //var datesOk = [new Date("2021-12-12"), new Date("2021-12-13")];

        /*    var todos = [
             {
               description: "Festivity",
               isComplete: false,
               dates: datesOk, // Every Friday
               color: "red",
             },
           ]; */
        //        var todos = [];
        /* console.log(dates); */
        return {

            searchHoliday: "",
            todos: [],
            page: 1,
            currFilterStatus: "",
            currItem: {},
            dialogMsg: false,

            defaultItem: {
                title: 'ti',
                body: '',
                read: 0,
                accepted: 0,
                id: 0

            },

            singleMode: false,

            loading: false,
            dialogMsgSend: false,

            singleSelect: true,

            totalRecords: 0,
            records: [],

            options: {},

            headers: [{
                text: ' ',
                value: 'read',
                align: 'start'
            },
            {
                text: 'Data inizio',
                value: 'created_at',
                align: 'start'
            },
            {
                text: 'Data fine',

                sortable: true,
                value: 'title',
                align: 'start'
            },
            {
                text: 'Status',

                sortable: true,
                value: 'dest',
                align: 'start'
            },

            ],

            tab: null,
            items: [
                'Request', 'History',
            ],
            text: 'ok prova'

            ,
            timeEnd: null,
            modalEndHour: false,
            timeStart: null,
            modalStartHour: false,

            causali: [],
            causale: "",
            range: {
                /*  start: new Date(),
                 end: new Date() */
            },
            days: [],
            menuAziende: false,
            search: '',
            searchDipendenti: '',

            globalArrAziende: {},
            setupNavBottomDone: false,
            currDipendente: "",
            currDipendenteObj: {},
            currAzienda: "",
            su: 'N',
            aa: 'N',
            sheet: false,

            allStatus: [{
                img: 'keep.png',
                title: 'Refused',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red"
            },
            {
                img: 'hangouts.png',
                title: 'to Approve',
                "id": 2,
                icon: "mdi-account-arrow-right",
                "color": "yellow"
            },
            {
                img: 'inbox.png',
                title: 'Approved',
                "id": 3,
                icon: "mdi-account-lock",
                "color": "green"
            },

            {
                img: 'inbox.png',
                title: 'Open',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "orange"
            },

            ],
            tiles: [{
                img: 'keep.png',
                title: 'Keep'
            }],

            admindialog: false,
            sheetStatus: false,
            sheetChangeStatus: false,
            sheetAziende: false,
            sheetDipendenti: false,
            /*  curDayReadOnly:true, */
            totPresenzaMese: "0",
            totAssenzaMese: "0",
            totStraordinariMese: "0",
            aziende: [],
            dipendenti: [],
            day_editable: false,
            curStatus: "Open",
            piano_orario: {},
            colorsStati: {
                "Close": "green",
                "For approval": "yellow",
                "Open": "red"
            },

            datesPoint: [new Date("2021-12-12"), new Date("2021-12-13")],
            showSpinner: false,
            curMonth: curMonth,
            curYear: curYear,
            curDay: curDay,

            monthArray: [],
            labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
            time: 0,
            itemsQuantity: [
                "0",
                "0.5",
                "1",
                "1.5",
                "2",
                "2.5",
                "3",
                "3.5",
                "4",
                "4.5",
                "5",
                "5.5",
                "6",
                "6.5",
                "7",
                "7.5",
                "8",
                "8.5",
                "9",
                "9.5",
                "10",
            ],
            forecast: [{
                day: "Causale",
                icon: "mdi-star-minus-outline",
                quantity: "Festività",
            },

            {
                day: "Ordinari",
                icon: "mdi-account-hard-hat",
                quantity: "0"
            },

            ],

            dayFormatted: "",
            dayAbout: "San Cristoforo",

            attributesOld: [{
                dot: {
                    style: {
                        "background-color": "brown",
                    },
                },
                dates: this.datesPoint
                /*  [
                                   new Date( '2021-12-12'), // Jan 12th
                                   new Date('2021-12-13'), // Jan 26th
                                   new Date(2018, 0, 15), // Jan 15th
                               ] */
                ,
            },

                /*  {
                        dot: "red",
                        dates: this.datesPoint,
                        }, */
            ],

            /*   date: new Date(), */
            date: "",

            /*   "it" : it,
            en: en,
            es: es,
            de: de */
        };
    },

    computed: {
        rangeDetail() {
            var start = this.range.start;
            var end = this.range.end;

            var text = "";

            if (start == undefined) return "";

            var $mesi = [

                "gen.",
                "feb.",
                "mar.",
                "apr.",
                "mag.",
                "giu.",
                "lug.",
                "ago.",
                "set.",
                "ott.",
                "nov.",
                "dic.",
            ];

            var startLiteral = start.getDate() + " " + $mesi[start.getMonth()] + " " + start.getFullYear();

            var endLiteral = end.getDate() + " " + $mesi[end.getMonth()] + " " + end.getFullYear();

            if (startLiteral != endLiteral) {
                text = startLiteral + " - " + endLiteral;
                //  this.singleMode = false
                this.setSingleMode(false);

                this.setTime();

            } else {
                text = startLiteral;
                //                    this.singleMode = true ;
                this.setSingleMode(true);

            }
            return text;

        },
        attributesERR() {

            var dates = this.dates.map(date => (
                console.log(date), {

                    highlight: {
                        backgroundColor: '#ff8080', // Red background
                        borderColor: '#ff6666',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                    },
                    /*  dates: date,
                     multiple: true */
                }));

            return [{
                highlight: {
                    class: 'date-circle', // Circle class
                    contentClass: 'date-text', // Text class
                },
                contentStyle: {
                    color: 'white', // White text
                },
                dates: dates

            }]

        },

        dates() {
            return this.days.map(day => day.date);
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

        attributesCal() {
            var ok = 0;
            console.log(ok);
            return [

                // Attributes for todos
                ...this.todos.map((todo) => ({
                    dates: todo.dates,
                    dot: todo.dot ? {
                        color: todo.color,
                        class: todo.isComplete ? "opacity-75" : "",
                    } : false,
                    bar: todo.bar ? todo.bar : false,
                    popover: {
                        label: todo.description,
                        hideIndicator: false,
                    },
                    customData: todo,
                })),
            ];
        },
    },

    components: {
        /*   Datepicker, */

        datepicker,
        RiseLoader,

    },
    // ...
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.v-list-item {
    margin-left: 20px;
}

select:focus-visible {
    outline: 1px dashed rgb(247, 247, 247);
}

.v-select__slot {
    font-size: 10px;
}

.v-list-item__title {
    font-size: 12px;
}

.vc-day {
    min-height: 36px !important;
    padding: 8px;
}

.contQuanties {
    max-width: 420px !important;
}

@media only screen and (max-width: 600px) {

    .v-menu__content REMOVE {
        left: 0px !important;
        min-width: 100vw !important;
        top: auto !important;
        bottom: 0px !important;
        position: fixed !important;
        min-height: 200px;
        /*   min-height: 300px; */

        background: #80808091;
        background: white;

        /*  pointer-events: none; */

    }

    .v-menu__content .v-select-listREMOVE {
        max-height: 100%;
        bottom: 0px;
        position: absolute;
        overflow: auto;
        min-width: 100vw;
    }

    .vc-day {
        min-height: 2px !important;
        padding: 6px 8px;
    }

    .contQuanties {
        max-width: 320px !important;
    }
}

span.titletotals {
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.totals {
    /*  transform: translate(0px, 14px);
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    font-size: 12px;
    margin-top: 10px; */
    /*  display: none; */
}

.contDayFormatted {}

.dayFormatted {
    color: black !important;
    font-weight: bolder;
}

.spanStatus {
    color: red;
}

.rowStatus {
    /*  padding-top:20px;
        padding-bottom:20px;
        font-weight: bolder;
        margin-bottom:200px; */
    /* transform: translate(0px, -60px); */
    display: none;
}

.progressBar {
    position: fixed;
    top: 25%;
    left: 25%;
    position: fixed;
    justify-content: center;
    display: flex;
    right: 0;
    left: 0;
    z-index: 100000;
    width: 100%;
    top: 0;
}

.contcalendar .v-card__actions {
    padding: 0px !important;
    padding-top: 0px !important;
}

.nocolor {
    border: none !important;
    box-shadow: none !important;
}

#holiday {
    padding-top: 0px;
    max-width: 90vw;
    margin: 0 auto;
}

.contcalendar {
    min-width: 60vw;
    /*
  min-height: 60vh; */
    align-content: center;
}

.vc-container {
    --day-content-height: 50px;
    --day-content-width: 50px;
}

.vc-container.vc-is-expanded {
    border: none;
}

/* .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid #d6d6d6 !important ;
}

.vdp-datepicker__calendar {
  width: auto;
  max-width: 300px;
  border: none !important;
}
.vdp-datepicker {
  margin: 0 auto;
  max-width: 300px;
}
.vdp-datepicker__calendar .cell.selected,
.vdp-datepicker__calendar .cell.selected:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  background: #f5cf2e !important;
  border-color: none;
} */

.selectHour {
    padding-left: 25px;
    font-size: 12px !important;
}

.v-list-item {
    min-height: 42px;
}

.v-list-item__icon {
    margin: 10px 0px;
}

.totaleCombo input {
    color: black !important;
    font-weight: 600;
}

html:not(.hydrated) body {
    display: block;
}

.vc-header {
    font-family: 'Montserrat' !important;

}

#holiday .plus,
#holiday .minus {
    background: #f5cf2e;
    border: 1px solid;
    padding: 0px 4px;
    cursor: pointer;
    max-height: 24px;
    min-width: 24px;
    text-align: center;

}

#holiday .plus {
    margin-right: 6px;
}

.addbuttons {
    display: flex;
    margin-left: 5px;
    align-items: center;
    font-size: 20px;
    padding-bottom: 12px;

    /* border: 1px solid; */
}

.conSelect {
    display: flex;

}

div[disabled] {
    opacity: 0.2;
    cursor: default;
}
</style>

<style>
input[type="number"] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.detailtotals {
    display: none;
}

.vc-bars {
    max-width: 18px;
    transform: translate(0px, 5px);
}

.vc-bar {
    max-height: 1px !important;
}

.v-application .text-center {

    overflow: auto;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: inherit !important;
}

.v-btn--disabled {
    opacity: 0.3;
}

.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
}

.itemAzienda {
    margin-left: 15px;
}

.headerSheetAziende {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.closeStatusSheet {
    position: absolute;
    right: 0;
}

.v-dialog__content {
    margin-top: 56px;
}

.v-list-item__icon {
    align-self: center;
}

#contCalendarHoliday .vc-day {
    min-height: 28px !important;
    padding: 8px;
}

.contVoices .v-list-item {
    max-height: 56px;
    ;
}

#contCalendarHoliday .vc-highlight-base-middle,
#contCalendarHoliday .vc-highlight-base-start,
#contCalendarHoliday .vc-highlight-base-end {
    background-color: rgb(245, 207, 46) !important;
}

.v-pagination__navigation--disabled {
    opacity: 0 !important;
}
</style>
