import axios from 'axios'

class APINotes {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async getUserInfo(user, id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("getPianificazioniUserInfoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getAttivitaByArgomento(id_argomento) {

    let formData = new FormData();

    formData.append("id_argomento", id_argomento);

    return axios.post(this.getUrlDomain("getAttivitaByArgomentoApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttivitaByUser(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getAttivitaByUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getAttivitaPredecessoriApi(id_argomento, user) {

    let formData = new FormData();

    formData.append("id_argomento", id_argomento);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("getAttivitaPredecessoriApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertNotes(notes, user) {


    let formData = new FormData();

    formData.append("username", user);

    for (var key in notes) {
      formData.append(key, notes[key]);
    }

    return axios.post(this.getUrlDomain("TodolistInsertNotes"), formData, { 'Content-Type': 'multipart/form-data' });
  }



  async getNotes(id) {

    let formData = new FormData();

    formData.append("id", id);

    return axios.post(this.getUrlDomain("TodolistEditNotes"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateNotes(notes, id, user) {


    let formData = new FormData();

    formData.append("username", user);
    formData.append("v_id", id);

    for (var key in notes) {
      formData.append(key, notes[key]);
    }

    return axios.post(this.getUrlDomain("TodolistUpdateNotes"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteNotes(id) {

    let formData = new FormData();

    formData.append("v_id", id);

    return axios.post(this.getUrlDomain("TodolistDeleteNotes"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getDefaultValueOld() {

    return axios.get(this.getUrlDomain("getPianificazioneDefaultValueApi"), { 'Content-Type': 'multipart/form-data' });

  }

  async getDefaultValue(username) {

    let formData = new FormData();

    formData.append("username", username);

    return axios.post(this.getUrlDomain("getPianificazioneDefaultValueApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





}

export default new APINotes({
  url: "http://emtool.local/public/api/alertduedate/"
})
