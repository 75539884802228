<template>
<div class="cont-table" style="max-width:90vw">

    <div class="spin">
        <ion-spinner name="crescent"></ion-spinner>
    </div>

    <div class="row cont_parenti cont_tickets">
        <div class=" " style="margin:0 auto;">
            <div class="card">

                <div class="card-body" style="padding:0px">

                    <div class="table-responsive" style="">

                        <table id="myTable">
                            <thead>
                                <tr>

                                    <th class='all' style='text-align:right!important;'> </th>

                                    <th class='all'>Nome</th>
                                    <th class='all'>E-Mail</th>
                                    <th class='all'>Telefono</th>
                                    <th class='desktop'>Ospite</th>
                                    <th class='desktop'>Nucleo</th>
                                    <th class='desktop'>CF Ospite</th>
                                    <th class='desktop'>Inserito</th>
                                    <th class='desktop' style="text-align:center">Osp. Assoc.</th>

                                </tr>
                            </thead>

                            <tfoot>

                                <tr>
                                    <th colspan="9">
                                    </th>
                                </tr>
                            </tfoot>

                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";

import {
    bus
} from "../main";
import router from ".././router";
//import $ from 'jquery';

export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
        //window.removeEventListener("scroll", this.onScroll, false);

        //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
    },

    mounted: function () {
        /*     let self = this;
        fetch('https://jsonplaceholder.typicode.com/posts')
          .then(response => response.json())
          .then(json => self.$data.items = json) */

        //var res = apiprofile.getProfile(1,2);

        /*eslint-disable no-undef*/
        $(document).ready(
            function () {

                window.uname = window.$cookies.get("username");
                var uname = window.uname;
                
                const args = [uname, true, ['bar', 5], {
                    foo: 'baz'
                }];
                try {
                    console.log("entering user");
                    window.flutter_inappwebview.callHandler('setLoggedUser', ...args);
                    console.log("passed");

                } catch (error) {
                    // alert(error);
                }

            }
        );

        $(document).ready(function () {

            $('body').on('click', '#footer-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openLcHandler', ...args);

                return false;
            });

            $('body').on('click', '#privacy-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openPrivacyHandler', ...args);

                return false;
            });

            $('body').on('click', '#hdtool-link', function (event) {
                event.preventDefault();

                const args = [1, true, ['bar', 5], {
                    foo: 'baz'
                }];
                window.flutter_inappwebview.callHandler('openHdHandler', ...args);

                return false;
            });

        });

        setTimeout(() => {
            this.syncProfile();
        }, 100);
        // console.log (res);

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_exit":
                    router.push({
                        path: "/dash"
                    });
                    break;

                case "btn_add":
                    router.push({
                        path: "/editParente/0"
                    });
                    break;

                case "btn_edit":

                   //alert(window.id_par_sel);

                    if (window.id_par_sel == 0)
                    {

                        this.$swal({
                            icon: "error",
                            text: "Selezionare la riga da modifcare",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }

                    if (window.id_par_sel > 0)
                    {

                        router.push({
                            path: "/editParente/" + window.id_par_sel
                        });
                        
                    }




                    break;




                default:
                    break;
            }
        });

        //this.setupButtons();

        this.setupButtonsGrid();

        this.setupGrid();

    },
    data: () => ({
        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
            (v && v.length <= 16) ||
            "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
            (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if ($(location).attr('hostname') == "localhost") {
                result = $(location).attr('protocol') + "//emtool.local/";
            }

            if ($(location).attr('hostname') != "localhost") {

                result = $(location).attr('protocol') + "//" + $(location).attr('hostname') + "/";

            }

            return result + "public/api/smartbook/" + nome_metodo;

        },

        setupGrid: function () {

            var that = this;
            var pathLocal = that.$withBase('/');

            var pointerVue = this;

            const date = new Date().toJSON().slice(0, 10)
            console.log(date) //2015-07-23

            var $futureTime = date;

            $(document).ready(function () {

                //console.log("DOMAIN: " + pointerVue.getUrlDomain("getListingRSA"));

                // console.log("HOSTNAME: " + $(location).attr('hostname'));
                // console.log("PROTOCOL: " + $(location).attr('protocol'));

                window.id_par_sel = 0;

                window.table = $('#myTable').dataTable({
                    // dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                    //     "<'rows colcontTable'<'col-sm-12 'tr>>" +
                    //     "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": 30,

                    processing: false,
                    paging: true,
                    serverSide: true,

                    ajax: {
                        url: pointerVue.getUrlDomain("getParenti") + '?v_user=ZmFnb3N0b25p',
                        data: function (valori) {

                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            return valori;

                        }

                    },

                    language: {

                        lengthMenu: `<label><select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select></label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: '<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66"><circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle></svg>'
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: [{
                            data: 'DT_RowIndex',
                            orderable: false,
                            searchable: false,
                            name: '',
                            width: '1%',
                            // targets: 0,
                        },

                        {
                            data: 'nome_parente',
                            orderable: false,
                            name: 'nome_parente',
                            width: '15%'
                        },

                        {
                            data: 'mail_parente',
                            orderable: false,
                            name: 'mail_parente',
                            width: '10%'
                        },

                        {
                            data: 'telefono_parente',
                            orderable: false,
                            name: 'telefono_parente',
                            width: '10%'
                        },

                        {
                            data: 'nome_ospite',
                            orderable: false,
                            name: 'nome_ospite',
                            width: '20%'
                        },

                        {
                            data: 'settore_piano_ospite',
                            orderable: false,
                            name: 'settore_piano_ospite',
                            width: '15%'
                        },

                        {
                            data: 'codice_fiscale_ospite',
                            orderable: false,
                            name: 'codice_fiscale_ospite',
                            width: '15%'
                        },

                        {
                            data: 'created_at',
                            orderable: false,
                            name: 'created_at',
                            width: '10%'
                        },

                        {
                            data: 'parent_assoc',
                            orderable: false,
                            name: 'parent_assoc',
                            width: '20%'
                        },

                    ],

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                        text: 'Cancel',
                                        role: 'cancel',
                                        cssClass: 'switch-btn-cancel',
                                    },
                                    {
                                        text: 'Done',
                                        cssClass: 'switch-btn-done',
                                        handler: columns => {
                                            console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                            var valore = columns.hour.text + ":" + columns.minute.text;

                                            console.log(valore);

                                            //alert("ok");

                                            // $.ajax({
                                            //     url: "{{ route('smartbook.updateHour') }}",
                                            //     data: {
                                            //         "id": id,
                                            //         "hour": valore
                                            //     },
                                            // });
                                        }
                                    },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        // $('.p_valore').number(true, 1, ',', '.');
                        // $('.p_totale').number(true, 2, ',', '.');
                    }

                });

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                var sel = `  <ion-item>
                <ion-select   data-id="0"  value="0" style='display:none;' class="selectstatusall" interface="action-sheet" placeholder=" "><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='0'>In attesa</ion-select-option><ion-select-option  value='1'>Confermato</ion-select-option><ion-select-option  value='2'>Disdetto cliente</ion-select-option><ion-select-option  value='3'>Disdetto ammin.</ion-select-option></ion-select></ion-item>
                  `;

                var sel2 = `  <ion-select   data-id="0" style='display:none;'  value="-1" class="selectpianiall" interface="action-sheet" placeholder=" "><ion-select-option  value='-1'>Tutti</ion-select-option><ion-select-option  value='NUCLEO 1 A'>NUCLEO 1 A</ion-select-option><ion-select-option  value='NUCLEO 1 B'>NUCLEO 1 B</ion-select-option><ion-select-option  value='NUCLEO 2 A'>NUCLEO 2 A</ion-select-option><ion-select-option  value='NUCLEO 3 A'>NUCLEO 3 A</ion-select-option><ion-select-option  value='NUCLEO ALZHEIMER'>NUCLEO ALZHEIMER</ion-select-option><ion-select-option  value='NUCLEO TERRA B'>NUCLEO TERRA B</ion-select-option></ion-select></ion-item>
`;

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">
                    <ion-icon class='dayleft' ios="ios-arrow-dropleft" style="z-index:-2;display:none;cursor:pointer;font-size:32px;" md="md-arrow-dropleft"></ion-icon>

                    <ion-datetime max="` + $futureTime + `" style="display:none;margin-right: 10px;z-index:0;" cancel-Text="" done-Text="" class='datetimechangeday' value='` + date + `' display-format="YYYY-MM-DD" ></ion-datetime>
                    <ion-datetime max="` + $futureTime + `" style="z-index:0;display:none;" cancel-Text="" done-Text="" class='datetimechangedayTo' value='` + date + `' display-format="YYYY-MM-DD" ></ion-datetime>

                    <ion-icon  class='dayright' style="display:none;font-size:32px;cursor:pointer;" ios="ios-arrow-dropright" md="md-arrow-dropright"></ion-icon>
                    <img src='` + pathLocal + `update-left-rotation.png' style='display:none;' class="refreshButton" />
                    ` + sel + sel2 + `




                </div>
            `);

                //new $.fn.dataTable.FixedHeader(window.table);

                console.log("EVENTO " + window.parentiAddedEvents);

                if (window.parentiAddedEvents === undefined) {

                    $('body').on('click', '.paginate_button', function () {
                        $(".spin").show();

                    });

                    $('body').on('click', '.refreshButton', function () {

                        $(".spin").show();
                        window.table._fnDraw();

                    });

                    $('body').on('click', '.cont_parenti tr', function () {


                        setTimeout(() => {
                            
                     
                        // var idx = window.table.api()
                        //     .row(this)
                        //     .index();


                        // var avail = $(this).find("[data-status='1']").length;
                        // console.log(avail);
                        // if (avail == 0) return false;

                        //alert( 'Row index: '+ window.table.api().row( this ).index() );

                        $("tr").removeClass("active-row");

                        $(this).addClass("active-row");


                        window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                        //var total = window.table.api().data().count();

                       // console.log(id_row, total);





                        // enableNext();

                        // $(".btn_mail").removeClass("btn-disabled");
                        // $(".btn_edit").removeClass("btn-disabled");
                        // $(".btn_delete").removeClass("btn-disabled");

                        // var id_row = $("tr.active").find(".id_row").attr("data-id");

                        // console.log("VAL ID: " + id_row);

                        // $(".btn_mail").attr("data-id", id_row);
                        // $(".btn_edit").attr("data-id", id_row);
                        // $(".btn_delete").attr("data-id", id_row);

                        }, 100);

                        

                    });

                }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    /*
                    var elements = jQuery('.datetimechangeday');
                    console.log ( elements , "testa" );
                    var id = $(this).data("id")  ;*/

                    window.currDay = date;
                    window.currDayTo = date;

                    // alert(window.currDay);

                    document.querySelector('.datetimechangeday').pickerOptions = {
                        buttons: [{
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'switch-btn-cancel',
                            },
                            {
                                text: 'Done',
                                cssClass: 'switch-btn-done',
                                handler: columns => {
                                    var id = 0;
                                    console.log("ore minuti id", columns, id);
                                    var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                                    window.currDay = valore;
                                    document.querySelector('.datetimechangeday').value = valore;

                                    window.currDayTo = valore;
                                    document.querySelector('.datetimechangedayTo').value = valore;

                                    $(".spin").show();
                                    window.table.fnPageChange(0);
                                    window.table._fnDraw();
                                }
                            },
                        ],
                    };

                    document.querySelector('.datetimechangedayTo').pickerOptions = {
                        buttons: [{
                                text: 'Cancel',
                                role: 'cancel',
                                cssClass: 'switch-btn-cancel',
                            },
                            {
                                text: 'Done',
                                cssClass: 'switch-btn-done',
                                handler: columns => {
                                    var id = 0;
                                    console.log("ore minuti id", columns, id);
                                    var valore = columns.year.text + "-" + columns.month.text + "-" + columns.day.text;

                                    window.currDayTo = valore;
                                    document.querySelector('.datetimechangedayTo').value = valore;
                                    //update From

                                    // window.currDay = valore ;
                                    // document.querySelector('.datetimechangeday').value = valore ;

                                    window.table.fnPageChange(0);
                                    $(".spin").show();
                                    window.table._fnDraw();
                                }
                            },
                        ],
                    };

                }, 600);

                window.parentiAddedEvents = true;

            });

        },


    setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross-2dolist.png",
                        //class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                        width: 30,
                        title: "Add"

                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/edit_2dolist.png",
                       // class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                        width: 30,
                        title: "Edit",
                 
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },


                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },


        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },
        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #45b7e9;

}

.pay a {
    color: #45b7e9;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #45b7e9;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);

}
</style>

<style>
.cont_parenti #myTable_wrapper {
    min-width: initial;
    min-width: 60vw;
}

.cont_parenti .nome_parente {
    min-width: 200px;
}

.cont_parenti .inserito {
    min-width: 70px;
}

.cont_parenti .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.cont_parenti .mail {
    min-width: 200px;
}

.cont_parenti td:first-child {
    padding: 5px;
}



@media screen and (max-width: 768px) {

    .cont_parenti .nome_parente {
        min-width: initial;
        word-break: break-all;
        max-width: 120px;
    }

    .cont_parenti .inserito {
        min-width: initial;
    }

    .cont_parenti .osp_assoc {
        min-width: initial;
        display: flex;
        justify-content: center;
    }

    .cont_parenti .mail {
        min-width: initial;
    }

}
</style>
