import { render, staticRenderFns } from "./info.vue?vue&type=template&id=1863a813&scoped=true&"
import script from "./info.vue?vue&type=script&lang=js&"
export * from "./info.vue?vue&type=script&lang=js&"
import style0 from "./info.vue?vue&type=style&index=0&id=1863a813&scoped=true&lang=css&"
import style1 from "./info.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1863a813",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VContainer,VImg})
