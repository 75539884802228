<template>
    <v-container class="w-container cont-edit-pianificazione" style="padding-top:59px">

        <v-row>


            <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px;z-index:3">

                <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color: #70cf4e">{{ titolo }}</div>

            </v-col>

        </v-row>

        <v-form style="" v-model="valid" ref="form" lazy-validation>


            <v-row>

                <v-col cols="12" md="12">

                    <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition" offset-y
                        max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="pianificazione.date" label="Date" persistent-hint readonly
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="pianificazione.date" no-title @input="showPicker = false"
                            locale="it"></v-date-picker>
                    </v-menu>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <v-textarea v-model="pianificazione.descrizione" label="Descrizione"></v-textarea>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_id_argomento" style="cursor:pointer">

                        <ion-item id="item_id_argomento" style="min-width:200px;transform: translateX(-6px);">
                            <ion-label style="font-size:12px;color:gray!important;opacity:1" position="floating">
                                Subject </ion-label>
                            <ion-select id="argomento" name="argomento" class='argomento' cancel-Text="Chiudi" done-Text=""
                                :value="pianificazione.id_argomento" interface="action-sheet" disabled readonly>
                                <ion-select-option v-for="item in argomenti" :key="item.id" :value='item.id'> {{
                                    item.nome
                                }}</ion-select-option>
                            </ion-select>
                        </ion-item>

                    </div>

                </v-col>

            </v-row>

            <v-row>

                <v-col cols="12" md="12">

                    <div id="cont_id_attivita" style="cursor:pointer">

                        <ion-item id="item_id_attivita" style="min-width:200px;transform: translateX(-6px);">
                            <ion-label style="font-size:12px;color:gray!important;opacity:1" position="floating">
                                Attività </ion-label>
                            <ion-select id="attivita" name="attivita" class='attivita' cancel-Text="Chiudi" done-Text=""
                                :value="pianificazione.id_attivita" interface="action-sheet" disabled readonly>
                                <ion-select-option v-for="item in attivita" :key="item.id" :value='item.id'> {{
                                    item.nome
                                }}</ion-select-option>
                            </ion-select>
                        </ion-item>

                    </div>

                </v-col>

            </v-row>



        </v-form>

        <div class="contproprietari">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetProprietari" class="vproprietarisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetProprietari" fixed style="position:fixed;">
                                Owners
                                <v-btn class='closeSheet' icon @click="sheetProprietari = !sheetProprietari">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchProprietari"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredProprietari" :key="item.login"
                                        @click="manageClickProprietario(item)">

                                        <v-img alt="Proprietario Logo" class="  " contain src="@/assets/owners.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemProprietario'>{{ item.name }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>
        </div>

        <div class="contargomenti">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetArgomenti" class="vargomentisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetArgomenti" fixed style="position:fixed;">
                                Argomenti
                                <v-btn class='closeSheet' icon @click="sheetArgomenti = !sheetArgomenti">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchArgomenti"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredArgomenti" :key="item.id"
                                        @click="manageClickArgomenti(item)">

                                        <v-img alt="Argomenti Logo" class="  " contain src="@/assets/argomenti_dd.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemArgomento'>{{ item.nome }}</v-list-item-title>


                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contattivita">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetAttivita" class="vattivitasheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetAttivita" fixed style="position:fixed;">
                                Attivita
                                <v-btn class='closeSheet' icon @click="sheetAttivita = !sheetAttivita">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchAttivita"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredAttivita" :key="item.id"
                                        @click="manageClickAttivita(item)">

                                        <v-img alt="Attivita Logo" class="  " contain src="@/assets/filter_activity2.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemAttivita'>{{ item.nome }}</v-list-item-title>

                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpredecessori">

            <template>
                <div class="text-center">
                    <v-bottom-sheet v-model="sheetPredecessore" class="vpredecessorisheet">
                        <v-sheet class="text-center" height="100vh">
                            <div class="headerSheetPredecessore" fixed style="position:fixed;">
                                Predecessore
                                <v-btn class='closeSheet' icon @click="sheetPredecessore = !sheetPredecessore">
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                </v-btn>

                                <v-toolbar>
                                    <v-text-field hide-details prepend-icon="mdi-magnify" single-line
                                        v-model="searchPredecessore"></v-text-field>

                                </v-toolbar>

                            </div>

                            <v-divider></v-divider>

                            <div class="spacer" style="min-height:72px;"></div>

                            <v-flex xs12 sm12>
                                <v-card>

                                    <v-list-item class="text-left" v-for="item in filteredPredecessore" :key="item.id"
                                        @click="manageClickPredecessore(item)">

                                        <v-img alt="Predecessore Logo" class="  " contain src="@/assets/planning_dd.png"
                                            max-width="24" max-height="24" transition="scale-transition" />

                                        <v-list-item-title class='itemPredecessore'>{{ item.descrizione }}
                                        </v-list-item-title>


                                    </v-list-item>
                                </v-card>
                            </v-flex>
                        </v-sheet>
                    </v-bottom-sheet>
                </div>
            </template>

        </div>

        <div class="contpopupstato">

            <template>
                <modal name="popupStato" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Stato (%)</label>

                            <div id="d_stato_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreStato" :options="'integer'" id="stato">
                                            </vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-stato" contain src="@/assets/btn_cancel3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-stato" contain src="@/assets/btn_confirm3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopuppriorita">

            <template>
                <modal name="popupPriorita" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Priorità</label>

                            <div id="d_priorita_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valorePriorita" :options="'integer'"
                                                id="priorita"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-priorita" contain src="@/assets/btn_cancel3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-priorita" contain src="@/assets/btn_confirm3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

        <div class="contpopupminuti">

            <template>
                <modal name="popupMinuti" :clickToClose="false" :width="300" :height="180">

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <label style="font-size:15px;margin-left:8px;">Ore</label>

                            <div id="d_minuti_popup" style="cursor:pointer"
                                class="v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                        <div class="v-text-field__slot"><label for="input-29"
                                                class="v-label v-label--active theme--light"
                                                style="left: 0px; right: auto; position: absolute;"></label>

                                            <vue-autonumeric v-model="valoreMinuti" :options="'integer'" id="durata_minuti"
                                                ref="fld_minuti"></vue-autonumeric>

                                        </div>
                                    </div>

                                    <div class="v-text-field__details">
                                        <div class="v-messages theme--light">
                                            <div class="v-messages__wrapper"></div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-minuti" contain src="@/assets/btn_cancel3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer;color:black;margin-right:10px"
                                    transition="scale-transition" />

                                <v-img alt="" class="btn-confirm-minuti" contain src="@/assets/btn_confirm3_2dolist.png"
                                    max-width="30" max-height="30" style="cursor:pointer" transition="scale-transition" />


                            </div>

                        </v-col>

                    </v-row>

                </modal>
            </template>

        </div>

    </v-container>
</template>

<script>
import apipianificazione from "../utils/pianificazione/apipianificazione";

import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

import {
    bus
} from "../main";

import router from ".././router";
import Vue from "vue";
import VModal from 'vue-js-modal';

export default ({
    mounted() {

        /*eslint-disable no-undef*/

        Vue.use(VModal, {
            dialog: true
        });

        this.proprietari = JSON.parse(localStorage.getItem('td_info_global')).todo_pian_utenti;

        this.valoreMinuti = null;

        var pointerVue = this;

        console.log("PARAMS: ", this.$route.params.id);

        this.cur_id = this.$route.params.id;

        var oggDrop = $('.dropify').dropify({
            messages: {
                default: 'Drag',
                replace: /* s */ '',
                remove: 'Rimuovi',
                error: 'File troppo grande'
            }
        });

        $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
        $(".dropify-render").append("<img>");

        //console.log(oggDrop);

        oggDrop.on('dropify.afterClear', function (event, element) {

            console.log(event, element);

        });

        this.setupButtons();

        if (this.$route.params.id == 0) {
            setTimeout(() => {
                this.syncDefaultValue();
            }, 100);
        }

        if (this.$route.params.id > 0) {

            this.titolo = "Modifica Pianificazione";



            setTimeout(() => {
                this.syncPianificazione();
            }, 100);

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":

                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/pianificazione"
                    });
                    break;

                default:
                    break;
            }
        });

        $('body').on('click', '#cont_id_argomento', function (event) {
            console.log(event);

            pointerVue.sheetArgomenti = true;

        });

        $('body').on('click', '#cont_id_attivita', function (event) {
            console.log(event);

            pointerVue.sheetAttivita = true;

        });

        $('body').on('click', '#cont_id_predecessore', function (event) {
            console.log(event);

            pointerVue.sheetPredecessore = true;

        });

        $('body').on('click', '#cont_proprietario', function (event) {
            console.log(event);

            if (!pointerVue.disField) {

                pointerVue.sheetProprietari = true;

            }

        });

        $("#ora_start").on("ionChange", function (opt) {

            if (pointerVue.doEvent) {

                console.log("OPT ORA START: ", opt);

                console.log("VALUE ORA START: ", this.value);

                pointerVue.pianificazione.ora_start = this.value;

                pointerVue.changePeriodStart();

            }

        });

        // $("#ora_end").on("ionChange", function (opt) {



        //     if (pointerVue.doEvent) {

        //         pointerVue.doEvent = false;

        //         console.log("OPT ORA END: ", opt);

        //         console.log("VALUE ORA END: ", this.value);

        //         pointerVue.pianificazione.ora_end = this.value;

        //         pointerVue.changePeriodEnd();

        //     }

        // });

        $('body').on('click', '#d_stato', function (event) {
            console.log(event);

            pointerVue.$modal.show('popupStato');

        });

        $('body').on('click', '.btn-cancel-stato', function (event) {
            console.log(event);

            pointerVue.$modal.hide('popupStato');

        });

        $('body').on('click', '.btn-confirm-stato', function (event) {
            console.log(event);

            pointerVue.pianificazione.stato = pointerVue.valoreStato;

            pointerVue.$root.$children[0].setFieldActive("#d_stato");

            pointerVue.$modal.hide('popupStato');

        });

        $('body').on('click', '#d_priorita', function (event) {
            console.log(event);

            pointerVue.$modal.show('popupPriorita');

        });

        $('body').on('click', '.btn-cancel-priorita', function (event) {
            console.log(event);

            pointerVue.$modal.hide('popupPriorita');

        });

        $('body').on('click', '.btn-confirm-priorita', function (event) {
            console.log(event);

            pointerVue.pianificazione.priorita = pointerVue.valorePriorita;

            pointerVue.$root.$children[0].setFieldActive("#d_priorita");

            pointerVue.$modal.hide('popupPriorita');

        });

        $('body').on('click', '#d_durata_min', function (event) {
            console.log(event);

            pointerVue.$modal.show('popupMinuti');



        });

        $('body').on('click', '.btn-cancel-minuti', function (event) {
            console.log(event);

            pointerVue.$modal.hide('popupMinuti');

        });

        $('body').on('click', '.btn-confirm-minuti', function (event) {
            console.log(event);

            if (pointerVue.doEvent) {

                pointerVue.doEvent = false;

                pointerVue.pianificazione.durata_min = pointerVue.valoreMinuti;

                pointerVue.$root.$children[0].setFieldActive("#d_durata_min");

                pointerVue.changePeriodStart();

            }

            pointerVue.$modal.hide('popupMinuti');

        });

    },

    components: {
        VueAutonumeric,
    },

    computed: {

        filteredProprietari() {

            return _.orderBy(this.proprietari.filter(item => {
                if (!this.searchProprietari) return this.proprietari;
                return (item.name.toLowerCase().includes(this.searchProprietari.toLowerCase()));
            }), 'headline')

        },

        filteredArgomenti() {

            return _.orderBy(this.argomenti.filter(item => {
                if (!this.searchArgomenti) return this.argomenti;
                return (item.nome.toLowerCase().includes(this.searchArgomenti.toLowerCase()));
            }), 'headline')
        },

        filteredAttivita() {

            return _.orderBy(this.attivita.filter(item => {
                if (!this.searchAttivita) return this.attivita;
                return (item.nome.toLowerCase().includes(this.searchAttivita.toLowerCase()));
            }), 'headline')
        },

        filteredPredecessore() {

            return _.orderBy(this.predecessori.filter(item => {
                if (!this.searchPredecessore) return this.predecessori;
                return (item.nome.toLowerCase().includes(this.searchPredecessore.toLowerCase()));
            }), 'headline')
        },

        mailMinutesToTime() {

            var strTime = "";

            if (this.pianificazione.ora_start != null) {

                strTime = new String(this.pianificazione.ora_start);

                console.log("MAIL MINUTES TIME TO: " + strTime);

                strTime = strTime.substring(0, 5);

            } else {

                strTime = this.getHourDefault();

            }

            return strTime;

        },

        mailMinutesToTime2() {

            console.log("Sta scattando");

            var strTime = "";

            /* var ok = this.pianificazione.ora_end ;
            this.pianificazione.ora_end = "";
            this.pianificazione.ora_end =ok; */

            if (this.pianificazione.ora_end != null) {

                strTime = new String(this.pianificazione.ora_end);

                console.log("MAIL MINUTES TIME TO 2: " + strTime);

                strTime = strTime.substring(0, 5);

            } else {

                strTime = this.getHourDefault();

            }

            return strTime;

        },

    },

    data: () => ({

        idActivitySel: [],

        attivitaTemp: [],

        valoreStato: null,
        valorePriorita: null,
        valoreMinuti: null,

        disField: false,

        doEvent: true,

        showPicker1: false,
        showPicker2: false,
        showPicker3: false,

        sheetArgomenti: false,
        searchArgomenti: "",

        sheetAttivita: false,
        searchAttivita: "",

        sheetPredecessore: false,
        searchPredecessore: "",

        sheetProprietari: false,
        searchProprietari: "",

        nameSurnameUser: null,

        currentFile: null,
        currDipendenteObj: {},
        currAzienda: "",
        titolo: "Aggiungi Pianificazione",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,

        //pianificazione: {},
        pianificazione: { name: "", surname: "", mailaddress: "", titolo: "", date: "", ora_start: "", durata_min: "", data_end: "", ora_end: "", stato: "", id_argomento: "", descrizione: "", id_attivita: "" },

        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        select: null,
        checkbox: false,

        argomenti: [],
        attivita: [],
        predecessori: [],
        aziende: [],
        dipendenti: [],
        proprietari: []

    }),

    methods: {

        checkMail: function (v_mail) {

            /* eslint-disable */
            var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            return regex.test(v_mail);

        },


        changePeriodEnd: async function () {

            console.log("CHANGE_PERIOD_END");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianificazione.calculateMinute(
                this.pianificazione.data_start,
                this.pianificazione.ora_start,
                this.pianificazione.data_end,
                this.pianificazione.ora_end
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from calculateMinute", res);

                that.doEvent = false;

                this.pianificazione.durata_min = res.data.ValMinute;


                $("#durata_minuti").val(res.data.ValMinute);

                that.doEvent = true;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eseguire il metodo");
                console.log("response", response);

            }

            );

        },

        changePeriodStart: async function () {

            var that = this;

            console.log("DATA DA: ", that.pianificazione.data_start);
            console.log("ORA DA: ", that.pianificazione.ora_start);
            console.log("MINUTI DUR: ", that.pianificazione.durata_min);

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianificazione.calculatePeriodEnd(
                that.pianificazione.data_start,
                that.pianificazione.ora_start,
                that.pianificazione.durata_min,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from calculatePeriodEnd", res);

                that.doEvent = false

                that.pianificazione.data_end = res.data.DataEnd;
                $("#data_end").val(res.data.DataEnd);

                that.pianificazione.ora_end = res.data.TimeEnd;
                $("#ora_end").val(res.data.TimeEnd);
                $(".cont_ora_end label").addClass("v-label--active");

                that.doEvent = true;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eseguire il metodo");
                console.log("response", response);

            }

            );

        },

        changeTime: function () {

            // $("#ora_end").val(this.pianificazione.ora_start);

            // $(".cont_ora_end label").addClass("v-label--active");
        },

        getHourDefault: function () {

            var dt = new Date();
            var time = dt.getHours().toString().padStart(2, "0") + ":" + dt.getMinutes().toString().padStart(2, "0");

            return time;

        },

        manageClickProprietario: async function (proprietario) {

            console.log("PROPRIETARIO SEL: ", proprietario);

            this.sheetProprietari = false;

            this.pianificazione.proprietario = proprietario.login;

            this.syncUserInfo();

        },

        manageClickArgomenti: async function (argomento) {

            console.log("TIPO SEL: ", argomento);

            this.sheetArgomenti = false;

            this.pianificazione.id_argomento = argomento.id;

            this.setAttivita(argomento.id);

            // this.loadAttivita();

            // this.loadPredecessori();

        },

        manageClickAttivita: async function (attivita) {

            console.log("TIPO SEL: ", attivita);

            this.sheetAttivita = false;

            this.pianificazione.id_attivita = attivita.id;

        },

        manageClickPredecessore: async function (predecessore) {

            console.log("TIPO SEL: ", predecessore);

            this.sheetPredecessore = false;

            this.pianificazione.id_predecessore = predecessore.id;

        },

        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/alertduedate/" + nome_metodo;

        },

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                    text: "Salva",
                    icon: "mdi-content-save",
                    link: "/timesheet",
                    id: "btn_save",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/salva_todolist.png",
                    width: 30,
                    title: "Save"


                },
                {
                    text: "Parenti",
                    icon: "mdi-arrow-left",
                    link: "/tipologiapagamento",
                    id: "btn_back",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/back-2dolist.png",
                    width: 30,
                    title: "Back"


                },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },



        validate() {

            var errore = "";

            var that = this;

            console.log("ACTIVITIES: ", that.idActivitySel);

            if (that.pianificazione.date == null) {

                errore = "Date required";

            }

            if (errore == "") {

                if (that.pianificazione.descrizione == "") {

                    errore = "Description required";

                }

            }

            if (errore == "") {

                if (that.pianificazione.id_argomento == "") {

                    errore = "Subject required";

                }

            }

            if (errore == "") {

                if (that.pianificazione.id_attivita == "") {

                    errore = "Activity required";

                }

            }


            if (errore == "") {

                console.log("PIANIF: ", that.pianificazione);

                that.saveData();

            } else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },


        loadAttivita: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianificazione.getAttivitaByArgomento(
                that.pianificazione.id_argomento,
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAttivitaByArgomento", res);

                this.attivita = res.data.attivita;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        loadPredecessori: async function () {

            //var v_user = window.$cookies.get("sel_user_pian");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianificazione.getAttivitaPredecessoriApi(
                that.pianificazione.id_argomento,
                that.pianificazione.proprietario

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getAttivitaPredecessoriApi", res);

                this.predecessori = res.data.predecessori;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        setAttivita: function (id_argomento) {

            console.log("ARGO SEL: ", id_argomento);

            console.log("ATTIVITA TMP: ", this.attivitaTemp);

            var Attivita = [];

            for (var i = 0; i < this.attivitaTemp.length; i++) {

                if (parseInt(this.attivitaTemp[i].id_argomento) == parseInt(id_argomento)) {

                    Attivita.push({
                        id: this.attivitaTemp[i].id,
                        id_argomento: this.attivitaTemp[i].id_argomento,
                        nome: this.attivitaTemp[i].nome,
                    });

                }

            }

            console.log("ATTIVITA SEL: ", Attivita);

            this.attivita = Attivita;

        },

        saveData: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            var response = null;

            if (that.pianificazione.stato == "") {

                that.pianificazione.stato = 0;

            }


            if (that.$route.params.id == 0) {




                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;


                response = await apipianificazione.insertPianificazionePersonaleNew(
                    that.pianificazione,
                    v_token,
                    that.idActivitySel
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from insertPianificazionePersonaleNew", res);

                    that.$swal({
                        icon: "success",
                        text: "Data saved successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/pianificazione"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

            if (that.$route.params.id > 0) {


                that.showSpinner = true;
                that.$root.$children[0].showProgress = true;


                response = await apipianificazione.updatePianificazioneNew(
                    that.pianificazione,
                    that.$route.params.id,
                    v_token
                ).then((res) => {

                    that.$root.$children[0].showProgress = false;
                    console.log("res from updatePianificazioneNew", res);

                    that.$swal({
                        icon: "success",
                        text: "Data saved successfully",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        that.dialogMsg = false;
                        that.setupButtons();

                        router.push({
                            path: "/pianificazione"
                        });

                    }, 200);

                }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

                );

            }

        },

        syncDefaultValueOld: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            //console.log("PARAM ID: ", that.$route.params.id);

            var response = await apipianificazione.getDefaultValue().then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDefaultValue", res);

                try {

                    this.pianificazione.data_start = res.data.result.data_default;
                    this.pianificazione.data_end = res.data.result.data_default;

                    this.pianificazione.ora_start = this.getHourDefault();

                    this.pianificazione.ora_end = this.getHourDefault();

                    $("#ora_start").val(this.getHourDefault());
                    $("#ora_end").val(this.getHourDefault());

                    this.pianificazione.abilitato_mail = "S";


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },


        syncDefaultValue: async function () {

            var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;


            var response = await apipianificazione.getDefaultValue(
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getDefaultValue", res);

                that.pianificazione.data_start = res.data.result.data_default;
                that.pianificazione.data_end = res.data.result.data_default;

                that.pianificazione.ora_start = that.getHourDefault();

                that.pianificazione.ora_end = that.getHourDefault();

                $("#ora_start").val(that.getHourDefault());
                $("#ora_end").val(that.getHourDefault());

                that.pianificazione.abilitato_mail = "S";


                that.argomenti = res.data.result.argomenti;

                that.attivitaTemp = res.data.result.attivita;

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        syncUserInfo: async function () {

            //var v_user = window.$cookies.get("sel_user_pian");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            //console.log("PARAM ID: ", that.$route.params.id);

            var response = await apipianificazione.getUserInfo(
                that.pianificazione.proprietario,
                that.$route.params.id
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getUserInfo", res);

                try {

                    //  this.nameSurnameUser = res.data.result.utente.name;

                    this.argomenti = res.data.result.argomenti;
                    //this.predecessori = res.data.result.predecessori;

                    // if (that.$route.params.id > 0) {

                    //     this.attivita = res.data.result.attivita;

                    //     this.predecessori = res.data.result.predecessori;

                    // }

                    // if (that.$route.params.id == 0) {

                    //     this.pianificazione.data_start = res.data.result.data_default;
                    //     this.pianificazione.data_end = res.data.result.data_default;

                    //     this.pianificazione.ora_start = this.getHourDefault();

                    //     this.pianificazione.ora_end = this.getHourDefault();

                    //     $("#ora_start").val(this.getHourDefault());
                    //     $("#ora_end").val(this.getHourDefault());

                    //     this.pianificazione.abilitato_mail = "S";

                    // }

                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        },

        syncPianificazione: async function () {

            // var v_token = window.$cookies.get("token");

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apipianificazione.getPianificazione(
                that.$route.params.id

            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getPianificazione", res);

                try {

                    this.argomenti = res.data.result.argomenti;
                    this.attivita = res.data.result.attivita;
                    this.predecessori = res.data.result.predecessori;

                    this.pianificazione = res.data.result.pianificazione;

                    this.pianificazione.durata_min = res.data.result.pianificazione.durata_min / 60;

                    this.doEvent = false;

                    var h_start = res.data.result.pianificazione.ora_start;
                    var elements = h_start.split(":");
                    var str_h_start = elements[0] + ":" + elements[1];

                    var h_end = res.data.result.pianificazione.ora_end;
                    elements = h_end.split(":");
                    var str_h_end = elements[0] + ":" + elements[1];

                    $("#ora_start").val(str_h_start);
                    $("#ora_end").val(str_h_end);

                    this.doEvent = true;

                    this.setAttivita(res.data.result.pianificazione.id_argomento);


                    $("#item_id_argomento").addClass("item-has-value");
                    $("#item_id_attivita").addClass("item-has-value");


                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile caricare i dati");
                console.log("response", response);

            }

            );

        }

    }

})
</script>

<style>
.cont-edit-pianificazione .containCheckBox {

    margin-top: 10px !important;

}

.cont-edit-pianificazione .contCheck {
    display: flex !important;
    height: 45px !important;
    justify-content: start !important;
    align-items: center !important;
    justify-items: center !important;
    overflow-y: hidden !important;
}


.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

.headerSheetArgomenti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetAttivita {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetPredecessore {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.headerSheetProprietari {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemProprietario {
    margin-left: 15px;
}

.closeSheet {
    position: absolute;
    right: 10px;
    top: 26px;
    z-index: 2;
}

.itemArgomento {
    margin-left: 15px;
}

.itemAttivita {
    margin-left: 15px;
}

.itemPredecessore {
    margin-left: 15px;
}

.v-application .text-center {

    overflow: auto;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

.v-input input {
    color: black !important;
}

.picker-toolbar-cancel {
    background: url("https://app.emtool.eu/public/imgs/icons8-rimuovi-50.png");
    background-size: 34px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
    /* border: 1px solid; */
}

.picker-toolbar-button:not(:first-child) {
    background: url("https://app.emtool.eu/_lib/img/usr__NM__new_icon_ok_32_2.png");
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 1px;
    min-width: 40px;
    max-width: 34px;
}

.switch-btn-cancel,
.switch-btn-done {
    text-indent: -9999px;
    min-width: 47px;
}

.picker-toolbar.sc-ion-picker-md {
    justify-content: center;
    margin-bottom: 12px;
    margin-top: 12px;
}
</style>