import axios from 'axios'

class APIArgomenti {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async insertArgomento(argomento, user)
  {


    let formData = new FormData();

    formData.append("username",  user);

    for ( var key in argomento ) {
       formData.append(key, argomento[key]);
    }

    return axios.post(this.getUrlDomain("insArgomentoApi") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getArgomento(id)
  {

    return axios.get(this.getUrlDomain("getArgomentoApi") + "/" + id + "?api=1",{ 'Content-Type': 'multipart/form-data' }   ) ;

  }



  async updateArgomento(argomento, id)
  {


    let formData = new FormData();


    for ( var key in argomento ) {
       formData.append(key, argomento[key]);
    }

    return axios.post(this.getUrlDomain("updArgomentoApi") + "/" + id, formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


  async deleteArgomento(id)
  {

    return axios.get(this.getUrlDomain("delArgomentoApi") + "?id=" + id,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }









}

export default new APIArgomenti({
  url: "http://emtool.local/"
})
