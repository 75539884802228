<template>
<v-container class="w-container" style="padding-top:59px">

    <v-row>

        <v-col class='mainCont' style="min-width:100vw;position:fixed;left:0;padding-left:0px">

            <div class="mainTitle" style="
            background-color: white;
            color: black;
            font-weight: bold;
            padding: 4px;
            font-size: 10px;
            min-width:100vw;
            border-color: #70cf4e;">{{ titolo }}</div>

        </v-col>

    </v-row>

    <v-form style="" v-model="valid" ref="form" lazy-validation>


        <v-row>

            <v-col cols="12" md="12">

                <v-text-field v-model="mail_gruppo.email" label="E-Mail" :rules="emailRules" required></v-text-field>

            </v-col>

        </v-row>

    

    </v-form>

</v-container>
</template>

<script>
import apimailgruppo from "../utils/mailgruppo/apimailgruppo";

import {
    bus
} from "../main";

import router from ".././router";

export default ({
    mounted() {

        console.log("PARAMS: ", this.$route.params.id);

        this.cur_id = this.$route.params.id;

        this.setupButtons();

        if (this.$route.params.id > 0) {

            this.titolo = "Edit Mail";

            setTimeout(() => {
                this.syncMailGruppo();
            }, 100);

        }

        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_save":
                    //alert("btn_add_tms");
                    this.validate();

                    break;

                case "btn_back":
                    //alert("btn_add_x");
                    router.push({
                        path: "/mailGruppo"
                    });
                    break;

                default:
                    break;
            }
        });

    },

    data: () => ({

        currentFile: null,
        currDipendenteObj: {},
        aziende: [],
        dipendenti: [],
        currAzienda: "",
        titolo: "Add Mail",

        cur_id: 0,

        sheetAziende: false,
        sheetDipendenti: false,
        searchDipendenti: "",
        search: "",

        showPicker: false,
        mail_gruppo: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) => (v && v.length <= 16) || "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) => (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [
            (v) => !!v || "Field required",

        ],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail richiesta",
            (v) => /.+@.+\..+/.test(v) || "E-mail non valida",
        ],

        nameRules: [
            (v) => !!v || "Nome richiesto",
        ],

        phoneRules: [
            (v) => !!v || "Telefono richiesto",
        ],

        nucleoRules: [
            (v) => !!v || "Nucleo richiesto",
        ],

        fiscodeRules: [
            (v) => !!v || "Codice fiscale richiesto",
        ],

        select: null,
        checkbox: false,
    }),

    methods: {

        setupButtons: async function () {

            setTimeout(() => {

                var pulsantis = [{
                        text: "Salva",
                        icon: "mdi-content-save",
                        link: "/timesheet",
                        id: "btn_save",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/salva_todolist.png",
                        width: 30,
                        title: "Save"

                    },
                    {
                        text: "Parenti",
                        icon: "mdi-arrow-left",
                        link: "/tipologiapagamento",
                        id: "btn_back",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/back-2dolist.png",
                        width: 30,
                        title: "Back"


                    },

                ];

                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {
                    //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
                    //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
                }, 100);

                // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
            }, 100);

        },

        validate() {
            //this.$root.$children[0].showProgress = true;

            var that = this;

            var res = that.$refs.form.validate();
            console.log(res);
            if (!res) {

                that.$swal({
                    icon: "error",
                    text: "Per favore verificare i dati",
                    showConfirmButton: false,
                    timer: 2000
                });
            } else {

                that.saveData();
              
            }
        },

        saveData: async function () {

           var v_token = window.$cookies.get("token");

            var that = this;

          
            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimailgruppo.setMailGruppo(
                that.mail_gruppo,
                that.$route.params.id,
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from setMailGruppo", res);

                that.$swal({
                    icon: "success",
                    text: "Data saved successfully",
                    showConfirmButton: false,
                    timer: 2000
                });

                setTimeout(() => {

                    that.dialogMsg = false;
                    that.setupButtons();

                    router.push({
                        path: "/mailgruppo"
                    });

                }, 200);

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile salvare i dati");
                    console.log("response", response);

                }

            );

        },
        

        syncMailGruppo: async function () {

            var v_token = window.$cookies.get("token");

            console.log(v_token);

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apimailgruppo.getMailGruppo(
                that.$route.params.id, 
                v_token
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getMailGruppo", res);

                try {

                    this.mail_gruppo = res.data.Result;

                   
                } catch (error) {
                    console.log(error);
                }

            }).catch(err => {
                    that.$root.$children[0].showProgress = false;
                    console.log(err);
                    var msg = err.response.data.Error;

                    that.$swal({
                        icon: "error",
                        text: msg,
                        showConfirmButton: false,
                        timer: 8000
                    });
                    console.log("ERrori", "Non è stato possibile caricare i dati");
                    console.log("response", response);

                }

            );

        }

    }

})
</script>

<style>

.w-container {
    width: 500px;
    padding: 0px;
}

.v-form {
    margin-top: 41px;
}

.mainTitle {
    padding: 20px !important;
    position: fixed;
    min-width: 500px;
    z-index: 1;
    top: 67px;
    border: 1px solid black;
}

@media screen and (max-width: 768px) {

    .v-form {

        padding-top: 45px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .w-container {
        width: 100vw;
        padding: initial;
        padding: inherit;
    }

    .mainTitle {
        padding: 20px !important;
        position: fixed;
        min-width: 100vw;
        left: 0;
        z-index: 1;
        top: 57px;
    }

}

</style>
