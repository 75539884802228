import axios from 'axios'


class APIConfInvioMail {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;

  }

  async saveConfig(id, user, pianaltri, pianpers)
  {


    let formData = new FormData();

    formData.append("v_user",  user);
    formData.append("v_pianaltri",  pianaltri);
    formData.append("v_pianpers",  pianpers);
    formData.append("v_id",  id);

    return axios.post(this.getUrlDomain("TodolistPianSaveConfUsers") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getConf(id, user)
  {


    let formData = new FormData();

    formData.append("v_user",  user);
    formData.append("v_id",  id);

    return axios.post(this.getUrlDomain("TodolistPianEditConfUsers") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async deleteConf(id)
  {


    let formData = new FormData();

    formData.append("v_id",  id);

    return axios.post(this.getUrlDomain("TodolistPianDelConfUsers") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

}

export default new APIConfInvioMail({
  url: "http://emtool.local/public/api/alertduedate/"
})
